import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";

import "./AccountSetup.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { AccountTypeOptions } from "../../components/AccountTypeOptions/AccountTypeOptions";
import { HospitalAccountSetup } from "../../components/HospitalAccountSetup/HospitalAccountSetup";
import { HospitalRepInvitation } from "../../models";
import { PatientAccountSetup } from "../../components/PatientAccountSetup/PatientAccountSetup";
import { TransportAccountSetup } from "../../components/TransportAccountSetup/TransportAccountSetup";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, UserType } from "../../interface";
import { USER_TYPES, USER_TYPE_KEYS, MBCOLORS } from "../../constants/config";
import { IonToast, IonLoading } from "@ionic/react";
import { MSGS_COMMON } from "../../constants/messages";

class AccountSetup extends React.Component<MBProps> {
  state = {
    selectedUserType: null as UserType | null,
    invitation: null as HospitalRepInvitation | null,
    loading: false,
    error: "",
  };

  componentDidMount = () => {
    const { authUser } = this.props;
    // Redirection is handled here to prevent instant redirection on success on account setup create account
    if (
      this.state.selectedUserType === null &&
      !_.isEmpty(authUser) &&
      !_.isEmpty(authUser.userDetails)
    ) {
      if (authUser.userType === USER_TYPES.patients.id) {
        this.props.history.push(routes.PATIENT_APPOINTMENTS);
      } else {
        this.props.history.push(routes.HOSPITAL_APPOINTMENTS); // replace this with other user type dashboards
      }
    }

    //CHECK IF HAS INVITATION
    this.checkInvitation();
  };

  checkInvitation = async () => {
    const urlParams = this.props.match.params;
    const { invitationId } = urlParams as { invitationId: string };
    if (!!invitationId) {
      this.setState({
        loading: true,
      });
      const invitation = await services.getInvitation(invitationId);
      console.log("GOT THE INVITE", invitation);
      if (!_.isEmpty(invitation)) {
        if (invitation.isCancelled) {
          this.setState({
            invitation: {} as HospitalRepInvitation,
            loading: false,
            error: "Invitation link not valid",
          });
        } else if (invitation.isUsed) {
          this.setState({
            invitation: {} as HospitalRepInvitation,
            loading: false,
            error: "Invitation link is already used",
          });
        } else {
          this.setState({
            loading: false,
            invitation,
          });
        }
      } else {
        this.setState({
          loading: false,
          error: "Invitation link not found",
        });
      }
    } else {
      this.setState({
        loading: false,
        invitation: {} as HospitalRepInvitation,
      });
    }
    console.log("GOT INVITATION ID!", invitationId);
  };
  render = () => {
    const { selectedUserType, invitation, loading, error } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {`MedBook - Account Setup ${
              !!selectedUserType
                ? ` - ${USER_TYPES[USER_TYPE_KEYS[selectedUserType]].name}`
                : ""
            }`}
          </title>
        </Helmet>
        <MBContainer {...this.props}>
          {_.isNull(selectedUserType) &&
          !_.isNull(invitation) &&
          _.isEmpty(invitation) ? (
            <AccountTypeOptions
              onSelect={(userType) => {
                this.setState({
                  selectedUserType: userType,
                });
              }}
            />
          ) : (selectedUserType === USER_TYPES.hospitals.id ||
              !_.isEmpty(invitation)) &&
            !_.isNull(invitation) ? (
            <HospitalAccountSetup
              invitation={invitation}
              onCancel={() => {
                this.setState({
                  selectedUserType: null,
                });
              }}
              {...this.props}
            />
          ) : selectedUserType === USER_TYPES.patients.id ? (
            <PatientAccountSetup
              onCancel={() => {
                this.setState({
                  selectedUserType: null,
                });
              }}
              {...this.props}
            />
          ) : (
            <TransportAccountSetup
              onCancel={() => {
                this.setState({
                  selectedUserType: null,
                });
              }}
              {...this.props}
            />
          )}
        </MBContainer>
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({
              error: "",
            });
            this.props.history.push(routes.LOGIN_OPTIONS);
          }}
          color={MBCOLORS.danger}
        />

        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
      </>
    );
  };
}

export default AccountSetup;
