/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCheckbox,
} from "@ionic/react";

import "./TransportAccountSetup.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  validatePassword,
  useEffectOnlyOnce,
  isMobile,
} from "../../functions/common";
import { CovidChecklistDialog } from "../CovidChecklistDialog/CovidChecklistDialog";
import { MBCard } from "../MBCard/MBCard";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBProps, AuthUser } from "../../interface";
import { MSGS_COMMON, MSGS_SIGNUP } from "../../constants/messages";
import {
  LIMITS,
  MBCOLORS,
  ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";

interface TransportAccountSetupProps extends MBProps {
  onCancel: () => void;
  authUser: AuthUser;
}

export const TransportAccountSetup = (props: TransportAccountSetupProps) => {
  const { onCancel, history, authUser } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [carModelAndPlateNo, setCarModelAndPlateNo] = useState("");
  const [maxAccomodation, setMaxAccomodation] = useState(0);
  const [covid19Safe, setCovif19Safe] = useState(false);
  const [isBaranggayRep, setIsBaranggayRep] = useState(false);
  const [haveReadPolicy, setHaveReadPolicy] = useState(false);

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorMsgAddress, setErrorMsgAddress] = useState("");
  const [errorMsgCarModelAndPlateNo, setErrorMsgCarModelAndPlateNo] = useState(
    ""
  );
  const [errorMsgMaxAccomodation, setErrorMsgMaxAccomodation] = useState("");

  const [
    questionnaireRequiredDialogOpen,
    setQuestionnaireRequiredDialogOpen,
  ] = useState(false);
  const [setupCompleteDialogIsOpen, setSetupCompleteDialogIsOpen] = useState(
    false
  );
  const [covidChecklistDialogOpen, setCovidChecklistDialogOpen] = useState(
    false
  );
  const [covidFreeOnlyOpenDialog, setCovidFreeOnlyOpenDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    if (!_.isEmpty(authUser)) {
      setEmailAddress(authUser.authEmail);
    }
  });

  const onSubmit = async () => {
    setLoading(true);

    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);
    const formattedAddress = formatString(address);
    const formattedPassword = password || "";
    const formattedCarModelAndPlateNo = formatString(carModelAndPlateNo || "");

    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedPhoneNumber.length === 0) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.email.required;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (formattedAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.address;
      setErrorMsgAddress(errorMessage);
    }

    if (_.isEmpty(authUser)) {
      if (formattedPassword.length === 0) {
        errorMessage = MSGS_SIGNUP.password.required;
        setErrorMsgPassword(errorMessage);
      }
      if (!validatePassword(formattedPassword)) {
        errorMessage = MSGS_SIGNUP.password.invalid;
        setErrorMsgPassword(errorMessage);
      }
    }

    if (formattedCarModelAndPlateNo.length === 0) {
      errorMessage = MSGS_SIGNUP.carModelAndPlateNo;
      setErrorMsgCarModelAndPlateNo(errorMessage);
    }

    if (maxAccomodation === 0) {
      errorMessage = MSGS_SIGNUP.maxAccomodation;
      setErrorMsgMaxAccomodation(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      if (!covid19Safe || !haveReadPolicy) {
        setLoading(false);
        setQuestionnaireRequiredDialogOpen(true);
      } else {
        try {
          let docId = !_.isEmpty(authUser) ? authUser!.uid : null;
          if (_.isEmpty(docId)) {
            docId = await services.createUserUsingEmail(
              formattedEmailAddress,
              formattedPassword
            );
          }

          if (!_.isNull(docId)) {
            // await services.createTransport(
            //   docId,
            //   formattedFirstName,
            //   formattedLastName,
            //   formattedEmailAddress,
            //   formattedPhoneNumber,
            //   formattedAddress,
            //   formattedCarModelAndPlateNo,
            //   maxAccomodation,
            //   isBaranggayRep
            // );
            analytics.logEvent("submit_transport_account", {
              content_type: ANALYTICS_CONTENT_TYPES.accountSetup.type,
            });
            setSetupCompleteDialogIsOpen(true);
            setLoading(false);
          } else {
            setError("The email address is already in use by another account.");
            setLoading(false);
          }
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <MBCard
      title="Account Setup"
      titleDescription="Transport"
      subtitle="Step 1 of 1"
      onBack={onCancel}
      onSubmit={onSubmit}
      mode="transport"
    >
      <form autoComplete="nope...">
        <div className="transport-account-setup-form-container">
          <div
            className={`transport-account-setup-form-description-container ${
              isMobile() && "mobile"
            }`}
          >
            <IonLabel className="mb-h2 ion-text-start">
              User Information
            </IonLabel>
            <IonLabel className="mb-body ion-text-start">
              Please provide information needed.
            </IonLabel>
          </div>
          <IonGrid className="transport-account-setup-form-container-grid ion-no-padding">
            <IonRow>
              <IonCol
                className={`transport-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="First name"
                  value={firstName}
                  type="text"
                  error={errorMsgFirstName}
                  onChange={(firstName) => {
                    setFirstName(firstName);
                    setErrorMsgFirstName("");
                  }}
                />
              </IonCol>
              <IonCol
                className={`transport-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Last name"
                  value={lastName}
                  type="text"
                  error={errorMsgLastName}
                  onChange={(lastName) => {
                    setLastName(lastName);
                    setErrorMsgLastName("");
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`transport-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Mobile Number"
                  value={phoneNumber}
                  type="tel"
                  error={errorMsgPhoneNumber}
                  onChange={(phoneNumber) => {
                    setPhoneNumber(phoneNumber);
                    setErrorMsgPhoneNumber("");
                  }}
                />
              </IonCol>
              <IonCol
                className={`transport-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Email Address"
                  value={emailAddress}
                  type="email"
                  error={errorMsgEmaillAddress}
                  onChange={(emailAddress) => {
                    setEmailAddress(emailAddress);
                    setErrorMsgEmaillAddress("");
                  }}
                  readonly={!_.isEmpty(authUser)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`transport-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <div className="transport-account-setup-form-container-col-select-hospital">
                  <MBInput
                    label="Address"
                    value={address}
                    type="text"
                    error={errorMsgAddress}
                    onChange={(address) => {
                      setAddress(address);
                      setErrorMsgAddress("");
                    }}
                  />
                </div>
              </IonCol>
            </IonRow>
            {_.isEmpty(authUser) && (
              <IonRow>
                <IonCol
                  className={`transport-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } last ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Password"
                    value={password}
                    type="password"
                    error={errorMsgPassword}
                    onChange={(emailAddress) => {
                      setPassword(emailAddress);
                      setErrorMsgPassword("");
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
            <IonRow>
              <IonCol
                className={`transport-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Car model / Plate number"
                  value={carModelAndPlateNo}
                  type="text"
                  error={errorMsgCarModelAndPlateNo}
                  onChange={(carModelAndPlateNo) => {
                    setCarModelAndPlateNo(carModelAndPlateNo);
                    setErrorMsgCarModelAndPlateNo("");
                  }}
                />
              </IonCol>
              <IonCol
                className={`transport-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Can accomodate how many?"
                  value={maxAccomodation.toString()}
                  type="number"
                  error={errorMsgMaxAccomodation}
                  onChange={(maxAccomodation) => {
                    setMaxAccomodation(+maxAccomodation);
                    setErrorMsgMaxAccomodation("");
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="transport-account-setup-form-footer-description-container">
            <div className="transport-account-setup-footer-checklist-details">
              <div className="transport-account-setup-footer-checklist-item ion-no-padding">
                <div>
                  <IonCheckbox
                    mode="md"
                    className="transport-account-setup-footer-checklist-checkbox ion-no-margin"
                    checked={covid19Safe}
                    onIonChange={(e) => {
                      setCovidChecklistDialogOpen(true);
                      // setCovif19Safe(e.detail.checked);
                    }}
                    color={MBCOLORS.tertiary}
                  />
                </div>
                <IonLabel className="transport-account-setup-footer-checklist-label mb-body ion-no-margin">
                  I am declaring myself as COVID-19 <b>negative</b>.
                </IonLabel>
              </div>
            </div>

            <div className="transport-account-setup-footer-checklist-details">
              <div className="transport-account-setup-footer-checklist-item ion-no-padding">
                <div>
                  <IonCheckbox
                    mode="md"
                    className="transport-account-setup-footer-checklist-checkbox ion-no-margin"
                    checked={isBaranggayRep}
                    onIonChange={(e) => setIsBaranggayRep(e.detail.checked)}
                    color={MBCOLORS.tertiary}
                  />
                </div>
                <IonLabel className="transport-account-setup-footer-checklist-label mb-body ion-no-margin">
                  Baranggay Representative
                </IonLabel>
              </div>
            </div>
            <div className="transport-account-setup-footer-checklist-details">
              <div className="transport-account-setup-footer-checklist-item ion-no-padding">
                <div>
                  <IonCheckbox
                    mode="md"
                    className="transport-account-setup-footer-checklist-checkbox ion-no-margin"
                    checked={haveReadPolicy}
                    onIonChange={(e) => setHaveReadPolicy(e.detail.checked)}
                    color={MBCOLORS.tertiary}
                  />
                </div>
                <IonLabel className="transport-account-setup-footer-checklist-label mb-body ion-no-margin">
                  I have read and understood the{" "}
                  <Link to={routes.DATA_PRIVACY} target="_blank">
                    <u>Data Privacy Policy</u>
                  </Link>
                </IonLabel>
              </div>
            </div>
          </div>
        </div>
      </form>
      {covidChecklistDialogOpen && (
        <CovidChecklistDialog
          isOpen={covidChecklistDialogOpen}
          onDidDismiss={() => setCovidChecklistDialogOpen(false)}
          onNext={(isCovidPositive: boolean) => {
            if (isCovidPositive) {
              setCovidFreeOnlyOpenDialog(true);
            } else {
              setCovidChecklistDialogOpen(false);
              setCovif19Safe(true);
            }
          }}
          onBack={() => {
            setCovidChecklistDialogOpen(false);
          }}
        />
      )}

      <MBDialog
        isOpen={questionnaireRequiredDialogOpen}
        title="Consent needed"
        message="We cannot proceed unless you have answered the COVID-19 self-assessment checklist, have read and agreed to the Data Privacy Policy. Please take your time in reading the policy."
        icon="warning"
        onDidDismiss={() => setQuestionnaireRequiredDialogOpen(false)}
      />

      <MBDialog
        isOpen={setupCompleteDialogIsOpen}
        title="Account setup complete"
        message="Your profile has now been added to the list of possible transportations. You will be required to declare yourself as COVID-19 negative every 14 days. Log in and follow instructions on how to declare yourself as COVID-19 negative."
        icon="success"
        onDidDismiss={() => {
          setSetupCompleteDialogIsOpen(false);
          // history.push(routes.TRANSPORT_ACCOUNT);
          window.location.reload(); //to solve the problem in white screen upon redirection
        }}
      />

      <MBDialog
        isOpen={covidFreeOnlyOpenDialog}
        title="You may have exposure or symptoms of COVID-19."
        message="Please review your assessment. If you truly have exposure or symptoms, please visit the nearest medical facility to have yourself checked. Else, please register again. Remember, your answer will help protect the lives of patients. Thank you!"
        icon="warning"
        onDidDismiss={() => {
          setCovidFreeOnlyOpenDialog(false);
          if (!_.isEmpty(authUser)) {
            services.signOut();
          }
          history.push(routes.LOGIN_OPTIONS);
        }}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </MBCard>
  );
};
