import React from "react";
import { IonIcon, IonLabel, IonCard } from "@ionic/react";

import "./MBDashboardCard.scss";

interface MBDashboardCardProps {
  title: string;
  message?: string;
  icon?: "doctor" | "service" | "ambulatory" | "booked" | "cancelled";
}

export const MBDashboardCard = (props: MBDashboardCardProps) => {
  const { icon, title, message } = props;
  return (
    <IonCard className="mb-dashboard-card">
      <div className="mb-dashboard-card-content-container">
        <IonIcon className={`mb-dashboard-card-icon ${icon}`} />
        <div className="mb-dashboard-card-details-container">
          <IonLabel className="mb-h1 dark-blue big">{title}</IonLabel>
          <IonLabel className="message mb-body dark-blue">{message}</IonLabel>
        </div>
      </div>
    </IonCard>
  );
};
