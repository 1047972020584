import { Helmet } from "react-helmet";
import React from "react";

import "./ForgotPassword.scss";
import { ForgotPasswordForm } from "../../components/ForgotPasswordForm/ForgotPasswordForm";
import { MBProps } from "../../interface";
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import { MBHeader } from "../../components/MBHeader/MBHeader";
import { MBFooter } from "../../components/MBFooter/MBFooter";

class ForgotPassword extends React.Component<MBProps> {
  render = () => {
    return (
      <>
        <Helmet>
          <title>MedBook - Forgot Password</title>
        </Helmet>
        <IonPage>
          <MBHeader {...this.props} />
          <IonContent className="mb-page-content-bg">
            <div className="mb-page-content-bg-card-container">
              <IonGrid className="mb-page-content-grid">
                <IonRow className="mb-page-content-row">
                  <IonCol
                    size="8"
                    className="mb-page-content-bg-image reset"
                  ></IonCol>
                  <IonCol size="4" className="mb-page-content-card-col">
                    <ForgotPasswordForm {...this.props} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonContent>
          <MBFooter />
        </IonPage>
      </>
    );
  };
}

export default ForgotPassword;
