import * as _ from "lodash";

import { getEnv } from "../env";
import { EmailObj } from "../interface";

var env = getEnv();

const medbookwebsite = env.firebase.websiteUrl;

export const contactUsEmailTemplate = (
  firstname: string,
  lastname: string,
  mobileNumber: string,
  inquiry: string,
  userEmail: string
): EmailObj => {
  const subject = `MedBookPH Inquiry - ${firstname} ${lastname}`;

  const message = `
    Email: ${userEmail} <br/>
    Name:  ${firstname} ${lastname} <br/>
    Phone Number: ${mobileNumber} <br/>
    <br/>
    Inquiry:
    <p>
    ${inquiry}
    </p>
  `;

  return {
    subject,
    message,
  };
};

export const successPatientRegistration = (fullName: string): EmailObj => {
  const subject = "MedBook Philippines - Successful Patient Registration";
  const message = `
  Hi ${fullName},<br/>
  <br/>   
  You are one step away from completing your registration. Please click the verification link sent in a separate email, <br/>
  after which you may now book appointments for treatments or consultations for your condition, at a hospital or physician of your choice. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const successHospitalRegistration = (
  fullName: string,
  hospitalName: string
): EmailObj => {
  const subject = "MedBook Philippines - Successful Hospital Registration";
  const message = `
  Hi ${fullName},<br/>
  <br/>   
  You and your hospital ${hospitalName} have been successfully registered in MedBook.  You may now enter your available treatment and consultation services. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const rejectedHospitalRegistration = (
  fullName: string,
  hospitalName: string,
  reason: string
): EmailObj => {
  const subject =
    "MedBook Philippines - Your Hospital application was not approved";
  const message = `
  Hi ${fullName},<br/>
  <br/>   
  We regret to inform you that you and your hospital ${hospitalName} has failed our verification process. <br/>
  <br/>
  Reason:<br/>
  ${reason}
  <br/>    
  <br/>
  If you wish to apply again please register again.
  <br/>
  <br/>
  Maraming Salamat!`;

  return {
    subject,
    message,
  };
};

export const updateTreatmentSchedule = (
  hospitalName: string,
  hospitalRepFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "MedBook Philippines – Changes in Patient schedule ";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName},<br/>
  <br/>
  Patient ${patientFullName} have changed their scheduled Treatment on ${originalDateOfSchedule}. Please review the changes made in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;
  return {
    subject,
    message,
  };
};

export const patientSetDoctorAppointment = (
  hospitalName: string,
  doctorName: string,
  secretaryName: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "Medbook Philippines - An Appoinment have been scheduled";
  const message = `
  Hi  ${hospitalName}, Dr. ${doctorName}, ${secretaryName}<br/>
  <br/>
  Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for Consultation. Please review the schedule in the MedBook platform <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  You MedBook Team`;
  return {
    subject,
    message,
  };
};

export const patientSetServiceAppointment = (
  hospitalName: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "Medbook Philippines - An Appoinment have been scheduled";
  const message = `
  Hi  ${hospitalName},<br/>
  <br/>
  Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for Out-Patient Service. Please review the schedule in the MedBook platform <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  You MedBook Team`;
  return {
    subject,
    message,
  };
};

export const patientUpdateAppointment = (
  hospitalRepFullName: string,
  hospitalName: string,
  doctorName: string,
  secretaryName: string,
  patientFullName: string,
  originalDateOfSchedule: string,
  serviceOrConsulationName: string
): EmailObj => {
  const subject = "Medbook Philippines - Changes in Patient schedule";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName}, Dr. ${doctorName}, ${secretaryName}<br/>
  <br/>
  Patient ${patientFullName} have changed their scheduled ${serviceOrConsulationName} on ${originalDateOfSchedule}. Please review the changed made in the MedBook platform <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  You MedBook Team`;
  return {
    subject,
    message,
  };
};

export const patientCancelServiceAppointment = (
  hospitalRepFullName: string,
  hospitalName: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "Medbook Philippines - Changes in Patient schedule";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName},<br/>
  <br/>
  Patient ${patientFullName} have cancelled their scheduled Out-Patient Service on ${originalDateOfSchedule}. Please review the changed made in the MedBook platform <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  You MedBook Team`;
  return {
    subject,
    message,
  };
};

export const patientCancelDoctorAppointment = (
  hospitalRepFullName: string,
  hospitalName: string,
  doctorName: string,
  secretaryName: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "Medbook Philippines - Changes in Patient schedule";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName}, Dr. ${doctorName}, ${secretaryName}<br/>
  <br/>
  Patient ${patientFullName} have cancelled their scheduled Consultation on ${originalDateOfSchedule}. Please review the changed made in the MedBook platform <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  You MedBook Team`;
  return {
    subject,
    message,
  };
};

export const hospitalCancelDoctorAppointment = (
  hospitalName: string,
  hospitalContact: string,
  doctorName: string,
  secretaryName: string,
  originalDateOfSchedule: string,
  patientName: string,
  reason: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi Dr. ${doctorName}, ${secretaryName}<br/>
  <br/>
  We are sorry for the inconvenience.
  <br/>
  ${hospitalName},  have cancelled your Consultation on ${originalDateOfSchedule} for patient ${patientName} due to ${reason}. Please contact ${hospitalName} at ${hospitalContact} for more information. The Same information can be found in the MedBook platform<br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  You MedBook Team`;
  return {
    subject,
    message,
  };
};

export const hospitalCancelServiceAppointment = (
  hospitalName: string,
  hospitalContact: string,
  originalDateOfSchedule: string,
  patientName: string,
  treatmentName: string,
  reason: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi ${patientName}<br/>
  <br/>
  We are sorry for the inconvenience.
  <br/>
  ${hospitalName},  have cancelled your ${treatmentName} on ${originalDateOfSchedule} due to ${reason}. Please contact ${hospitalName} at ${hospitalContact} for more information. The Same information can be found in the MedBook platform<br/>
  If you need to re-schedule, please visit MedBook <a href="${medbookwebsite}">${medbookwebsite}</a> and re-book your appointment.
  <br/>
  Maraming Salamat! <br/>
  <br/>
  You MedBook Team`;
  return {
    subject,
    message,
  };
};

export const updateConsultationSchedule = (
  hospitalName: string,
  hospitalRepFullName: string,
  doctorFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "MedBook Philippines – Changes in Patient schedule";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName}, Dr. ${doctorFullName} <br/>
    <br/>
    Patient ${patientFullName} have changed their scheduled Consultation on ${originalDateOfSchedule}. Please review the changes made in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
    <br/>
    Maraming Salamat! <br/>
    <br/>
    Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const cancelPatientConsultationSchedule = (
  hospitalName: string,
  hospitalRepFullName: string,
  doctorFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string,
  reason: string
): EmailObj => {
  const subject = "MedBook Philippines – Changes in Patient schedule";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName}, Dr. ${doctorFullName} <br/>
    <br/>
    Patient  ${patientFullName} have cancelled their scheduled Consultation on ${originalDateOfSchedule}. <br/>
    Reason: ${reason}. Please review the changes made in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
    <br/>
    Maraming Salamat! <br/>
    <br/>
    Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const scheduleTreatmentAppointment = (
  hospitalName: string,
  hospitalRepFullName: string,
  treatmentType: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "MedBook Philippines – An Appointment have been scheduled";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName},<br/>
    <br/>
    Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for ${treatmentType}. <br/>
    Please review the changes made in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
    <br/>
    Maraming Salamat! <br/>
    <br/>
    Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const scheduleConsultationAppointment = (
  hospitalName: string,
  hospitalRepFullName: string,
  doctorFullName: string,
  patientFullName: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "MedBook Philippines – An Appointment have been scheduled";
  const message = `
  Hi ${hospitalRepFullName} - ${hospitalName}, Dr. ${doctorFullName} <br/>
    <br/>
    Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for Consultation. <br/>
    Please review the changes made in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
    <br/>
    Maraming Salamat! <br/>
    <br/>
    Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const representativeCancelPatientSchedule = (
  patientFullName: string,
  hospitalName: string,
  originalDateOfSchedule: string,
  reason: string,
  hospitalContactNumber: string,
  treatmentType?: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi ${patientFullName}, <br/>
  <br/>
  We are sorry for the inconvenience. <br/>
  <br/>
  ${hospitalName} have cancelled your ${
    !!treatmentType ? treatmentType : "Consultation"
  } on ${originalDateOfSchedule} due to ${reason} Please contact ${hospitalName} at ${hospitalContactNumber} for more information. <br/>
  The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. If you need to re-schedule, please visit MedBook <a href="${medbookwebsite}">${medbookwebsite}</a> and re-book your appointment.  <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const representativeCancelDoctorSchedule = (
  patientFullName: string,
  hospitalName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  hospitalContactNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi Dr. ${doctorFullName}, ${patientFullName} <br/>
  <br/>
  We are sorry for the inconvenience. <br/>
  <br/>
  ${hospitalName} have cancelled your consultation on ${originalDateOfSchedule} due to ${reason}. <br/>
  Please contact ${hospitalName} at ${hospitalContactNumber} for more information. The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const representativeUpdatePatientSchedule = (
  patientFullName: string,
  hospitalName: string,
  originalDateOfSchedule: string,
  newDateOfSchedule: string,
  hospitalContactNumber: string,
  treatmentType?: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been updated ";
  const message = `
  Hi ${patientFullName},  <br/>
  <br/>
  ${hospitalName} have updated your ${
    !!treatmentType ? treatmentType : "consultation"
  } on ${originalDateOfSchedule}. It is now moved to ${newDateOfSchedule}. Please contact ${hospitalName} at ${hospitalContactNumber} for more information. 
  <br/>The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. If you need to re-schedule, please visit MedBook <a href="${medbookwebsite}">${medbookwebsite}</a> and re-book your appointment.  <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorUpdatePatientSchedule = (
  patientFullName: string,
  doctorName: string,
  originalDateOfSchedule: string,
  newDateOfSchedule: string,
  doctorNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been updated ";
  const message = `
  Hi ${patientFullName},  <br/>
  <br/>
  ${doctorName} have updated your consultation on ${originalDateOfSchedule}. It is now moved to ${newDateOfSchedule}. Please contact ${doctorName} at ${doctorNumber} for more information. 
  <br/>The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. If you need to re-schedule, please visit MedBook <a href="${medbookwebsite}">${medbookwebsite}</a> and re-book your appointment.  <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const representativeUpdateDoctorSchedule = (
  patientFullName: string,
  hospitalName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  newDateOfSchedule: string,
  hospitalContactNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been updated";
  const message = `
  Hi Dr. ${doctorFullName}, ${patientFullName} <br/>
  <br/>
  ${hospitalName} have udpated your consultation on ${originalDateOfSchedule}  for patient ${patientFullName}. 
  <br/>It is now moved to ${newDateOfSchedule}. Please contact ${hospitalName} at ${hospitalContactNumber} for more information. 
  <br/>The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorCancelPatientConsultationSchedule = (
  patientFullName: string,
  hospitalName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  hospitalContactNumber: string,
  treatmentType?: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi ${patientFullName}, <br/>
  <br/>
  We are sorry for the inconvenience. <br/>
  <br/>
 Dr. ${doctorFullName} have cancelled your Consultation on ${originalDateOfSchedule} due to ${reason}. 
 <br/>Please contact ${hospitalName} at ${hospitalContactNumber} for more information. The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. 
 <br/>If you need to re-schedule, please visit MedBook <a href="${medbookwebsite}">${medbookwebsite}</a> and re-book your appointment.  <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const secretaryCancelPatientAppointment = (
  patientFullName: string,
  secretaryName: string,
  originalDateOfSchedule: string,
  reason: string,
  secretaryContactNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi ${patientFullName},<br/>
  <br/>
  We are sorry for the inconvenience,
  <br/>
  ${secretaryName} have cancelled the Consultation on ${originalDateOfSchedule} due to ${reason}. 
  <br/>Please contact ${secretaryName} at ${secretaryContactNumber} for more information. The same information can be found in MedBook platform. <br/>
  If you need to re-schedule, please visit <a href="${medbookwebsite}">${medbookwebsite}</a> to rebook an appointment.
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const secretaryHospitalDoctorCancelPatientAppointment = (
  patientFullName: string,
  hospitalName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  doctorContactNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi ${hospitalName}, ${doctorFullName}<br/>
  <br/>
  We are sorry for the inconvenience,
  <br/>
  Dr. ${doctorFullName} have cancelled the Consultation for ${patientFullName} on ${originalDateOfSchedule} due to ${reason}. 
  <br/>Please contact ${doctorFullName} at ${doctorContactNumber} for more information. The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorCancelPatientAppointment = (
  patientFullName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  doctorContactNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi ${patientFullName},<br/>
  <br/>
  We are sorry for the inconvenience,
  <br/>
  Dr. ${doctorFullName} have cancelled the Consultation on ${originalDateOfSchedule} due to ${reason}. 
  <br/>Please contact ${doctorFullName} at ${doctorContactNumber} for more information. The same information can be found in MedBook platform. <br/>
  If you need to re-schedule, please visit <a href="${medbookwebsite}">${medbookwebsite}</a> to rebook an appointment.
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorHospitalSecretaryCancelPatientAppointment = (
  patientFullName: string,
  hospitalName: string,
  secretaryName: string,
  doctorFullName: string,
  originalDateOfSchedule: string,
  reason: string,
  doctorContactNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – Your schedule has been cancelled";
  const message = `
  Hi ${hospitalName}, ${secretaryName}<br/>
  <br/>
  We are sorry for the inconvenience,
  <br/>
  Dr. ${doctorFullName} have cancelled the Consultation for ${patientFullName} on ${originalDateOfSchedule} due to ${reason}. 
  <br/>Please contact ${doctorFullName} at ${doctorContactNumber} for more information. The same information can be found in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const hospitalRegisterVerificationRequest = (
  hospitalRepFullName: string,
  hospitalName: string,
  hospitalRepEmailAddress: string,
  hospitalRepContactNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – A Hospital is pending verification";
  const message = `
  Hi, <br/>
  <br/>
  ${hospitalRepFullName} with the following contacts details ${hospitalRepEmailAddress} / ${hospitalRepContactNumber} of ${hospitalName} have registered.  <br/>
  Please review and visit MedBook to reject or verify if the hospital is already part of the Bayanihan Cancer Network. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;
  return {
    subject,
    message,
  };
};

export const requestTransportation = (
  transportFullName: string,
  patientFullName: string,
  patientAddress: string,
  patientPhoneNumber: string,
  patientEmailAddress: string
): EmailObj => {
  const subject = "MedBook Philippines – You have a request for transport ";
  const message = `
  Hi, ${transportFullName}<br/>
  <br/>
  ${patientFullName} from ${patientAddress} is requesting transportation. Please contact the patient with contact details ${patientPhoneNumber} / ${patientEmailAddress}.
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;
  return {
    subject,
    message,
  };
};

export const existinDoctorRegisteredInNewHospital = (
  doctorFirstName: string,
  doctorLastName: string,
  hospitalRepName: string,
  hospitalName: string
): EmailObj => {
  const subject = "MedBook Philippines – Successful Physician Registration";
  const message = `Hi ${doctorFirstName} ${doctorLastName}, 
        <br/>
        <br/>${hospitalRepName} of ${hospitalName} have registered you in their hospital.  
        <br/>You may see this by accessing your account in <a href="${medbookwebsite}">${medbookwebsite}</a>/
        <br/>
        <br/>
        Maraming Salamat! 
        <br/>
        <br/>
        Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const updateDoctorAvailabilityByRepresentative = (
  doctorFullName: string,
  hospitalRepName: string,
  hospitalName: string
): EmailObj => {
  const subject = "MedBook Philippines – Availability Schedule Update";
  const message = `Hi ${doctorFullName}, 
        <br/>
        <br/>${hospitalRepName} of ${hospitalName} have updated your availability schedule in their hospital.  
        <br/>You may see this by accessing your account in <a href="${medbookwebsite}">${medbookwebsite}</a>/
        <br/>
        <br/>
        Maraming Salamat! 
        <br/>
        <br/>
        Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const updateDoctorAvailabilityByDoctor = (
  doctorFullName: string,
  hospitalRepName: string
): EmailObj => {
  const subject = "MedBook Philippines – Availability Schedule Update";
  const message = `Hi ${hospitalRepName}, 
        <br/>
        <br/>${doctorFullName} have updated their availability schedule for your hospital.  
        <br/>You may see this by accessing your account in <a href="${medbookwebsite}">${medbookwebsite}</a>/
        <br/>
        <br/>
        Maraming Salamat! 
        <br/>
        <br/>
        Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const secretaryUpdateDoctorSchedule = (
  secretaryName: string,
  doctorFullName: string,
  hospitalName: string
): EmailObj => {
  const subject = "MedBook Philippines – Availability Schedule Update";
  const message = `Hi ${hospitalName}, ${doctorFullName}, 
        <br/>
        <br/>${secretaryName} have updated ${doctorFullName} availability schedule for your hospital.  
        <br/>You may see this by accessing your account in <a href="${medbookwebsite}">${medbookwebsite}</a>/
        <br/>
        <br/>
        Maraming Salamat! 
        <br/>
        <br/>
        Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const updateDoctorAvailabilitySecretary = (
  secretaryName: string,
  doctorFullName: string
): EmailObj => {
  const subject = "MedBook Philippines – Availability Schedule Update";
  const message = `Hi ${secretaryName}, 
        <br/>
        <br/>${doctorFullName} have updated their availability schedule for your hospital.  
        <br/>You may see this by accessing your account in <a href="${medbookwebsite}">${medbookwebsite}</a>/
        <br/>
        <br/>
        Maraming Salamat! 
        <br/>
        <br/>
        Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const updateDoctorUserInfoByRepresentative = (
  doctorFullName: string,
  hospitalRepName: string,
  hospitalName: string
): EmailObj => {
  const subject = "MedBook Philippines – Availability Schedule Update";
  const message = `Hi ${doctorFullName}, 
        <br/>
        <br/>${hospitalRepName} of ${hospitalName} have updated your profile details in their hospital.  
        <br/>You may see this by accessing your account in <a href="${medbookwebsite}">${medbookwebsite}</a>/
        <br/>
        <br/>
        Maraming Salamat! 
        <br/>
        <br/>
        Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const supportGroupRegistrationRequest = (
  supportGroupName: string,
  supportGroupEmail: string,
  supportGroupNumber: string
): EmailObj => {
  const subject = "MedBook Philippines – A Support group is pending approval";
  const message = `
  Hi, <br/>
  <br/>
  ${supportGroupName} with the following contacts details ${supportGroupEmail} / ${supportGroupNumber} have registered.  <br/>
  Please review and visit MedBook to reject or approve this registration. <br/>
  <br/>
  Maraming Salamat! <br/>
  <br/>
  Your MedBook Team`;
  return {
    subject,
    message,
  };
};

export const successSupportGroupRegistration = (
  supportGroupName: string
): EmailObj => {
  const subject = "MedBook Philippines - Successful Support Group Registration";
  const message = `
  Hi ${supportGroupName},<br/>
  <br/>   
  Your support group have been successfully registered in MedBook. Your services will now be made public to our patients. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const rejectedSupportGroupRegistration = (
  supportGroupName: string,
  reason: string
): EmailObj => {
  const subject =
    "MedBook Philippines - Your support group registration was not approved";
  const message = `
  Hi ${supportGroupName},<br/>
  <br/>  
  We regret to inform you that your support group has failed our screening process. <br/>
  <br/>
  Reason:<br/>
  ${reason}
  <br/>    
  <br/>
  If you wish, you may register again.
  <br/>
  <br/>
  Maraming Salamat!`;

  return {
    subject,
    message,
  };
};

export const doctorOutOfOfficeSecretary = (
  secretaryName: string,
  doctorName: string,
  startVL: string,
  endVL: string
): EmailObj => {
  const subject = "MedBook Philippines – The Doctor is OUT";
  const message = `
  Hi ${secretaryName},<br/>  
  <br/>
  Dr. ${doctorName} is currently out from ${startVL}${
    !_.isEmpty(endVL) ? ` to ${endVL}` : ""
  } <br/>
  Some patients’ schedule may be affected. Please expect some calls from them. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorInOfOfficeSecretary = (
  secretaryName: string,
  doctorName: string
): EmailObj => {
  const subject = "MedBook Philippines – The Doctor is IN";
  const message = `
  Hi ${secretaryName},<br/>  
  <br/>
  Dr. ${doctorName} is now available and patients can start scheduling appointments again. <br/>  
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorOutOfOfficeHospitalRep = (
  hospitalName: string,
  doctorName: string,
  startVL: string,
  endVL: string
): EmailObj => {
  const subject = "MedBook Philippines – The Doctor is OUT";
  const message = `
  Hi ${hospitalName},<br/>  
  <br/>
  Dr. ${doctorName} is currently out from ${startVL}${
    !_.isEmpty(endVL) ? ` to ${endVL}` : ""
  } <br/>
  Some patients’ schedule may be affected. Please expect some calls from them. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorInOfOfficeHospitalRep = (
  hospitalName: string,
  doctorName: string
): EmailObj => {
  const subject = "MedBook Philippines – The Doctor is IN";
  const message = `
  Hi ${hospitalName},<br/>  
  <br/>
  Dr. ${doctorName} is now available and patients can start scheduling appointments again. <br/>  
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorOutOfOfficePatient = (
  patientName: string,
  doctorName: string,
  hospitalName: string,
  hospitalNumber: string,
  startVL: string,
  endVL: string
): EmailObj => {
  const subject = "MedBook Philippines – The Doctor is OUT";
  const message = `
  Hi ${patientName},<br/>  
  <br/>
  Dr. ${doctorName} is currently out from ${startVL}${
    !_.isEmpty(endVL) ? ` to ${endVL}` : ""
  } <br/>
  Your appointment may be affected. Please call the hospital at ${hospitalName} ${hospitalNumber}. <br/>
  You can also check back when your doctor returns.  <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const doctorInOfOfficePatient = (
  patientName: string,
  doctorName: string
): EmailObj => {
  const subject = "MedBook Philippines – The Doctor is IN";
  const message = `
  Hi ${patientName},<br/>  
  <br/>
  Dr. ${doctorName} is now available and patients can start scheduling appointments again. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const notifyPreferredDoctorPatientScheduleServices = (
  hospitalRepName: string,
  doctorName: string,
  deparment: string,
  treatmentType: string,
  patientFullName: string,
  hospitalContactNumber: string,
  originalDateOfSchedule: string
): EmailObj => {
  const subject = "MedBook Philippines – A Patient Booked a Service";
  const message = `
  Hi  Dr. ${doctorName},<br/>
    <br/>
    Patient ${patientFullName} have scheduled an appointment on ${originalDateOfSchedule} for ${treatmentType}. <br/>
    Please contact ${hospitalRepName} - ${deparment} at ${hospitalContactNumber} for details. 
    <br/>
    <br/>
    Maraming Salamat! <br/>
    <br/>
    Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const serviceArchived = (
  patientName: string,
  hospitalName: string,
  hospitalNumber: string,
  startDate: string,
  serviceName: string
): EmailObj => {
  const subject = "MedBook Philippines – Service will be archived";
  const message = `
  Hi ${patientName},<br/>  
  <br/>
  ${hospitalName} will archive Service ${serviceName} starting ${startDate} <br/>
  You will no longer be able to book this service after from this date onwards.<br/>
  Please call the hospital at ${hospitalNumber} for details. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const notifyPatientHospitalDeletedDoctor = (
  patientName: string,
  hospitalName: string,
  hospitalNumber: string,
  doctorName: string,
  reason: string
) => {
  const subject = "MedBook Philippines – Physician Account Removed ";
  const message = `
  Hi ${patientName},<br/>  
  <br/>
  ${hospitalName} has deleted the account of Dr. ${doctorName}.<br/>
  You will no longer be able to receive appointments in their hospital.<br/>
  Your appointments with him/her in this hospital have also been cancelled.
  Please call the hospital at ${hospitalNumber} for details. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const notifyDoctorHospitalDeletedDoctor = (
  hospitalName: string,
  hospitalNumber: string,
  doctorName: string,
  reason: string
) => {
  const subject = "MedBook Philippines – Physician Account Removed ";
  const message = `
  Hi ${doctorName},<br/>  
  <br/>
  ${hospitalName} has deleted your account.<br/>
  You will no longer be able to receive appointments in their hospital<br/>
  Your appointments with patients in this hospital have also been cancelled.
  Please call the hospital at ${hospitalNumber} for details. <br/>
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const inviteHospitalRepresentative = (
  hospitalRepName: string,
  hospitalName: string,
  hospitalNumber: string,
  inviteLink: string
) => {
  const subject =
    "MedBook Philippines – Invitation for Hospital Representative Position";
  const message = `
  Hi,<br/>  
  <br/>
  You have been invited by ${hospitalRepName} to become the new hospital representative of ${hospitalName}. <br/>
  Please click this link to proceed to registration.<br/>
  <a href="${medbookwebsite}${inviteLink}">${medbookwebsite}${inviteLink}</a> <br/>
  <br/>
  For details please call ${hospitalNumber}
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const inviteHospitalRepresentativeCancelled = (
  hospitalRepName: string,
  hospitalName: string,
  hospitalNumber: string
) => {
  const subject =
    "MedBook Philippines – Invitation for Hospital Representative Position has been cancelled";
  const message = `
  Hi,<br/>  
  <br/>
  The transfer account invite sent by ${hospitalRepName} for ${hospitalName} <br/>
  has been cancelled. The link sent will now be invalid. For details please call ${hospitalNumber}<br/>  
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const notifyPatientOfAvailableDoctorSlot = (
  patientName: string,
  doctorName: string,
  hospitalName: string,
  appointmentDate: string
): EmailObj => {
  const subject = "MedBook Philippines – Slot Available";
  const message = `
  Hi ${patientName},<br/>  
  <br/>
  A slot has just became available for ${doctorName} in ${hospitalName} for  ${appointmentDate}<br/>
  Please check your book list in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>  
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};

export const notifyPatientOfAvailableServiceSlot = (
  patientName: string,
  serviceName: string,
  hospitalName: string,
  appointmentDate: string
): EmailObj => {
  const subject = "MedBook Philippines – Slot Available";
  const message = `
  Hi ${patientName},<br/>  
  <br/>
  A slot has just became available for ${serviceName} in ${hospitalName} for  ${appointmentDate}<br/>
  Please check your book list in <a href="${medbookwebsite}">${medbookwebsite}</a>. <br/>  
  <br/>    
  <br/>
  Maraming Salamat! 
  <br/>
  <br/>
  Your MedBook Team`;

  return {
    subject,
    message,
  };
};
