import React, { useState } from "react";
import { list, calendarOutline, ellipsisVertical } from "ionicons/icons";
import {
  IonIcon,
  IonButton,
  IonLabel,
  IonItem,
  IonList,
  IonPopover,
} from "@ionic/react";

import "./MBAppointments.scss";
import { MBCOLORS } from "../../constants/config";

export type StatusTab = "today" | "booked" | "scheduled" | "done" | "cancelled";
interface MBAppointmentsProps {
  view: "list" | "calendar";
  status: StatusTab;
  onClickStatus: (status: StatusTab) => void;
  children: any;
  setSelectedView: (selectedView: "list" | "calendar") => void;
  onDownloadSchedule?: () => void;
  onFilterView?: () => void;
  onClearSchedule?: () => void;
}

export const MBAppointments = (props: MBAppointmentsProps) => {
  const {
    onClickStatus,
    children,
    setSelectedView,
    view,
    status,
    onDownloadSchedule,
    onFilterView,
    onClearSchedule,
  } = props;
  const [showOtherOptions, setShowOtherOptions] = useState(false);
  const [actionButtonsEvent, setActionButtonsEvent] = useState(
    undefined
  ) as any;
  return (
    <>
      <div className="mb-appointments-container">
        <div className="mb-appointments-header-container">
          <IonLabel className="mb-appointments-header-label mb-h2 dark-blue">
            My Appointments
          </IonLabel>
          <div className="mb-appointments-action-button-container">
            <IonButton
              color={MBCOLORS.tertiary}
              className={`mb-appointments-list-button ${
                view === "list" && "active"
              } ion-no-padding ion-no-margin`}
              onClick={() => {
                setSelectedView("list");
              }}
            >
              <IonIcon
                color={view === "list" ? MBCOLORS.primary : MBCOLORS.light}
                icon={list}
              />
            </IonButton>
            <IonButton
              color={MBCOLORS.tertiary}
              className={`mb-appointments-grid-button ${
                view === "calendar" && "active"
              } ion-no-padding ion-no-margin`}
              onClick={() => {
                setSelectedView("calendar");
              }}
            >
              <IonIcon
                color={view === "calendar" ? MBCOLORS.primary : MBCOLORS.light}
                icon={calendarOutline}
              />
            </IonButton>
            <IonButton
              color={MBCOLORS.light}
              fill="clear"
              className="mb-appointments-status-other-button ion-no-padding ion-no-margin"
              onClick={(e) => {
                setActionButtonsEvent(e.nativeEvent);
                setShowOtherOptions(true);
              }}
            >
              <IonIcon icon={ellipsisVertical} />
            </IonButton>
          </div>
        </div>
        {view === "list" && (
          <div className="mb-appointments-status-button-container ion-no-margin ion-no-padding">
            <IonButton
              fill="clear"
              mode="ios"
              className={`mb-appointments-status-button ${
                status === "today"
                  ? "active mb-body bold blue"
                  : "mb-body bold light"
              } ion-no-margin ion-no-padding`}
              onClick={() => {
                onClickStatus("today");
              }}
            >
              TODAY
            </IonButton>
            <IonButton
              fill="clear"
              mode="ios"
              className={`mb-appointments-status-button ${
                status === "booked"
                  ? "active mb-body bold blue"
                  : "mb-body bold light"
              } ion-no-margin ion-no-padding`}
              onClick={() => {
                onClickStatus("booked");
              }}
            >
              BOOKED
            </IonButton>
            <IonButton
              fill="clear"
              mode="ios"
              className={`mb-appointments-status-button ${
                status === "scheduled"
                  ? "active mb-body bold blue"
                  : "mb-body bold light"
              } ion-no-margin ion-no-padding`}
              onClick={() => {
                onClickStatus("scheduled");
              }}
            >
              SCHEDULED
            </IonButton>
            <IonButton
              fill="clear"
              mode="ios"
              className={`mb-appointments-status-button ${
                status === "done"
                  ? "active mb-body bold blue"
                  : "mb-body bold light"
              } ion-no-margin ion-no-padding`}
              onClick={() => {
                onClickStatus("done");
              }}
            >
              DONE
            </IonButton>
            <IonButton
              fill="clear"
              mode="ios"
              className={`mb-appointments-status-button ${
                status === "cancelled"
                  ? "active mb-body bold blue"
                  : "mb-body bold light"
              } ion-no-margin ion-no-padding`}
              onClick={() => {
                onClickStatus("cancelled");
              }}
            >
              CANCELLED
            </IonButton>
          </div>
        )}
        <div className="mb-appointments-children-container">{children}</div>
      </div>
      <IonPopover
        mode="md"
        isOpen={showOtherOptions}
        event={actionButtonsEvent}
        cssClass="mb-filter-popover noselect ion-no-padding"
        showBackdrop={false}
        onDidDismiss={() => {
          setShowOtherOptions(false);
        }}
      >
        <div className="mb-filter-list-container">
          <IonList className="mb-filter-list">
            <div className="mb-filter-item-container">
              {!!onDownloadSchedule && (
                <IonItem
                  className="mb-filter-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  lines="none"
                  onClick={() => {
                    onDownloadSchedule();
                  }}
                >
                  <IonLabel className="mb-filter-dropdown-label mb-h5">
                    <IonIcon
                      className="mb-filter-dropdown-modal-item-icon download"
                      slot="start"
                    />
                    Download Schedule
                  </IonLabel>
                </IonItem>
              )}
              {!!onFilterView && (
                <IonItem
                  className="mb-filter-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  lines="none"
                  onClick={() => {
                    onFilterView();
                  }}
                >
                  <IonLabel className="mb-filter-dropdown-label mb-h5">
                    <IonIcon
                      className="mb-filter-dropdown-modal-item-icon filter"
                      slot="start"
                    />
                    Filter View
                  </IonLabel>
                </IonItem>
              )}
              {!!onClearSchedule && (
                <IonItem
                  className="mb-filter-dropdown-modal-item ion-justify-content-center"
                  button={true}
                  lines="none"
                  onClick={() => {
                    onClearSchedule();
                  }}
                >
                  <IonLabel className="mb-filter-dropdown-label mb-h5 danger">
                    <IonIcon
                      className="mb-filter-dropdown-modal-item-icon clear"
                      slot="start"
                    />
                    Clear Schedule
                  </IonLabel>
                </IonItem>
              )}
            </div>
          </IonList>
        </div>
      </IonPopover>
    </>
  );
};
