import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { close, trash, calendar, time } from "ionicons/icons";
import {
  IonIcon,
  IonButton,
  IonModal,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonCard,
  IonCardContent,
  IonToast,
} from "@ionic/react";

import "./MBBookListDialog.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { useEffectOnlyOnce } from "../../functions/common";
import { MBProps } from "../../interface";
import {
  BookListItem,
  HospitalServiceAppointments,
  HospitalDoctorAppointments,
  ServiceResource,
  DoctorResource,
} from "../../models";
import { MBCOLORS } from "../../constants/config";

interface MBBookListDialogProps extends MBProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  bookList: BookListItem[];
  waitList?: boolean;
}
interface BookListWithCheckStatus {
  [bookingId: string]: boolean;
}
export const MBBookListDialog = (props: MBBookListDialogProps) => {
  const { waitList, isOpen, onDidDismiss, bookList } = props;

  const [bookListWithCheckStatus, setBookListWithCheckStatus] = useState(
    {} as BookListWithCheckStatus
  );

  const [bookingFee, setBookingFee] = useState(0);
  const [error, setError] = useState("");
  useEffectOnlyOnce(() => {
    const bookListWithCheckStatusInit = {} as BookListWithCheckStatus;
    bookList.forEach((booking) => {
      bookListWithCheckStatusInit[booking.docId || ""] = !!booking.toCheckout
        ? booking.toCheckout
        : false;
    });

    setBookListWithCheckStatus(bookListWithCheckStatusInit);
    getBookingFee();
  });

  const getBookingFee = async () => {
    try {
      const bookingFee = await services.getBookingFee();
      setBookingFee(bookingFee);
    } catch (e) {
      console.log("ERROR HERE in getBookingFee: ", e);
    }
  };

  const deleteSelectedBooking = async () => {
    const clonedBookListWithCheckStatus = _.cloneDeep(bookListWithCheckStatus);

    const deletedBooking = _.omitBy(
      clonedBookListWithCheckStatus,
      (selectedBooking) => {
        return !selectedBooking;
      }
    );
    const remaningBooking = _.omitBy(
      clonedBookListWithCheckStatus,
      (selectedBooking) => {
        return selectedBooking;
      }
    );

    Object.keys(deletedBooking).forEach(async (bookingId) => {
      await services.deletedSelectedBookingList(bookingId);
    });
    setBookListWithCheckStatus(remaningBooking);
  };
  const proceedCheckout = async () => {
    const clonedBookListWithCheckStatus = _.cloneDeep(bookListWithCheckStatus);

    const selectedBookingToCheckout = _.pickBy(
      clonedBookListWithCheckStatus,
      (toCheckoutBooking) => toCheckoutBooking
    );

    const hasUnavailableItem = Object.keys(selectedBookingToCheckout).map(
      (bookingId) => {
        return _.filter(
          bookList,
          (bookingItem) =>
            bookingItem.docId === bookingId && bookingItem.unavailable === true
        );
      }
    );

    console.log(
      "hasUnavailableItem ---- ",
      !_.isEmpty(_.flatten(hasUnavailableItem))
    );

    if (!_.isEmpty(_.flatten(hasUnavailableItem))) {
      setError(
        "Unavailable booking item is selected, Please review your Booking List or Book more."
      );
    } else {
      Object.keys(clonedBookListWithCheckStatus).forEach(async (bookingId) => {
        await services.setBookListToCheckout(
          bookingId,
          clonedBookListWithCheckStatus[bookingId]
        );
      });
      props.history.push(routes.PATIENT_CHECKOUT);
    }
  };

  const getNumberOfSelectedBooking = () => {
    return _.compact(
      Object.keys(bookListWithCheckStatus).map(
        (bookingId) => bookListWithCheckStatus[bookingId]
      )
    ).length;
  };
  console.log("bookingIdListStatus --- RENDER --- ", bookListWithCheckStatus);
  return (
    <>
      <IonModal
        cssClass="mb-booklist-dialog"
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
      >
        <div className="modal-content">
          <div className="mb-booklist-dialog-children-container">
            <div className="mb-booklist-dialog-header">
              <IonLabel className="mb-booklist-dialog-title-label mb-h1 dark-blue">
                {!waitList ? "Book" : "Waiting"} List
              </IonLabel>
              <IonButton fill="clear" onClick={onDidDismiss}>
                <IonIcon icon={close} />
              </IonButton>
            </div>
            <div className="mb-booklist-dialog-content">
              <IonGrid className="ion-no-padding ion-no-margin">
                <IonRow>
                  <IonCol className="ion-no-padding ion-no-margin" size="7">
                    <div className="mb-booklist-dialog-details">
                      <div className="mb-booklist-dialog-details-header">
                        <div className="mb-booklist-dialog-details-checkbox-container">
                          <IonCheckbox
                            checked={_.isEmpty(
                              _.filter(
                                Object.values(bookListWithCheckStatus).map(
                                  (booking) => booking
                                ),
                                (booking) => !booking
                              )
                            )}
                            onClick={(event) => {
                              let bookingIdListStatus = {} as {
                                [bookingId: string]: boolean;
                              };
                              Object.keys(bookListWithCheckStatus).forEach(
                                (key) => {
                                  bookingIdListStatus[key] =
                                    event.currentTarget.checked;
                                }
                              );
                              setBookListWithCheckStatus(bookingIdListStatus);
                            }}
                            mode="md"
                            className="mb-booklist-dialog-details-select-all-checkbox"
                          />
                          <IonLabel className="mb-body">
                            Select All ({bookList.length} Bookings)
                          </IonLabel>
                        </div>

                        <IonButton
                          color={MBCOLORS.danger}
                          className="mb-booklist-dialog-details-delete mb-body white ion-text-capitalize"
                          onClick={deleteSelectedBooking}
                        >
                          <IonIcon color={MBCOLORS.tertiary} icon={trash} />
                          Delete
                        </IonButton>
                      </div>

                      <div className="mb-booklist-booking-resource-list-container">
                        {bookList.map((bookListItem) => {
                          const serviceAppointment = bookListItem.appointment as HospitalServiceAppointments;
                          const doctorAppointment = bookListItem.appointment as HospitalDoctorAppointments;
                          const serviceResource = bookListItem.resource as ServiceResource;
                          const doctorResources = bookListItem.resource as DoctorResource;

                          const isDoctorAppointment =
                            bookListItem.isDoctorResource;
                          return (
                            <IonCard
                              key={`book-list-item-${bookListItem.docId || ""}`}
                              className="mb-booklist-booking-card ion-no-margin"
                            >
                              <IonCardContent className="mb-booklist-booking-card-content ion-no-padding">
                                {isDoctorAppointment ? (
                                  <>
                                    <IonCheckbox
                                      checked={
                                        bookListWithCheckStatus[
                                          bookListItem.docId as string
                                        ]
                                      }
                                      onClick={(event) => {
                                        const copyBookListWithCheckStatus = _.cloneDeep(
                                          bookListWithCheckStatus
                                        );

                                        const newBookListWithCheckStatus = {
                                          ...copyBookListWithCheckStatus,
                                          [bookListItem.docId as string]: event
                                            .currentTarget.checked,
                                        };
                                        setBookListWithCheckStatus(
                                          newBookListWithCheckStatus
                                        );
                                      }}
                                      mode="md"
                                      className="mb-booklist-booking-card-content-checkbox"
                                    />
                                    <IonIcon className="mb-booklist-booking-card-doctor-icon ion-no-margin" />
                                    <div className="mb-booklist-booking-card-resource-details">
                                      <IonLabel
                                        className="mb-h2 dark-blue bold"
                                        color={MBCOLORS.secondary}
                                      >
                                        <b>{doctorResources.fullName}</b> (
                                        {doctorResources.specialities[0].name})
                                      </IonLabel>
                                      <IonLabel className="mb-booklist-booking-card-hospital mb-body">
                                        {doctorResources.hospital.hospitalName}{" "}
                                        <span className="mb-booklist-booking-card-resource-type">
                                          • Consulation
                                        </span>
                                      </IonLabel>
                                      <div className="mb-booklist-booking-card-time-container">
                                        <IonLabel
                                          className="mb-body medium-light ion-margin-end ion-padding-end"
                                          color={MBCOLORS.medium}
                                        >
                                          <IonIcon
                                            className="ion-margin-end"
                                            icon={calendar}
                                          />
                                          {moment(
                                            doctorAppointment.appointmentDate.toDate()
                                          ).format("MMM DD, YYYY")}
                                        </IonLabel>
                                        <IonLabel
                                          className={`mb-body medium-light ${
                                            bookListItem.unavailable ||
                                            bookListItem.isWaitList
                                              ? "danger bold"
                                              : ""
                                          }`}
                                          color={MBCOLORS.medium}
                                        >
                                          {!bookListItem.isWaitList ? (
                                            !bookListItem.unavailable ? (
                                              <>
                                                <IonIcon
                                                  className="ion-margin-end"
                                                  icon={time}
                                                />
                                                {`Est. Time ${moment(
                                                  doctorAppointment.appointmentDate.toDate()
                                                ).format("HH:mm A")} - Slot ${
                                                  doctorAppointment.slotNumber
                                                }`}
                                              </>
                                            ) : (
                                              `Slot ${doctorAppointment.slotNumber} is not available`
                                            )
                                          ) : (
                                            "No Available Slot"
                                          )}
                                        </IonLabel>
                                      </div>
                                      <IonLabel className="mb-booklist-booking-card-fee mb-h3">
                                        <b>{`Php ${doctorResources.specialities[0].fee}`}</b>{" "}
                                        Consultation Fee
                                      </IonLabel>
                                    </div>
                                    <div className="mb-booklist-edit-button-container">
                                      <IonButton
                                        onClick={() => {
                                          props.history.push(
                                            routes.PATIENT_EDIT_BOOKLISTITEM.replace(
                                              ":bookListItemId",
                                              bookListItem.docId || ""
                                            )
                                          );
                                          onDidDismiss();
                                        }}
                                        className="mb-body blue ion-text-capitalize"
                                        fill="outline"
                                      >
                                        Edit
                                      </IonButton>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <IonCheckbox
                                      checked={
                                        bookListWithCheckStatus[
                                          bookListItem.docId as string
                                        ]
                                      }
                                      onClick={(event) => {
                                        const copyBookListWithCheckStatus = _.cloneDeep(
                                          bookListWithCheckStatus
                                        );

                                        const newBookListWithCheckStatus = {
                                          ...copyBookListWithCheckStatus,
                                          [bookListItem.docId as string]: event
                                            .currentTarget.checked,
                                        };
                                        setBookListWithCheckStatus(
                                          newBookListWithCheckStatus
                                        );
                                      }}
                                      mode="md"
                                      className="mb-booklist-booking-card-content-checkbox"
                                    />
                                    <IonIcon className="mb-booklist-booking-card-service-icon ion-no-margin" />
                                    <div className="mb-booklist-booking-card-resource-details">
                                      <IonLabel
                                        className="mb-h2 dark-blue bold"
                                        color={MBCOLORS.secondary}
                                      >
                                        {serviceResource.treatmentName}
                                      </IonLabel>
                                      <IonLabel className="mb-booklist-booking-card-hospital mb-body">
                                        {serviceResource.hospital.hospitalName}{" "}
                                        <span className="mb-booklist-booking-card-resource-type">
                                          • Ambulatory Care
                                        </span>
                                      </IonLabel>
                                      <div className="mb-booklist-booking-card-time-container">
                                        <IonLabel
                                          className="mb-body medium-light ion-margin-end ion-padding-end"
                                          color={MBCOLORS.medium}
                                        >
                                          <IonIcon
                                            className="ion-margin-end"
                                            icon={calendar}
                                          />
                                          {moment(
                                            serviceAppointment.appointmentDate.toDate()
                                          ).format("MMM DD, YYYY")}
                                        </IonLabel>
                                        <IonLabel
                                          className={`mb-body medium-light ${
                                            bookListItem.unavailable ||
                                            bookListItem.isWaitList
                                              ? "danger bold"
                                              : ""
                                          }`}
                                          color={MBCOLORS.medium}
                                        >
                                          {!bookListItem.isWaitList ? (
                                            !bookListItem.unavailable ? (
                                              <>
                                                <IonIcon
                                                  className="ion-margin-end"
                                                  icon={time}
                                                />
                                                {`Est. Time ${moment(
                                                  serviceAppointment.appointmentDate.toDate()
                                                ).format("HH:mm A")} - Slot ${
                                                  serviceAppointment.slotNumber
                                                }`}
                                              </>
                                            ) : (
                                              `Slot ${serviceAppointment.slotNumber} is not available`
                                            )
                                          ) : (
                                            "No Available Slot"
                                          )}
                                        </IonLabel>
                                      </div>
                                      <IonLabel className="mb-booklist-booking-card-fee mb-h3">
                                        <b>{`Php ${serviceResource.fee}`}</b>{" "}
                                        Service Fee
                                      </IonLabel>
                                    </div>
                                    <div className="mb-booklist-edit-button-container">
                                      <IonButton
                                        onClick={() => {
                                          props.history.push(
                                            routes.PATIENT_EDIT_BOOKLISTITEM.replace(
                                              ":bookListItemId",
                                              bookListItem.docId || ""
                                            )
                                          );
                                          onDidDismiss();
                                        }}
                                        className="mb-body blue ion-text-capitalize"
                                        fill="outline"
                                      >
                                        Edit
                                      </IonButton>
                                    </div>
                                  </>
                                )}
                              </IonCardContent>
                            </IonCard>
                          );
                        })}
                      </div>
                    </div>
                  </IonCol>
                  <IonCol
                    className="mb-booklist-dialog-summary ion-no-padding ion-no-margin"
                    size="5"
                  >
                    <IonLabel className="mb-booklist-dialog-summary-label mb-h4 bold ion-text-start">
                      Summary
                    </IonLabel>

                    <div className="mb-booklist-dialog-summary-booking-fees-container">
                      <div className="mb-booklist-dialog-summary-booking-fees-item">
                        <IonLabel className="mb-body">
                          Subtotal (
                          {!_.isEmpty(bookListWithCheckStatus)
                            ? getNumberOfSelectedBooking()
                            : 0}{" "}
                          booking):{" "}
                        </IonLabel>
                        <IonLabel className="mb-body bold">
                          Php{" "}
                          {!_.isEmpty(bookListWithCheckStatus)
                            ? getNumberOfSelectedBooking() * bookingFee
                            : 0}
                        </IonLabel>
                      </div>
                      <div className="mb-booklist-dialog-summary-booking-fees-item">
                        <IonLabel className="mb-body">Others</IonLabel>
                        <IonLabel className="mb-body bold">Php 0</IonLabel>
                      </div>
                    </div>

                    <div className="mb-booklist-dialog-summary-booking-fees-total-container">
                      <IonLabel className="mb-h3 dark-blue">Total</IonLabel>
                      <IonLabel className="mb-h3 bold dark-blue">
                        Php {getNumberOfSelectedBooking() * bookingFee}
                      </IonLabel>
                    </div>

                    <div className="mb-booklist-dialog-submit-container">
                      <IonButton
                        className="mb-booklist-dialog-submit-button mb-body bold ion-text-capitalize"
                        color={MBCOLORS.primary}
                        size="large"
                        fill="outline"
                        onClick={() => {
                          if (
                            props.history.location.pathname ===
                            routes.PATIENT_HOME
                          ) {
                            onDidDismiss();
                          } else {
                            props.history.push(routes.PATIENT_HOME);
                          }
                        }}
                      >
                        Book More
                      </IonButton>

                      <IonButton
                        disabled={
                          _.isEmpty(
                            _.find(
                              Object.keys(bookListWithCheckStatus),
                              (bookingId) => bookListWithCheckStatus[bookingId]
                            )
                          ) || waitList
                        }
                        className="mb-booklist-dialog-submit-button mb-body bold white ion-text-capitalize"
                        size="large"
                        onClick={proceedCheckout}
                      >
                        Proceed Check Out
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </div>
      </IonModal>
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
