import * as _ from "lodash";
import React, { useEffect } from "react";
import { logoFacebook, checkmark } from "ionicons/icons";
import { TimelineLite, Power3, Power4 } from "gsap";
import {
  IonButtons,
  IonInput,
  IonImg,
  IonRippleEffect,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonRow,
} from "@ionic/react";

import "./Tab4.scss";
import * as routes from "../../constants/routes";
import { LandingContact } from "./LandingContact";
interface PreregistrationProps {
  onGoHome: () => void;
}
const Tab4: React.FC<PreregistrationProps> = (props) => {
  let t1 = new TimelineLite({ delay: 0.1 });
  const { onGoHome } = props;
  useEffect(() => {
    t1.from(
      ".banneranim",
      { y: 15, opacity: 0, ease: Power3.easeInOut, delay: 0.8 },
      "Start"
    );

    t1.from(
      ".textanim",
      { y: 20, opacity: 0, ease: Power4.easeOut, delay: 0.4 },
      "Start"
    );
    t1.from(
      ".btnanim",
      { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.7 },
      "Start"
    );
  }, []);

  return (
    <IonPage className="tab-container">
      <IonHeader>
        <IonToolbar className="ion-padding-top ion-padding-left">
          <IonTitle onClick={onGoHome}>
            <img
              className="logo-padding"
              src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}
            />
          </IonTitle>
          <IonButtons slot="primary" className="ion-margin-right ml-50">
            {/* <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-margin-right mr-10"
              // fill="outline"
              color="primary"
              routerLink={routes.LOGIN_OPTIONS}
            >
              Login
            </IonButton> */}
            <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-padding-end ion-margin-right mr-50"
              fill="outline"
              color="primary"
            >
              Follow us
              <IonIcon slot="start" icon={logoFacebook} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* CONTENT */}
      <IonContent className="pb-0">
        <IonRow className="h100 landing-dark-blue"></IonRow>
        <IonRow className="bkg-blue ion-justify-content-center landing-dark-blue ion-padding-top  ">
          <IonCol
            className="white-text ion-padding-top pb-0  textanim "
            size="4"
          >
            <h1 className="lgH1">
              Monitor the day to day activities of your hospital
            </h1>
            <p>
              <strong> LIMITED VISIBILITY ON YOUR SERVICES NO MORE !</strong>
            </p>
            <p>
              Track and manage all schedules with ease in one centralized
              dashboard. Digitalize your operations to increase efficiency.{" "}
            </p>
            <IonButton
              className="main btnanim ion-text-capitalize"
              color="warning"
              href="#contact"
            >
              REQUEST FOR A DEMO
            </IonButton>
          </IonCol>

          <IonCol size="6" className="ion-padding-top pb-0 banneranim">
            {" "}
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/hospital-banner.png`}
            />
          </IonCol>
        </IonRow>

        {/* WHY MEDBOOK? */}
        <IonRow className="bkg-blue ion-justify-content-center teal ion-padding-bottom">
          <IonCard
            color="primary"
            className="card-no-shadow bkg-trans ion-justify-content-center"
          >
            <IonCardHeader>
              <IonCardTitle className="ion-text-center ion-padding-bottom">
                <h1>Why is Medbook a great fit for your organization?</h1>
              </IonCardTitle>
            </IonCardHeader>
            <IonRow className="ion-padding-bottom ion-justify-content-center">
              <IonCol size="3">
                <IonCard className="card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bord-20  ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/Realtime.svg`}
                  />
                  <h4 className="landing-dark-blue-text">
                    Booking in Real Time
                  </h4>
                  <p>
                    All consultations and treatment services can be booked and
                    scheduled online.{" "}
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
              <IonCol size="3">
                <IonCard className="card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bord-20 ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/GetFullOverview.svg`}
                  />
                  <h4 className="landing-dark-blue-text">
                    Get a Full Overview
                  </h4>
                  <p>Monitor all hospitals and clinics in real time.</p>
                  <br />
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
              <IonCol size="3">
                <IonCard className="card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent  bord-20 ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/PatientExperience.svg`}
                  />
                  <h4 className="landing-dark-blue-text">
                    Improve patient experience
                  </h4>
                  <p>
                    Medbook prioritizes time from beginning to end for a better
                    patient experience.
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
              <IonCol size="3">
                <IonCard className="card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent  bord-20 ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/CentralControl.svg`}
                  />
                  <h4 className="landing-dark-blue-text">Central Control</h4>
                  <p>
                    You have full control over service offerings, schedules,
                    pricing, and payments.
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonCard>
        </IonRow>

        {/* HOMO TO HOME IN 6 STEPS */}
        <IonRow className="ion-justify-content-center landing-dark-blue">
          <IonCard
            color="primary"
            className="landing-center card-no-shadow bkg-trans"
          >
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                <h1>Check to Checkups in 4 Steps</h1>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonRow>

        <IonRow className="ion-justify-content-center landing-dark-blue">
          <IonCol size="3">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step.svg`}
                  slot="start"
                />
                <h6 className="white-text">Login to Medbook</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/HospitalLogin.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="3">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step2.svg`}
                  slot="start"
                />
                <h6 className="white-text">Check your Schedule</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/HospitalCheckschedule.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center landing-dark-blue">
          <IonCol size="3">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step3.svg`}
                  slot="start"
                />
                <h6 className="white-text">
                  Receive the Patient & Scan QR Code
                </h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/CheckQRCode.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="3">
            <IonCard className="card-no-shadow ion-padding bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step4.svg`}
                  slot="start"
                />
                <h6 className="white-text">Perform the Procedure</h6>
              </IonItem>
              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/PerformService.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>

        {/* HOW EASY MEDBOOK */}
        <IonRow className="bkg-blue marg">
          <IonCard
            color="light"
            className="landing-center card-no-shadow ion-text-center bkg-trans"
          >
            <IonCardHeader>
              <IonCardTitle>
                <h1 className="landing-dark-blue-text">
                  {" "}
                  Making monitoring & management easy
                </h1>
              </IonCardTitle>
              <IonCardContent>
                Medbook gives you a full view on view of your schedules, prices,
                and reports
              </IonCardContent>

              <IonRow className=" ">
                <IonCol size="">
                  <IonCard className="card-no-shadow   bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/computers.png`}
                    />
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
                <IonCol size="">
                  <IonCard className="card-no-shadow ion-aling-item-center bord-20 ion-padding ion-text-center ion-justify-content-center ion-text-start ion-activatable ripple-parent bkg-trans ">
                    <IonItem
                      className="landing-light ion-margin-vertical boar-20 ion-padding"
                      lines="none"
                    >
                      <IonIcon src={checkmark} color="primary" slot="start" />
                      <h6 className="landing-dark-blue-text">
                        Perform the Procedure
                      </h6>
                    </IonItem>

                    <IonItem
                      className="landing-light ion-margin-vertical boar-20 ion-padding"
                      lines="none"
                    >
                      <IonIcon src={checkmark} color="primary" slot="start" />
                      <h6 className="landing-dark-blue-text">
                        Review Dashboard
                      </h6>
                    </IonItem>

                    <IonItem
                      className="landing-light ion-margin-vertical boar-10 ion-padding"
                      lines="none"
                    >
                      <IonIcon src={checkmark} color="primary" slot="start" />
                      <h6 className="landing-dark-blue-text">
                        Configure Prices
                      </h6>
                    </IonItem>

                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonCardHeader>
          </IonCard>
        </IonRow>

        {/* GET NOTIFIED */}
        {/* GET NOTIFIED */}
        <form>
          <IonCard className="card-no-shadow landing-light ion-padding">
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="6" className="padding">
                {" "}
                <h3 className="ion-text-end teal-text">
                  Get notified for the latest updates
                </h3>{" "}
              </IonCol>
              <IonCol size="1">
                {" "}
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput type="email" required></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="1">
                {" "}
                <IonButton
                  className="ion-text-capitalize"
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Subscribe
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCard>
        </form>

        {/* YOUMAY ASLO LIKE */}
        {/* 
<IonRow className="ion-justify-content-center six-steps ">
      <IonCard color="light"className="bkg-trans">
        <IonCardHeader>
          <IonCardTitle className="ion-text-center" ><h1 className="landing-dark-blue-text">You may also like</h1></IonCardTitle>
          <IonCardContent className="ion-text-center" >
          Medbook also have some partner brands that you can offer to your patients
        </IonCardContent>
        </IonCardHeader>
        <IonRow className="ion-justify-content-center">
      <IonCol size="2.5">
              <IonCard className="card-no-shadow ion-padding shadow bord-10">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/Ad1.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>
        <IonCol size="2.5">
                <IonCard className="card-no-shadow ion-padding shadow bord-10">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/Ad2.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>


        <IonCol size="2.5">
                <IonCard className="card-no-shadow ion-padding shadow bord-10">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/Ad3.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>
      </IonRow>

      </IonCard>
  




      </IonRow> */}

        {/* CONTACT US NEW */}

        <LandingContact />
      </IonContent>
    </IonPage>
  );
};

export default Tab4;
