import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonSegment,
  IonSegmentButton,
  IonTab,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { home, medkit, people, person } from "ionicons/icons";
import React from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route } from "react-router-dom";
import Details from "./Details";

import "./Landing.scss";
import Preregistration from "./Preregistration";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";
import Tab4 from "./Tab4";

class Landing extends React.Component<{}> {
  state = {
    otherView: "",
    selectedView: "home" as
      | "home"
      | "patient"
      | "doctor"
      | "hospital"
      | "preregistration",
  };
  render = () => {
    const { selectedView } = this.state;
    return (
      <>
        <Helmet>
          <title>MedBook</title>
        </Helmet>
        <IonPage>
          <IonContent>
            <div className="landing-content">
              {selectedView === "preregistration" ? (
                <Preregistration
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                  }}
                />
              ) : selectedView === "home" ? (
                <Tab3
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                  }}
                  onPreregister={() => {
                    this.setState({
                      selectedView: "preregistration",
                    });
                  }}
                  onGoToDoctor={() => {
                    this.setState({
                      selectedView: "doctor",
                    });
                  }}
                  onGoToHospital={() => {
                    this.setState({
                      selectedView: "hospital",
                    });
                  }}
                  onGoToPatient={() => {
                    this.setState({
                      selectedView: "patient",
                    });
                  }}
                />
              ) : selectedView === "patient" ? (
                <Tab1
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                  }}
                  onPreregister={() => {
                    this.setState({
                      selectedView: "preregistration",
                    });
                  }}
                />
              ) : selectedView === "doctor" ? (
                <Tab2
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                  }}
                />
              ) : (
                <Tab4
                  onGoHome={() => {
                    this.setState({
                      selectedView: "home",
                    });
                  }}
                />
              )}
            </div>
            <IonSegment
              className="landing-segment"
              value={selectedView}
              onIonChange={(e) => {
                console.log("Segment selected", e.detail.value);
                this.setState({
                  selectedView: e.detail.value,
                });
              }}
            >
              <IonSegmentButton value="home">
                <IonIcon icon={home} />
                <IonLabel className="ion-text-capitalize">Home</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="patient">
                <IonIcon icon={person} />
                <IonLabel className="ion-text-capitalize">For Patient</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="doctor">
                <IonIcon icon={people} />
                <IonLabel className="ion-text-capitalize">For Doctors</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="hospital">
                <IonIcon icon={medkit} />
                <IonLabel className="ion-text-capitalize">
                  For Hospitals
                </IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonContent>
        </IonPage>
      </>
    );
  };
}

export default Landing;
