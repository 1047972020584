/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import moment from "moment";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCheckbox,
} from "@ionic/react";

import "./PatientAccountSetup.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import * as sms from "../../functions/sms";
import { analytics, storage } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  validatePassword,
  useEffectOnlyOnce,
  isMobile,
} from "../../functions/common";
import { MBCard } from "../MBCard/MBCard";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBProps, AuthUser } from "../../interface";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../MBDropdownSelect/MBDropdownSelect";
import { MBDateInput } from "../MBDateInput/MBDateInput";
import { PROVINCES, ADDRESS } from "../../constants/address";
import { MSGS_COMMON, MSGS_SIGNUP } from "../../constants/messages";
import {
  LIMITS,
  MBCOLORS,
  ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";
import { MBFileInput } from "../MBFileInput/MBFileInput";

interface PatientAccountSetupProps extends MBProps {
  onCancel: () => void;
  authUser: AuthUser;
}

export const PatientAccountSetup = (props: PatientAccountSetupProps) => {
  const { onCancel, history, authUser } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [gender, setGender] = useState("");
  const [errorMsgGender, setErrorMsgGender] = useState("");
  const [birthday, setBirthday] = useState(null as Date | null);
  const [errorMsgBirthday, setErrorMsgBirthday] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [provinceSearch, setProvinceSearch] = useState("");

  const [selectedCityMunicipality, setSelectedCityMunicipality] = useState("");
  const [cityMunicipalitySearch, setCityMunicipalitySearch] = useState("");

  const [password, setPassword] = useState("");
  // const [covid19Safe, setCovif19Safe] = useState(false);
  // const [, set] = useState(false);
  const [haveReadPolicy, setHaveReadPolicy] = useState(false);
  const [hasGivenConsent, setHasGivenConsent] = useState(false);
  const [governmentIdFile, setGovernmentIdFile] = useState(null as any);
  const [errorMsgGovtIdFile, setErrorMsgGovtIdFile] = useState("");
  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorMsgProvince, setErrorMsgProvince] = useState("");
  const [errorMsgCityMunicipality, setErrorMsgCityMunicipality] = useState("");

  const [listOfCityMunicipality, setListOfCityMunicipality] = useState(
    [] as string[]
  );

  const [consentNeededDialogOpen, setConsentNeededDialogOpen] = useState(false);
  const [setupCompleteDialogIsOpen, setSetupCompleteDialogIsOpen] = useState(
    false
  );
  // const [covidChecklistDialogOpen, setCovidChecklistDialogOpen] = useState(
  //   false
  // );
  // const [showThankyouAnsweringCovid, setShowThankyouAnsweringCovid] = useState(
  //   false
  // );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    if (!_.isEmpty(authUser)) {
      setEmailAddress(authUser.authEmail);
    }
  });

  const onSubmit = async () => {
    setLoading(true);

    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);
    const formattedProvince = formatString(selectedProvince || "");
    const formattedGender = formatString(gender || "");
    const formattedCityMunicipality = formatString(
      selectedCityMunicipality || ""
    );
    const formattedPassword = password || "";

    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_SIGNUP.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_SIGNUP.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_SIGNUP.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedGender.length === 0) {
      errorMessage = MSGS_SIGNUP.gender.required;
      setErrorMsgGender(errorMessage);
    }
    if (_.isNull(birthday)) {
      errorMessage = MSGS_SIGNUP.birthday.required;
      setErrorMsgBirthday(errorMessage);
    }
    if (formattedPhoneNumber.length === 0) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (!validatePhoneNumber(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.invalidMobileNumber;
      setErrorMsgPhoneNumber(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_SIGNUP.email.required;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_SIGNUP.email.invalid;
      setErrorMsgEmaillAddress(errorMessage);
    }
    if (!governmentIdFile) {
      errorMessage = MSGS_SIGNUP.governmentId.required;
      setErrorMsgGovtIdFile(errorMessage);
    }

    if (_.isEmpty(authUser)) {
      if (formattedPassword.length === 0) {
        errorMessage = MSGS_SIGNUP.password.required;
        setErrorMsgPassword(errorMessage);
      }
      if (!validatePassword(formattedPassword)) {
        errorMessage = MSGS_SIGNUP.password.invalid;
        setErrorMsgPassword(errorMessage);
      }
    }
    if (formattedProvince.length === 0) {
      errorMessage = MSGS_SIGNUP.province;
      setErrorMsgProvince(errorMessage);
    }
    if (formattedCityMunicipality.length === 0) {
      errorMessage = MSGS_SIGNUP.cityMunicipality;
      setErrorMsgCityMunicipality(errorMessage);
    }
    const yearsOld = moment(new Date()).diff(
      moment(birthday, "MM/DD/YYYY"),
      "years"
    );

    if (yearsOld < 18) {
      errorMessage = "Only 18 Years old above can register for an account";
      setErrorMsgBirthday(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      if (!haveReadPolicy || !hasGivenConsent) {
        setLoading(false);
        setConsentNeededDialogOpen(true);
      } else {
        try {
          let docId = !_.isEmpty(authUser) ? authUser!.uid : null;
          if (_.isEmpty(docId)) {
            docId = await services.createUserUsingEmail(
              formattedEmailAddress,
              formattedPassword
            );
          }
          if (!_.isNull(docId)) {
            const ref = storage.ref();
            const governmentIdPath = `governmentId/${formattedLastName
              .replace(" ", "")
              .toLowerCase()}_${formattedFirstName}_${docId}/government_id_${new Date().valueOf()}`;
            const uploadGovernmentIdSnapshot = await ref
              .child(governmentIdPath)
              .put(governmentIdFile);
            const newGovermentIdURL = await uploadGovernmentIdSnapshot.ref.getDownloadURL();

            await services.createPatient(
              docId,
              formattedFirstName,
              formattedLastName,
              formattedEmailAddress,
              formattedPhoneNumber,
              formattedGender,
              birthday as Date,
              formattedProvince,
              formattedCityMunicipality,
              newGovermentIdURL
            );

            try {
              const { subject, message } = email.successPatientRegistration(
                `${formattedFirstName} ${formattedLastName}`
              );

              const smsMessage = sms.successPatientRegistration(
                `${formattedFirstName} ${formattedLastName}`
              );

              await services.sendEmail(formattedEmailAddress, subject, message);
              await api.sendSMS(formattedPhoneNumber, smsMessage);
            } catch (error) {
              console.log("ERROR SENDING NOTIFS", error);
            }
            analytics.logEvent("submit_patient_account", {
              content_type: ANALYTICS_CONTENT_TYPES.accountSetup.type,
            });
            setSetupCompleteDialogIsOpen(true);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <MBCard title="Register as a Patient" onBack={onCancel} onSubmit={onSubmit}>
      <form autoComplete="nope...">
        <div className="patient-account-setup-form-container">
          <div
            className={`patient-account-setup-form-description-container ${
              isMobile() && "mobile"
            }`}
          ></div>
          <IonGrid className="patient-account-setup-form-container-grid ion-no-padding">
            <IonRow>
              <IonCol
                className={`patient-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="First name"
                  value={firstName}
                  type="text"
                  error={errorMsgFirstName}
                  onChange={(firstName) => {
                    setFirstName(firstName);
                    setErrorMsgFirstName("");
                  }}
                />
              </IonCol>
              <IonCol
                className={`patient-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Last name"
                  value={lastName}
                  type="text"
                  error={errorMsgLastName}
                  onChange={(lastName) => {
                    setLastName(lastName);
                    setErrorMsgLastName("");
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`patient-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Mobile Number"
                  value={phoneNumber}
                  type="tel"
                  error={errorMsgPhoneNumber}
                  onChange={(phoneNumber) => {
                    setPhoneNumber(phoneNumber);
                    setErrorMsgPhoneNumber("");
                  }}
                />
              </IonCol>
              <IonCol
                className={`patient-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBInput
                  label="Email Address"
                  value={emailAddress}
                  type="email"
                  error={errorMsgEmaillAddress}
                  onChange={(emailAddress) => {
                    setEmailAddress(emailAddress);
                    setErrorMsgEmaillAddress("");
                  }}
                  readonly={!_.isEmpty(authUser)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`patient-account-setup-form-container-col has-dropdown-input ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <div className="patient-account-setup-dropdown-gender">
                  <MBDropdownSelect
                    label="Gender"
                    value={gender}
                    onSelectItem={(gender) => {
                      setGender(gender.name);
                      setErrorMsgGender("");
                    }}
                    selectFromOptionsRequired={true}
                    options={[
                      { id: "1", name: "Female" },
                      { id: "2", name: "Male" },
                    ]}
                    error={errorMsgGender}
                  />
                </div>
              </IonCol>
              <IonCol
                className={`patient-account-setup-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <MBDateInput
                  className="patient-birthday-input"
                  title="Birthday"
                  value={birthday}
                  placeholder="MM/DD/YYYY"
                  displayFormat="MM/DD/YYYY"
                  minValue={moment(new Date()).subtract(100, "years").toDate()}
                  maxValue={moment(new Date()).add(1, "years").toDate()}
                  onChange={(birthday) => {
                    setBirthday(birthday);
                    setErrorMsgBirthday("");
                  }}
                  hideIcon={true}
                  error={errorMsgBirthday}
                />
              </IonCol>
            </IonRow>
            <IonRow className="patient-account-row-dropdown">
              <IonCol
                className={`patient-account-setup-form-container-col has-dropdown-input  ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <div className="patient-account-setup-province-container">
                  <MBDropdownSelect
                    label="Province"
                    value={selectedProvince || provinceSearch}
                    onChange={(provinceSearch) => {
                      setProvinceSearch(provinceSearch);
                      setErrorMsgProvince("");
                      if (provinceSearch.trim() !== selectedProvince) {
                        setSelectedProvince("");
                      }
                    }}
                    onSelectItem={(selectedProvince) => {
                      setSelectedProvince(selectedProvince.name);
                      setListOfCityMunicipality(ADDRESS[selectedProvince.name]);
                      setSelectedCityMunicipality("");
                      setCityMunicipalitySearch("");
                      setErrorMsgProvince("");
                    }}
                    selectFromOptionsRequired={true}
                    options={PROVINCES.map((province, index) => {
                      return {
                        id: index.toString(),
                        name: province,
                      } as MBDropdownSelectOption;
                    })}
                    error={errorMsgProvince}
                  />
                </div>
              </IonCol>
              <IonCol
                className={`patient-account-setup-form-container-col has-dropdown-input ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size={`${isMobile() ? "12" : "6"}`}
              >
                <div className="patient-account-setup-province-container">
                  <MBDropdownSelect
                    label="City/Municipality"
                    value={selectedCityMunicipality || cityMunicipalitySearch}
                    onChange={(cityMunicipalitySearch) => {
                      setCityMunicipalitySearch(cityMunicipalitySearch);
                      setErrorMsgCityMunicipality("");
                      if (
                        cityMunicipalitySearch.trim() !==
                        selectedCityMunicipality
                      ) {
                        setSelectedCityMunicipality("");
                      }
                    }}
                    onSelectItem={(selectedCityMunicipality) => {
                      setSelectedCityMunicipality(
                        selectedCityMunicipality.name
                      );
                      setErrorMsgCityMunicipality("");
                    }}
                    selectFromOptionsRequired={true}
                    options={listOfCityMunicipality.map(
                      (cityMunicipality, index) => {
                        return {
                          id: index.toString(),
                          name: cityMunicipality,
                        } as MBDropdownSelectOption;
                      }
                    )}
                    error={errorMsgCityMunicipality}
                  />
                </div>
              </IonCol>
              <IonCol
                className={`patient-account-setup-form-container-col has-file-input ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBFileInput
                  label="Government ID"
                  onChange={(file) => {
                    setGovernmentIdFile(file);
                    setErrorMsgGovtIdFile("");
                  }}
                  error={errorMsgGovtIdFile}
                  disableTab={true}
                />
              </IonCol>
            </IonRow>
            {_.isEmpty(authUser) && (
              <IonRow>
                <IonCol
                  className={`patient-account-setup-form-container-col ${
                    isMobile() && "mobile"
                  } last ion-no-padding`}
                  size="12"
                >
                  <MBInput
                    label="Password"
                    value={password}
                    type="password"
                    error={errorMsgPassword}
                    onChange={(emailAddress) => {
                      setPassword(emailAddress);
                      setErrorMsgPassword("");
                    }}
                  />
                </IonCol>
              </IonRow>
            )}
          </IonGrid>

          <div className="patient-account-setup-form-footer-description-container">
            <IonLabel className="mb-h2 ion-text-start">
              Patient data privacy consent
            </IonLabel>
            <div className="patient-account-setup-data-privacy-consent-details">
              {/* <div className="patient-account-setup-data-privacy-consent-item ion-no-padding">
                <div>
                  <IonCheckbox
                    mode="md"
                    className="patient-account-setup-data-privacy-consent-checkbox ion-no-margin"
                    checked={isCovidRead}
                    onIonChange={(e) => {
                      setCovidChecklistDialogOpen(true);
                    }}
                    color={MBCOLORS.tertiary}
                  />
                </div>
                <IonLabel
                  className="patient-account-setup-covid-consent-label mb-body ion-no-margin"
                  onClick={() => {
                    setCovidChecklistDialogOpen(true);
                  }}
                >
                  {!isCovidRead ? (
                    <>
                      Click here to answer <b>the COVID-19 Self-Assessment</b>
                    </>
                  ) : (
                    <>
                      I have answered the <b>COVID-19 Self-Assessment</b>.
                    </>
                  )}
                </IonLabel>
              </div> */}
              <div className="patient-account-setup-data-privacy-consent-item ion-no-padding">
                <div>
                  <IonCheckbox
                    mode="md"
                    className="patient-account-setup-data-privacy-consent-checkbox ion-no-margin"
                    checked={haveReadPolicy}
                    onIonChange={(e) => setHaveReadPolicy(e.detail.checked)}
                    color={MBCOLORS.tertiary}
                  />
                </div>
                <IonLabel className="patient-account-setup-data-privacy-consent-label mb-body ion-no-margin">
                  I have read and understood the{" "}
                  <a
                    onClick={() => {
                      window.open(routes.DATA_PRIVACY);
                    }}
                  >
                    <u>Data Privacy Policy</u>
                  </a>
                </IonLabel>
              </div>
              <div className="patient-account-setup-data-privacy-consent-item ion-no-padding">
                <div>
                  <IonCheckbox
                    mode="md"
                    className="patient-account-setup-data-privacy-consent-checkbox ion-no-margin"
                    checked={hasGivenConsent}
                    onIonChange={(e) => setHasGivenConsent(e.detail.checked)}
                    color={MBCOLORS.tertiary}
                  />
                </div>
                <IonLabel className="patient-account-setup-data-privacy-consent-label mb-body ion-no-margin">
                  I agree and I am giving my consent to use my personal
                  information as stated in the privacy policy.
                </IonLabel>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* {covidChecklistDialogOpen && (
        <CovidChecklistDialog
          isOpen={covidChecklistDialogOpen}
          onDidDismiss={() => setCovidChecklistDialogOpen(false)}
          onNext={(isCovidPositive: boolean) => {
            if (isCovidPositive) {
              setCovidChecklistDialogOpen(false);
              setCovif19Safe(false);
              setShowThankyouAnsweringCovid(true);
            } else {
              setCovidChecklistDialogOpen(false);
              setCovif19Safe(true);
              setShowThankyouAnsweringCovid(true);
            }
            setIsCovidRead(true);
          }}
          onBack={() => {
            setCovidChecklistDialogOpen(false);
          }}
        />
      )} */}

      <MBDialog
        isOpen={consentNeededDialogOpen}
        title="Consent needed"
        message=" We cannot proceed unless you have read and agreed to the Data Privacy Policy. Please take your time in reading the policy."
        icon="warning"
        onDidDismiss={() => setConsentNeededDialogOpen(false)}
      />

      <MBDialog
        isOpen={setupCompleteDialogIsOpen}
        title="Account setup complete"
        message="We have sent you an email. Click the verification link to complete the account setup process."
        icon="success"
        onDidDismiss={() => {
          setSetupCompleteDialogIsOpen(false);
          history.push(routes.PATIENT_APPOINTMENTS);
          window.location.reload(); //to solve the problem in white screen upon redirection
        }}
      />
      {/* <MBDialog
        isOpen={showThankyouAnsweringCovid}
        title="Thank you for your response"
        message="You may now proceed with your registration."
        icon="success"
        onDidDismiss={() => {
          setShowThankyouAnsweringCovid(false);
        }}
      /> */}

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </MBCard>
  );
};
