import { ServiceSchedule, DoctorSchedule } from "../models";
import {
  UserType,
  AppointmentView,
  ResourceType,
  CancerType,
  Period,
  DaysOfWeek,
  RecurringPeriod,
  Department,
  PaymentMethod,
  AppointmentStatusType,
} from "../interface";
import {
  PATIENTS,
  DOCTORS,
  HOSPITAL_SERVICES,
  TRANSPORT,
  HOSPITALS,
  HOSPITAL_TIME_KEEPING,
  EXECUTIVE_ASSISTANT,
} from "./dbCollections";

export const MBCOLORS = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  success: "success",
  warning: "warning",
  danger: "danger",
  dark: "dark",
  medium: "medium",
  light: "light",
};

export const ANALYTICS_CONTENT_TYPES = {
  accountOptions: {
    type: "account-options",
  },
  accountSetup: {
    type: "account-setup",
  },
  patientAccount: {
    type: "patient-account",
  },
  patientAppointments: {
    type: "patient-appointments",
  },
  patientSupport: {
    type: "patient-support",
  },
  contactUs: {
    type: "contact-us",
  },
  login: {
    type: "login",
  },
  supportGroupRegistration: {
    type: "support-group-registration",
  },
  doctorAppointments: {
    type: "doctor-appointments",
  },
  doctorAccount: {
    type: "doctor-account",
  },
  transportAccount: {
    type: "transport-account",
  },
  hospitalRepAccount: {
    type: "hospital-rep-account",
  },
  hospitalSchedule: {
    type: "hospital-schedule",
  },
  hospitalDepartment: {
    type: "hospital-department",
  },
  supportAccount: {
    type: "support-account",
  },
  landingPage: {
    type: "landing-page",
  },
};

export interface UserAccountTypeInterface {
  id: UserType;
  collection: string;
  name: string;
  key: string;
}

export const PAYMENT_TYPES = {
  gCash: {
    id: 1 as PaymentMethod,
    name: "GCash",
  },
  card: {
    id: 2 as PaymentMethod,
    name: "Credit/ Debit Card",
  },
  bookingCredit: {
    id: 3 as PaymentMethod,
    name: "Booking Credit",
  },
};

export const USER_TYPES: {
  [key: string]: UserAccountTypeInterface;
} = {
  patients: {
    id: 1,
    collection: PATIENTS,
    name: "Patient",
    key: "patients",
  },
  hospitals: {
    id: 2,
    collection: HOSPITALS,
    name: "Hospital",
    key: "hospitals",
  },
  doctors: {
    id: 3,
    collection: DOCTORS,
    name: "Doctor",
    key: "doctors",
  },
  services: {
    id: 4,
    name: "Out-Patient Service",
    collection: HOSPITAL_SERVICES,
    key: "services",
  },
  support: {
    id: 5,
    name: "Support",
    collection: "",
    key: "support",
  },
  transport: {
    id: 6,
    name: "Transport Volunteer",
    collection: TRANSPORT,
    key: "transport",
  },
  techAdmin: {
    id: 7,
    name: "Tech Admin",
    collection: "",
    key: "techAdmin",
  },
  timeKeeping: {
    id: 8,
    name: "Time Keeping",
    collection: HOSPITAL_TIME_KEEPING,
    key: "timeKeeping",
  },
  executiveAssistant: {
    id: 9,
    name: "Executive Assistant",
    collection: EXECUTIVE_ASSISTANT,
    key: "executiveAssistant",
  },
};

export const USER_TYPE_KEYS = {
  [USER_TYPES.patients.id]: USER_TYPES.patients.key,
  [USER_TYPES.hospitals.id]: USER_TYPES.hospitals.key,
  [USER_TYPES.doctors.id]: USER_TYPES.doctors.key,
  [USER_TYPES.services.id]: USER_TYPES.services.key,
  [USER_TYPES.support.id]: USER_TYPES.support.key,
  [USER_TYPES.transport.id]: USER_TYPES.transport.key,
  [USER_TYPES.techAdmin.id]: USER_TYPES.techAdmin.key,
  [USER_TYPES.timeKeeping.id]: USER_TYPES.timeKeeping.key,
  [USER_TYPES.executiveAssistant.id]: USER_TYPES.executiveAssistant.key,
};

export interface DepartmentInterface {
  id: Department;
  name: string;
  key: string;
}

export const DEPARTMENTS: {
  [key: string]: DepartmentInterface;
} = {
  cancerCenter: {
    id: 1,
    name: "Cancer Center",
    key: "cancerCenter",
  },
  surgery: {
    id: 2,
    name: "Surgery",
    key: "surgery",
  },
  medicalOncology: {
    id: 3,
    name: "Medical Oncology",
    key: "medicalOncology",
  },
  radiationOncology: {
    id: 4,
    name: "Radiation Oncology",
    key: "radiationOncology",
  },
  pediatricOncology: {
    id: 5,
    name: "Pediatric Oncology",
    key: "pediatricOncology",
  },
  breastCenter: {
    id: 6,
    name: "Breast Center",
    key: "breastCenter",
  },
};

export const DEPARTMENT_KEYS = {
  [DEPARTMENTS.cancerCenter.id]: DEPARTMENTS.cancerCenter.key,
  [DEPARTMENTS.surgery.id]: DEPARTMENTS.surgery.key,
  [DEPARTMENTS.medicalOncology.id]: DEPARTMENTS.medicalOncology.key,
  [DEPARTMENTS.radiationOncology.id]: DEPARTMENTS.radiationOncology.key,
  [DEPARTMENTS.pediatricOncology.id]: DEPARTMENTS.pediatricOncology.key,
  [DEPARTMENTS.breastCenter.id]: DEPARTMENTS.breastCenter.key,
};

export interface AvailableServiceSchedule {
  availableSchedule: ServiceSchedule;
  slot: number;
  estimatedAppointmentTime: Date;
}

export interface AvailableDoctorSchedule {
  availableSchedule: DoctorSchedule;
  slot: number;
  estimatedAppointmentTime: Date;
}

export interface CancerTypeInterface {
  id: CancerType;
  name: string;
  key: string;
}

export const CANCER_TYPES: {
  [key: string]: CancerTypeInterface;
} = {
  iDontKnow: {
    id: 0,
    name: "I dont know...",
    key: "iDontKnow",
  },
  breast: {
    id: 1,
    name: "Breast",
    key: "breast",
  },
  lung: {
    id: 2,
    name: "Lung",
    key: "lung",
  },
  headAndNeck: {
    id: 3,
    name: "Head & Neck",
    key: "headAndNeck",
  },
  liver: {
    id: 4,
    name: "Liver",
    key: "liver",
  },
  biliary: {
    id: 5,
    name: "Biliary",
    key: "biliary",
  },
  pancreas: {
    id: 6,
    name: "Pancreas",
    key: "pancreas",
  },
  prostate: {
    id: 7,
    name: "Prostate",
    key: "prostate",
  },
  bone: {
    id: 8,
    name: "Bone",
    key: "bone",
  },
  colonAndRectal: {
    id: 9,
    name: "Colon & Rectal",
    key: "colonAndRectal",
  },
  gastric: {
    id: 10,
    name: "Gastric",
    key: "gastric",
  },
  kidney: {
    id: 11,
    name: "Kidney",
    key: "kidney",
  },
  bladder: {
    id: 12,
    name: "Bladder",
    key: "bladder",
  },
  centralNervousSystem: {
    id: 13,
    name: "Central Nervous System",
    key: "centralNervousSystem",
  },
  cervical: {
    id: 14,
    name: "Cervical",
    key: "cervical",
  },
  ovarian: {
    id: 15,
    name: "Ovarian",
    key: "ovarian",
  },
  testicular: {
    id: 16,
    name: "Testiscular",
    key: "testicular",
  },
  vulvar: {
    id: 17,
    name: "Vulvar",
    key: "vulvar",
  },
  leukemia: {
    id: 18,
    name: "Leukemia",
    key: "leukemia",
  },
};

export interface DaysOfWeekInterface {
  day: DaysOfWeek;
  name: string;
  key: string;
}

export const CANCER_TYPE_KEYS = {
  [CANCER_TYPES.iDontKnow.id]: CANCER_TYPES.iDontKnow.key,
  [CANCER_TYPES.breast.id]: CANCER_TYPES.breast.key,
  [CANCER_TYPES.lung.id]: CANCER_TYPES.lung.key,
  [CANCER_TYPES.headAndNeck.id]: CANCER_TYPES.headAndNeck.key,
  [CANCER_TYPES.liver.id]: CANCER_TYPES.liver.key,
  [CANCER_TYPES.biliary.id]: CANCER_TYPES.biliary.key,
  [CANCER_TYPES.pancreas.id]: CANCER_TYPES.pancreas.key,
  [CANCER_TYPES.prostate.id]: CANCER_TYPES.prostate.key,
  [CANCER_TYPES.bone.id]: CANCER_TYPES.bone.key,
  [CANCER_TYPES.colonAndRectal.id]: CANCER_TYPES.colonAndRectal.key,
  [CANCER_TYPES.gastric.id]: CANCER_TYPES.gastric.key,
  [CANCER_TYPES.kidney.id]: CANCER_TYPES.kidney.key,
  [CANCER_TYPES.bladder.id]: CANCER_TYPES.bladder.key,
  [CANCER_TYPES.centralNervousSystem.id]: CANCER_TYPES.centralNervousSystem.key,
  [CANCER_TYPES.cervical.id]: CANCER_TYPES.cervical.key,
  [CANCER_TYPES.ovarian.id]: CANCER_TYPES.ovarian.key,
  [CANCER_TYPES.testicular.id]: CANCER_TYPES.testicular.key,
  [CANCER_TYPES.vulvar.id]: CANCER_TYPES.vulvar.key,
  [CANCER_TYPES.leukemia.id]: CANCER_TYPES.leukemia.key,
};

export interface DaysOfWeekInterface {
  day: DaysOfWeek;
  name: string;
  key: string;
}

export const DAYS_OF_WEEK: { [key: string]: DaysOfWeekInterface } = {
  sunday: {
    day: 0,
    name: "Sunday",
    key: "sunday",
  },
  monday: {
    day: 1,
    name: "Monday",
    key: "monday",
  },
  tuesday: {
    day: 2,
    name: "Tuesday",
    key: "tuesday",
  },
  wednesday: {
    day: 3,
    name: "Wednesday",
    key: "wednesday",
  },
  thursday: {
    day: 4,
    name: "Thursday",
    key: "thursday",
  },
  friday: {
    day: 5,
    name: "Friday",
    key: "friday",
  },
  saturday: {
    day: 6,
    name: "Saturday",
    key: "saturday",
  },
};

export const DAYS_OF_WEEK_KEYS = {
  [DAYS_OF_WEEK.sunday.day]: DAYS_OF_WEEK.sunday.key,
  [DAYS_OF_WEEK.monday.day]: DAYS_OF_WEEK.monday.key,
  [DAYS_OF_WEEK.tuesday.day]: DAYS_OF_WEEK.tuesday.key,
  [DAYS_OF_WEEK.wednesday.day]: DAYS_OF_WEEK.wednesday.key,
  [DAYS_OF_WEEK.thursday.day]: DAYS_OF_WEEK.thursday.key,
  [DAYS_OF_WEEK.friday.day]: DAYS_OF_WEEK.friday.key,
  [DAYS_OF_WEEK.saturday.day]: DAYS_OF_WEEK.saturday.key,
};

export interface AppointmentStatusTypeInterface {
  id: AppointmentStatusType;
  name: string;
  key: string;
}

export const APPOINTMENT_STATUS_TYPES = {
  arrived: {
    id: 1,
    name: "Arrived",
    key: "arrived",
  } as AppointmentStatusTypeInterface,
  onGoing: {
    id: 2,
    name: "On Going",
    key: "onGoing",
  } as AppointmentStatusTypeInterface,
  done: {
    id: 3,
    name: "Done",
    key: "done",
  } as AppointmentStatusTypeInterface,
};

export interface ResourceTypeInterface {
  id: ResourceType;
  name: string;
  key: string;
}

export const RESOURCE_TYPES: {
  [key: string]: ResourceTypeInterface;
} = {
  services: {
    id: 1,
    name: "Services",
    key: "services",
  },
  consultation: {
    id: 2,
    name: "Consultation",
    key: "consultation",
  },
  timeKeeping: {
    id: 3,
    name: "Time Keeping",
    key: "timeKeeping",
  },
};

export const APPOINTMENT_TYPE_KEYS = {
  [RESOURCE_TYPES.services.id]: RESOURCE_TYPES.services.key,
  [RESOURCE_TYPES.consultation.id]: RESOURCE_TYPES.consultation.key,
  [RESOURCE_TYPES.timeKeeping.id]: RESOURCE_TYPES.timeKeeping.key,
};

export const APPOINTMENT_VIEW = {
  create: 1 as AppointmentView,
  read: 2 as AppointmentView,
};

export const LIMITS = {
  name: {
    single: 50,
    full: 100,
  },
  companyABN: {
    requiredLength: 11,
  },
  siteName: 100,
  siteJobCode: 50,
  siteAddress: 300,
  file: {
    count: 20,
    size: 5000000, // 5mb
    limitSizePretty: "5mb",
  },
};

interface PeriodInterface {
  id: Period;
  name: string;
  key: string;
}

export const PERIOD_TYPES: {
  [key: string]: PeriodInterface;
} = {
  am: {
    id: 1,
    name: "Morning",
    key: "am",
  },
  pm: {
    id: 2,
    name: "Afternoon",
    key: "pm",
  },
};

export const PERIOD_TYPE_KEYS = {
  [PERIOD_TYPES.am.id]: PERIOD_TYPES.am.key,
  [PERIOD_TYPES.pm.id]: PERIOD_TYPES.pm.key,
};

interface RecurringPeriodInterface {
  id: RecurringPeriod;
  name: string;
  key: string;
}

export const RECURRING_PERIOD: { [key: string]: RecurringPeriodInterface } = {
  daily: {
    id: 1,
    name: "Daily",
    key: "daily",
  },
  weekly: {
    id: 2,
    name: "Weekly",
    key: "weekly",
  },
  monthly: {
    id: 3,
    name: "Monthly",
    key: "monthly",
  },
};

export const RECURRING_PERIOD_KEYS = {
  [RECURRING_PERIOD.daily.id]: RECURRING_PERIOD.daily.key,
  [RECURRING_PERIOD.weekly.id]: RECURRING_PERIOD.weekly.key,
  [RECURRING_PERIOD.monthly.id]: RECURRING_PERIOD.monthly.key,
};

export const FIREBASE_EMAIL_MODES = {
  verifyEmail: "verifyEmail",
  resetPassword: "resetPassword",
  recoverEmail: "recoverEmail",
};

export const FIREBASE_ERROR_CODES = {
  invalidActionCode: "auth/invalid-action-code",
  userNotFound: "auth/user-not-found",
  incorrectPassword: "auth/wrong-password",
};
