import * as _ from "lodash";
import React, { useState } from "react";
import { close, trash } from "ionicons/icons";
import {
  IonIcon,
  IonButton,
  IonModal,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonCheckbox,
  IonToast,
  IonLoading,
} from "@ionic/react";

import "./MBAppointmentNotifDialog.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { MBProps } from "../../interface";
import {
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";
import { MBCOLORS, USER_TYPES } from "../../constants/config";
import { MBAppointmentList } from "../MBAppointmentList/MBAppointmentList";
import { MSGS_COMMON } from "../../constants/messages";

interface MBAppointmentNotifDialogProps extends MBProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  appointmentList: (
    | HospitalDoctorAppointmentsView
    | HospitalServiceAppointmentsView
  )[];
}
export const MBAppointmentNotifDialog = (
  props: MBAppointmentNotifDialogProps
) => {
  const { isOpen, onDidDismiss, appointmentList, authUser } = props;
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState(
    [] as string[]
  );

  const markAppointmentAsRead = async (appointmentIdsOverride?: string[]) => {
    try {
      setLoading(true);

      await Promise.all(
        (appointmentIdsOverride || selectedAppointmentIds).map(
          (appointmentId) => {
            const appointment = _.find(
              appointmentList,
              (appointment) => appointment.docId === appointmentId
            );
            const isDoctorAppointment =
              (appointment! as HospitalDoctorAppointmentsView).doctorDetails !==
              undefined;
            return services.updateAppointmentViewers(
              authUser.uid,
              appointmentId,
              isDoctorAppointment
            );
          }
        )
      );

      setSelectedAppointmentIds([]);
      setLoading(false);

      if (selectedAppointmentIds.length === appointmentList.length) {
        onDidDismiss();
      }
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const onViewAppointment = async (appointmentId: string) => {
    const appointment = _.find(
      appointmentList,
      (appointment) => appointment.docId === appointmentId
    );
    const isDoctorAppointment =
      (appointment! as HospitalDoctorAppointmentsView).doctorDetails !==
      undefined;

    await markAppointmentAsRead([appointmentId]);

    props.history.push(
      (authUser.userType === USER_TYPES.doctors.id
        ? routes.DOCTOR_APPOINTMENT_DETAIL
        : routes.HOSPITAL_APPOINTMENT_DETAIL
      )
        .replace(":resourceType", isDoctorAppointment ? "doctor" : "service")
        .replace(":appointmentId", appointmentId)
    );
  };

  return (
    <IonModal
      cssClass="mb-appointment-list-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="modal-content">
        <div className="mb-appointment-list-dialog-children-container">
          <div className="mb-appointment-list-dialog-header">
            <IonLabel className="mb-appointment-list-dialog-title-label mb-h1 dark-blue">
              Book List
            </IonLabel>
            <IonButton fill="clear" onClick={onDidDismiss}>
              <IonIcon icon={close} />
            </IonButton>
          </div>
          <div className="mb-appointment-list-dialog-content">
            <IonGrid className="ion-no-padding ion-no-margin">
              <IonRow>
                <IonCol className="ion-no-padding ion-no-margin" size="12">
                  <div className="mb-appointment-list-dialog-details">
                    <div className="mb-appointment-list-dialog-details-header">
                      <div className="mb-appointment-list-dialog-details-checkbox-container">
                        <IonCheckbox
                          checked={
                            selectedAppointmentIds.length ===
                            appointmentList.length
                          }
                          onClick={(event) => {
                            if (event.currentTarget.checked) {
                              setSelectedAppointmentIds(
                                appointmentList.map(
                                  (appointment) => appointment.docId || ""
                                )
                              );
                            } else {
                              setSelectedAppointmentIds([]);
                            }
                          }}
                          mode="md"
                          className="mb-appointment-list-dialog-details-select-all-checkbox"
                        />
                        <IonLabel className="mb-body">
                          Select All ({appointmentList.length} Bookings)
                        </IonLabel>
                      </div>

                      {selectedAppointmentIds.length > 0 && (
                        <IonButton
                          color={MBCOLORS.primary}
                          className="mb-appointment-list-dialog-details-delete mb-body white ion-text-capitalize"
                          onClick={() => {
                            markAppointmentAsRead();
                          }}
                        >
                          Mark all as Read
                        </IonButton>
                      )}
                    </div>
                    <div className="mb-appointment-list-appointments-container">
                      <MBAppointmentList
                        showCheckbox={true}
                        mode={
                          authUser.userType === USER_TYPES.doctors.id
                            ? "doctor"
                            : "hospital"
                        }
                        onCheckAppointment={(appointmentIdsWithCheck) => {
                          console.log(
                            "APPOINTMENTS GETTING CHECKED",
                            appointmentIdsWithCheck
                          );
                          setSelectedAppointmentIds(appointmentIdsWithCheck);
                        }}
                        selectedAppointments={selectedAppointmentIds}
                        appointmentData={appointmentList}
                        onClickView={async (appointmentId) => {
                          onViewAppointment(appointmentId);
                        }}
                        onClickCancel={(appointmentId) => {}}
                      />
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </div>

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={5000}
        onDidDismiss={() => {
          setError("");
        }}
        color={MBCOLORS.danger}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
    </IonModal>
  );
};
