import * as _ from "lodash";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import {
  addOutline,
  ellipsisHorizontal,
  pencilOutline,
  trashBin,
} from "ionicons/icons";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonPopover,
  IonRow,
  IonToast,
} from "@ionic/react";

import "./PatientAccount.scss";
import * as api from "../../api";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBDialog } from "../../components/MBDialog/MBDialog";
import { MBProps, AppointmentView } from "../../interface";
import { PatientAccountForm } from "../../components/PatientAccountForm/PatientAccountForm";
import {
  FamilyMember,
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
  Patient,
} from "../../models";
import { MSGS_COMMON } from "../../constants/messages";
import { ANALYTICS_CONTENT_TYPES, MBCOLORS } from "../../constants/config";
import { FamilyMemberFormDialog } from "../../components/FamilyMemberFormDialog/FamilyMemberFormDialog";
import { isHistoricalDate } from "../../functions/common";

class PatientAccount extends React.Component<MBProps> {
  state = {
    viewMode: 2 as AppointmentView,
    familyMembers: [] as FamilyMember[],
    doctorAppointments: [] as HospitalDoctorAppointmentsView[],
    serviceAppointments: [] as HospitalServiceAppointmentsView[],

    isOpenEditAccount: false,
    isDeleteProfileConfirmationDialogOpen: false,
    isOpenFamilyMemberDialog: false,
    showActionButtons: false,
    actionButtonsEvent: undefined as any,
    loading: true,
    error: "",

    isRemoveFamMemberConfirmationDialogOpen: false,
    willViewFamMember: false,
    toViewEditFamMemberData: {} as FamilyMember,
  };

  componentDidMount = () => {
    this.getPatientDoctorAppointments();
    this.getPatientServiceAppointments();
    this.getFamilyMembers();
  };

  getPatientDoctorAppointments = async () => {
    const { authUser } = this.props;
    try {
      await services.getDoctorAppointmentsPatientOrHospital(
        "patient",
        authUser.uid,
        (patientDoctorAppointments, error) => {
          if (!error) {
            this.setState({
              doctorAppointments: patientDoctorAppointments,
              loading: false,
            });
          }
        }
      );
    } catch (error) {
      console.log("error -- patient -- ", error);
    }
  };

  getPatientServiceAppointments = async () => {
    const { authUser } = this.props;
    try {
      await services.getServiceAppointmentsPatientOrHospital(
        "patient",
        authUser.uid,
        (patientServiceAppointments, error) => {
          if (!error) {
            this.setState({
              serviceAppointments: patientServiceAppointments,
            });
          }
        }
      );
    } catch (error) {
      console.log("error -- patient -- ", error);
    }
  };

  getConsolidatedAppointments = () => {
    const { doctorAppointments, serviceAppointments } = this.state;
    const consolidatedAppointments: (
      | HospitalDoctorAppointmentsView
      | HospitalServiceAppointmentsView
    )[] = _.concat(
      doctorAppointments || [],
      (serviceAppointments as (
        | HospitalDoctorAppointmentsView
        | HospitalServiceAppointmentsView
      )[]) || []
    );
    return _.orderBy(consolidatedAppointments, "appointmentDate", "asc");
  };

  getFamilyMembers = () => {
    services.getFamilyMember(
      this.props.authUser.uid,
      (familyMembers, error) => {
        this.setState({
          familyMembers,
          error,
        });
      }
    );
  };

  deleteProfile = async () => {
    const hasActiveAppointment = !_.isEmpty(
      _.filter(
        this.getConsolidatedAppointments(),
        (appointment) =>
          !appointment.isCancelled &&
          !isHistoricalDate(appointment.appointmentDate)
      )
    );
    if (hasActiveAppointment) {
      this.setState({
        error:
          "Unable to Delete you account, Please check your exisiting appointment/schedules.",
      });
    } else {
      try {
        this.setState({
          loading: true,
        });
        await api.deleteUser(this.props.authUser.authEmail);
        analytics.logEvent("delete_patient_account", {
          content_type: ANALYTICS_CONTENT_TYPES.patientAccount.type,
        });
        await services.signOut();
        this.setState({ loading: false });
      } catch (e) {
        this.setState({ loading: false, error: e });
      }
    }
  };

  deleteFamilyMember = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { toViewEditFamMemberData } = this.state;
      await services.deleteFamilyMember(toViewEditFamMemberData.docId || "");

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  };

  render = () => {
    const {
      familyMembers,
      isOpenEditAccount,
      isDeleteProfileConfirmationDialogOpen,
      isOpenFamilyMemberDialog,
      showActionButtons,
      actionButtonsEvent,
      loading,
      error,

      isRemoveFamMemberConfirmationDialogOpen,
      willViewFamMember,
      toViewEditFamMemberData,
    } = this.state;
    const patient = this.props.authUser.userDetails as Patient;

    return (
      <>
        <Helmet>
          <title>MedBook - Patient Account</title>
        </Helmet>
        <MBContainer {...this.props} activePage="account">
          <IonCard
            className="patient-account-detail-card ion-no-margin"
            color={MBCOLORS.tertiary}
          >
            <IonCardContent className="patient-account-detail-card-content ion-no-padding">
              <div className="patient-account-detail-card-content-details-container">
                <IonGrid className="patient-account-detail-card-grid-detail ion-no-padding ion-no-margin">
                  <IonRow className="patient-account-detail-card-row ion-no-padding ion-no-margin">
                    <IonCol
                      size="4"
                      className="patient-account-detail-card-col-icons ion-no-padding ion-no-margin"
                    >
                      <IonIcon className="patient-account-detail-card-details-icon ion-no-margin" />
                    </IonCol>
                    <IonCol
                      size="7"
                      className="patient-account-detail-card-col-details ion-no-padding ion-no-margin"
                    >
                      <div className="patient-account-detail-card-details-container">
                        <IonLabel className="mb-h1 dark-blue ion-no-padding ion-no-margin">
                          {`${patient.firstName} ${patient.lastName}`}
                        </IonLabel>
                        <div className="patient-account-detail-card-sub-detail-container">
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon gender" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {patient.gender}
                            </IonLabel>
                          </div>
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon birthday" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {moment(patient.birthday.toDate()).format(
                                "MMMM DD, YYYY"
                              )}
                            </IonLabel>
                          </div>
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon mail" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {patient.emailAddress}
                            </IonLabel>
                          </div>
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon mobile" />
                            <IonLabel className="mb-h3 ion-no-padding ion-no-margin">
                              {patient.phoneNumber}
                            </IonLabel>
                          </div>
                          <div className="detail-container">
                            <IonIcon className="md-detail-card-sub-detail-icon freebies" />
                            <IonLabel className="mb-h3 success ion-no-padding ion-no-margin">
                              {patient.bookingCredit !== undefined
                                ? patient.bookingCredit
                                : 0}{" "}
                              Free Bookings
                            </IonLabel>
                          </div>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol
                      size="1"
                      className="patient-account-detail-card-col-button ion-no-padding ion-no-margin"
                    >
                      <IonButton
                        className="patient-account-detail-card-action-button"
                        color={MBCOLORS.medium}
                        onClick={(e) => {
                          this.setState({
                            actionButtonsEvent: e.nativeEvent,
                            showActionButtons: true,
                          });
                        }}
                      >
                        <IonIcon icon={ellipsisHorizontal} />
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCardContent>
          </IonCard>

          <IonCard className="patient-account-assistants-card ion-no-margin">
            <IonCardHeader className="patient-account-assistants-card-header ion-no-padding">
              <IonLabel className="mb-body bold">Family Members</IonLabel>

              <IonButton
                onClick={() => {
                  this.setState({
                    toViewEditFamMemberData: {},
                    isOpenFamilyMemberDialog: true,
                  });
                }}
                color={MBCOLORS.primary}
                className="patient-account-assistants-card-add-button mb-body white bold ion-text-capitalize"
              >
                <IonIcon icon={addOutline} />
                Add
              </IonButton>
            </IonCardHeader>
            <IonCardContent className="patient-account-assistants-card-content">
              {!_.isEmpty(familyMembers) ? (
                <IonGrid className="ion-no-margin ion-no-padding">
                  {familyMembers!.map((familyMember, index) => {
                    return (
                      <IonRow
                        className={`patient-account-assistants-card-row ${
                          index % 2 === 0 ? "light" : ""
                        } ion-padding-horizontal`}
                      >
                        <IonCol
                          size="5"
                          className="patient-account-assistants-card-col ion-no-margin ion-no-padding"
                        >
                          <IonLabel className="mb-body">
                            {`${familyMember.firstName} ${familyMember.lastName}`}
                          </IonLabel>
                        </IonCol>
                        <IonCol
                          size="5"
                          className="patient-account-assistants-card-col ion-no-margin ion-no-padding"
                        >
                          <IonLabel className="mb-body">
                            {`${moment().diff(
                              familyMember.birthday.toDate(),
                              "years",
                              false
                            )} years old`}
                          </IonLabel>
                        </IonCol>
                        <IonCol
                          size="2"
                          className="patient-account-assistants-card-col button ion-no-margin ion-no-padding"
                        >
                          <IonButton
                            className="mb-doctor-detail-card-action-button"
                            fill="clear"
                            onClick={(e) => {
                              this.setState({
                                willViewFamMember: true,
                                toViewEditFamMemberData: familyMember,
                                actionButtonsEvent: e.nativeEvent,
                                showActionButtons: true,
                              });
                            }}
                          >
                            <IonIcon
                              color={MBCOLORS.dark}
                              icon={ellipsisHorizontal}
                            />
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    );
                  })}
                </IonGrid>
              ) : (
                <IonLabel className="mb-body ion-text-center">
                  {" "}
                  No Results...
                </IonLabel>
              )}
            </IonCardContent>
          </IonCard>

          {isOpenFamilyMemberDialog && (
            <FamilyMemberFormDialog
              {...this.props}
              isOpen={isOpenFamilyMemberDialog}
              onCancel={() => {
                this.setState({
                  isOpenFamilyMemberDialog: false,
                });
              }}
              {...(!_.isEmpty(toViewEditFamMemberData) && {
                editData: toViewEditFamMemberData,
              })}
            />
          )}

          <IonModal
            isOpen={isOpenEditAccount}
            onDidDismiss={() => {
              this.setState({
                isOpenEditAccount: false,
              });
            }}
            cssClass="patient-account-form-modal"
          >
            {!!patient && !_.isEmpty(patient) && (
              <PatientAccountForm
                {...this.props}
                onBack={() => {
                  this.setState({
                    isOpenEditAccount: false,
                  });
                }}
                patient={patient}
              />
            )}
          </IonModal>
          <IonPopover
            mode="md"
            isOpen={showActionButtons}
            event={actionButtonsEvent}
            cssClass="patient-account-detail-card-popover noselect ion-no-padding"
            showBackdrop={false}
            onDidDismiss={() => {
              this.setState({
                showActionButtons: false,
              });
            }}
          >
            <div className="patient-account-detail-card-list-container">
              <IonList className="patient-account-detail-card-list">
                <div className="patient-account-detail-card-item-container">
                  <IonItem
                    className="patient-account-detail-card-dropdown-modal-item ion-justify-content-center"
                    button={true}
                    lines="none"
                    onClick={() => {
                      if (!willViewFamMember) {
                        this.setState({
                          isOpenEditAccount: true,
                          showActionButtons: false,
                        });
                      } else {
                        this.setState({
                          isOpenFamilyMemberDialog: true,
                          showActionButtons: false,
                        });
                      }
                    }}
                  >
                    <IonLabel className="patient-account-detail-card-dropdown-label mb-h5">
                      <IonIcon
                        className="patient-account-detail-card-dropdown-modal-item-icon"
                        icon={pencilOutline}
                        slot="start"
                      />
                      {!willViewFamMember ? "Edit" : "View / Edit"}
                    </IonLabel>
                  </IonItem>
                  <IonItem
                    className="patient-account-detail-card-dropdown-modal-item ion-justify-content-center"
                    button={true}
                    onClick={() => {
                      if (!willViewFamMember) {
                        this.setState({
                          isDeleteProfileConfirmationDialogOpen: true,
                          showActionButtons: false,
                        });
                      } else {
                        this.setState({
                          isRemoveFamMemberConfirmationDialogOpen: true,
                          showActionButtons: false,
                        });
                      }
                    }}
                    lines="none"
                  >
                    <IonLabel className="patient-account-detail-card-dropdown-label mb-h5">
                      <IonIcon
                        className="patient-account-detail-card-dropdown-modal-item-icon"
                        icon={trashBin}
                        slot="start"
                      />
                      Remove
                    </IonLabel>
                  </IonItem>
                </div>
              </IonList>
            </div>
          </IonPopover>

          <MBDialog
            isOpen={isDeleteProfileConfirmationDialogOpen}
            icon="warning"
            title="You are about to delete your profile"
            message="Are you sure you want to delete your profile?"
            onDidDismiss={() => {
              this.setState({
                isDeleteProfileConfirmationDialogOpen: false,
              });
            }}
            onApprove={() => {
              this.deleteProfile();
              this.setState({
                isDeleteProfileConfirmationDialogOpen: false,
              });
            }}
            onDecline={() => {
              this.setState({
                isDeleteProfileConfirmationDialogOpen: false,
              });
            }}
          />

          <MBDialog
            isOpen={isRemoveFamMemberConfirmationDialogOpen}
            icon="warning"
            title="You are about to remove this family member"
            message="Are you sure you want to remove this family member?"
            onDidDismiss={() => {
              this.setState({
                isRemoveFamMemberConfirmationDialogOpen: false,
              });
            }}
            onApprove={() => {
              this.deleteFamilyMember();
              this.setState({
                isRemoveFamMemberConfirmationDialogOpen: false,
              });
            }}
            onDecline={() => {
              this.setState({
                isRemoveFamMemberConfirmationDialogOpen: false,
              });
            }}
          />

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />

          <IonToast
            isOpen={!_.isEmpty(error)}
            message={error}
            duration={2000}
            onDidDismiss={() => {
              this.setState({
                error: "",
              });
            }}
            color={MBCOLORS.danger}
          />
        </MBContainer>
      </>
    );
  };
}

export default PatientAccount;
