import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonLoading,
  IonInput,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonRow,
} from "@ionic/react";
import * as routes from "../../constants/routes";
import { logoFacebook, call, mail, person } from "ionicons/icons";
import { TimelineLite, Power3, Power4, Power2 } from "gsap";
import "./Preregistration.scss";

interface PreregistrationProps {
  onGoHome: () => void;
}
const Preregistration: React.FC<PreregistrationProps> = (props) => {
  const { onGoHome } = props;
  const [text, setText] = useState<string>();

  const [gender, setGender] = useState<string>();
  const [showLoading, setShowLoading] = useState(true);

  setTimeout(() => {
    setShowLoading(false);
  }, 1000);

  let t1 = new TimelineLite({ delay: 0.1 });

  useEffect(() => {
    t1.from(
      ".banneranim",
      { y: 15, opacity: 0, ease: Power3.easeInOut, delay: 0.8, yoyo: true },
      "Start"
    );

    t1.from(
      ".textanim",
      { y: 20, opacity: 0, ease: Power4.easeOut, delay: 0.4 },
      "Start"
    );
    t1.from(
      ".btnanim",
      { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".box",
      { x: 20, opacity: 0, ease: Power3.easeInOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".headanim",
      { y: -100, opacity: 0, ease: Power2.easeInOut, delay: 0 },
      "Start"
    );
  }, []);

  return (
    <IonPage className="bkg-gray">
      <IonHeader>
        <IonToolbar className="ion-padding-top ion-padding-left">
          <IonTitle onClick={onGoHome}>
            <img
              className="logo-padding"
              src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}
            />
          </IonTitle>

          <IonButtons slot="primary" className="ion-margin-right ml-50">
            {/* <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-margin-right mr-10"
              // fill="outline"
              color="primary"
              routerLink={routes.LOGIN_OPTIONS}
            >
              Login
            </IonButton> */}
            <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-padding-end ion-margin-right mr-50"
              fill="outline"
              color="primary"
            >
              Follow us
              <IonIcon slot="start" icon={logoFacebook} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* CONTENT */}
      <IonContent>
        <IonRow className="ion-justify-content-center landing-light bkg-gray textanim">
          <IonCol
            size="5"
            className="ion-justify-content-center ion-text-align-center"
          >
            <IonCard className="shadow ion-padding preregistration bord20">
              <h3 className="landing-dark-blue-text mb-h2 dark-blue bold">
                Pre-Register Now!
              </h3>
              <p>
                Get a <strong>10 FREE bookings</strong> upon pre-registration.
              </p>
              <form>
                <IonItem>
                  <IonLabel position="floating">Full Name</IonLabel>
                  <IonInput type="text" clearInput required></IonInput>
                  <IonIcon
                    className="preregister-icon"
                    color="medium"
                    src={person}
                    slot="end"
                  ></IonIcon>
                </IonItem>
                <br></br>
                <IonItem>
                  <IonLabel position="floating">Mobile Number</IonLabel>
                  <IonInput type="number" required>
                    {" "}
                  </IonInput>
                  <IonIcon
                    className="preregister-icon"
                    color="medium"
                    src={call}
                    slot="end"
                  ></IonIcon>
                </IonItem>
                <br></br>

                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput type="email" required>
                    {" "}
                  </IonInput>
                  <IonIcon
                    className="preregister-icon"
                    color="medium"
                    src={mail}
                    slot="end"
                  ></IonIcon>
                </IonItem>
                <br></br>

                {/* SURVEY */}

                <IonCard className="card-no-shadow" color="right"></IonCard>

                <p className="">
                  {" "}
                  <strong> Quick Survey (Optional)</strong>{" "}
                </p>

                <IonItem className="landing-light" lines="none">
                  <p>Top 3 hospitals that you visit</p>
                </IonItem>
                <br></br>

                <IonItem>
                  <IonLabel position="stacked">Hospital 1</IonLabel>
                  <IonInput type="text" clearInput>
                    {" "}
                  </IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Hospital 2</IonLabel>
                  <IonInput type="text" clearInput>
                    {" "}
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Hospital 3</IonLabel>
                  <IonInput type="text" clearInput>
                    {" "}
                  </IonInput>
                </IonItem>

                <br></br>

                <IonItem className="landing-light" lines="none">
                  <p>Top 3 Services that you usually avail</p>
                </IonItem>
                <br></br>

                <IonItem>
                  <IonLabel position="stacked">Service 1</IonLabel>
                  <IonInput type="text" clearInput>
                    {" "}
                  </IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">Service 2</IonLabel>
                  <IonInput type="text" clearInput>
                    {" "}
                  </IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Service 3</IonLabel>
                  <IonInput type="text" clearInput>
                    {" "}
                  </IonInput>
                </IonItem>

                <br></br>
                <br></br>

                <IonButton
                  onClick={onGoHome}
                  className="landing-light black ion-margin-right"
                >
                  Back
                </IonButton>

                <IonButton
                  type="submit"
                  className="ion-margin-left"
                  color="primary"
                >
                  Submit
                </IonButton>
              </form>

              <br></br>
              <br></br>
              <br></br>
            </IonCard>
          </IonCol>

          <IonCol size="5" className="ion-self-aling-center btnanim">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/preregister-illustration.svg`}
            ></img>
          </IonCol>

          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"We are getting there..."}
            duration={5000}
          />
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Preregistration;
