import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState, useEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonLabel,
  IonButton,
} from "@ionic/react";

import "./ForgotPasswordForm.scss";
import * as services from "../../services";
import {
  checkIfCanVerifyMobile,
  setRecentMobileVerification,
} from "../../functions/common";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import { MSGS_COMMON_FORM_ERROR } from "../../constants/messages";
import { MBCOLORS } from "../../constants/config";

export const ForgotPasswordForm = (props: MBProps) => {
  const [emailAddress, setEmailAddress] = useState("");

  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [verificationCoolDown, setVerificationCooldown] = useState(0);

  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (verificationCoolDown <= 0) {
      checkIfCanVerifyMobile((remainingTime) => {
        setVerificationCooldown(~~remainingTime);
      });
    }
  });

  const sendEmail = async (event: React.FormEvent) => {
    event.preventDefault();
    let error = "";
    if (_.isEmpty(emailAddress)) {
      error = MSGS_COMMON_FORM_ERROR.emailAddress;
      setEmailError(error);
    }
    if (!EmailValidator.validate(emailAddress)) {
      error = MSGS_COMMON_FORM_ERROR.invalidEmail;
      setEmailError(error);
    }

    if (!error) {
      setIsOpenSuccessDialog(true);
      try {
        await services.doSendResetPasswordEmail(emailAddress);
        setRecentMobileVerification();
        checkIfCanVerifyMobile((remainingTime) => {
          setVerificationCooldown(~~remainingTime);
        });
      } catch (error) {
        console.log("Error -- doSendResetPasswordEmail -- ", error);
      }
    }
  };
  return (
    <>
      <IonCard
        className="forgot-password-form-card ion-no-margin"
        color={MBCOLORS.tertiary}
      >
        <IonCardContent className="forgot-password-form-card-content ion-no-padding">
          <IonIcon className="forgot-password-form-card-logo" />
          <div className="forgot-password-form-details">
            <IonLabel className="mb-h2 bold">Reset Password</IonLabel>
            <IonLabel className="forgot-password-description mb-body">
              Please enter the email address you provided us upon registration.
              If the email address you provided is valid we will send an email
              containing a link where you can reset your password.
            </IonLabel>
            <form onSubmit={sendEmail}>
              <div className="forgot-password-form-input-container">
                <div className="forgot-password-form-email-input">
                  <MBInput
                    type="email"
                    label="Email"
                    value={emailAddress}
                    onChange={(emailAddress: string) => {
                      setEmailAddress(emailAddress);
                      setEmailError("");
                    }}
                    error={emailError}
                  />
                </div>

                <IonLabel className="forgot-password-form-forgot-resend-seconds mb-paragraph">
                  {`Didn’t recieve email? You can resend code in ${
                    !!verificationCoolDown
                      ? verificationCoolDown.toString()
                      : "60"
                  }s`}
                </IonLabel>
              </div>

              <IonButton
                disabled={!!verificationCoolDown}
                color={MBCOLORS.primary}
                className="forgot-password-form-send-button ion-text-capitalize"
                type="submit"
              >
                Send
              </IonButton>
            </form>
            <div className="forgot-password-form-support-container">
              <IonLabel className="forgot-password-form-support-label mb-h6 small light">
                Having problems? Email
              </IonLabel>
              <IonButton
                fill="clear"
                className="mb-forgot-password-form-email-support mb-h6 blue ion-text-lowercase ion-no-padding ion-no-margin"
                href="mailto:medbook@cortex.ph"
              >
                medbook@cortex.ph
              </IonButton>
            </div>
          </div>
        </IonCardContent>
      </IonCard>
      <MBDialog
        isOpen={isOpenSuccessDialog}
        icon="success"
        title="Email link sent"
        message="Please check your email for the reset password link."
        onDidDismiss={() => {
          setIsOpenSuccessDialog(false);
        }}
      />
    </>
  );
};
