export const ACCOUNT_TYPES = "accountTypes";
export const HOSPITAL_NETWORK = "hospitalNetwork";
export const HOSPITALS = "hospitals";
export const HOSPITAL_TIME_KEEPING = "hospitalTimeKeeping";
export const HOSPITAL_SERVICES = "hospitalServices";
export const HOSPITAL_SERVICES_SCHEDULE = "hospitalServicesSchedules";
export const HOSPITAL_SERVICE_APPOINTMENTS = "hospitalServiceAppointments";
export const PATIENTS = "patients";
export const FAMILY_MEMBERS = "familyMembers";
export const DOCTORS = "doctors";
export const DOCTOR_HOSPITALS = "doctorHospitals";
export const DOCTOR_SCHEDULE = "doctorSchedules";
export const HOSPITAL_DOCTOR_APPOINTMENTS = "hospitalDoctorAppointments";
export const EXECUTIVE_ASSISTANT = "executiveAssistant";
export const EXECUTIVE_ASSISTANT_DOCTOR_HOSPITAL =
  "executiveAssistantDoctorHospital";
export const HOSPITAL_REPRESENTATIVES = "hospitalRepresentatives";
export const HOSPITAL_REPRESENTATIVES_INVITATION = "hospitalRepInvitations";
export const SPECIALITIES = "specialities";
export const TRANSPORT = "transport";
export const MAIL = "mail";
export const SUPPORT_GROUP = "supportGroup";
export const SERVICES = "services";
export const BOOKING_FEE = "bookingFee";
export const BOOK_LIST = "bookList";
export const GCASH_PAYMENTS = "gCashPayments";
export const CREDIT_DEBIT_PAYMENTS = "creditDebitPayments";
