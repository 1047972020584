import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";
import { IonLoading, IonIcon, IonLabel, IonButton } from "@ionic/react";

import "./DoctorHome.scss";
import * as services from "../../services";
import * as routes from "../../constants/routes";
import { HospitalDoctorAppointmentsView } from "../../models";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBDashboardCard } from "../../components/MBDashboardCard/MBDashboardCard";
import { MBProps } from "../../interface";
import { MSGS_COMMON } from "../../constants/messages";
import { MBCOLORS, USER_TYPES } from "../../constants/config";
import { isSameDate, toDateTimeFromSecs } from "../../functions/common";

class DoctorHome extends React.Component<MBProps> {
  state = {
    loading: true,
    selected: 0,
    doctorAppointments: [] as HospitalDoctorAppointmentsView[],
  };
  //todo: get doctors appointments to check
  componentDidMount = () => {
    this.getDoctorsAppointments();
  };

  getDoctorsAppointments = async () => {
    const { authUser } = this.props;
    const { userType } = this.props.authUser;
    if (!!authUser) {
      try {
        let doctorIds = [];
        if (userType === USER_TYPES.executiveAssistant.id) {
          doctorIds = await services.getExecutiveAssistantDoctorIds(
            authUser.uid
          );
        } else {
          doctorIds.push(authUser.uid);
        }
        console.log("doctorIds -- ", doctorIds);
        await services.getAllDoctorAppointments(
          doctorIds,
          (doctorAppointments, error) => {
            console.log();
            if (!error) {
              this.setState({
                doctorAppointments,
                loading: false,
              });
            } else {
              console.log("error", error);
              this.setState({
                doctorAppointments: [],
                loading: false,
              });
            }
          }
        );
      } catch (error) {
        console.log("getDoctorAppointmens - error --", error);
      }
    }
  };

  render = () => {
    const { loading, doctorAppointments } = this.state;
    const { userType } = this.props.authUser;
    const isExecutiveAssistant = userType === USER_TYPES.executiveAssistant.id;
    return (
      <>
        <Helmet>
          <title>
            {userType === USER_TYPES.executiveAssistant.id
              ? "MedBook - Executive Assistant - Home"
              : "MedBook - Doctor - Home"}
          </title>
        </Helmet>
        <MBContainer
          {...this.props}
          activePage={
            userType === USER_TYPES.executiveAssistant.id
              ? "executive-assistant-home"
              : "doctor-home"
          }
        >
          {/* //todo: check if doctor has appointments */}
          {!_.isEmpty(doctorAppointments) && (
            <div className="doctor-home-dashboard-card-container">
              <MBDashboardCard
                icon="doctor"
                title={_.filter(
                  doctorAppointments,
                  (appointment) =>
                    !appointment.isCancelled &&
                    _.isEmpty(appointment.appointmentStatus) &&
                    isSameDate(new Date(), appointment.appointmentDate)
                ).length.toString()}
                message="Doctors Appointment Today"
              />
              <MBDashboardCard
                icon="booked"
                title={_.filter(
                  doctorAppointments,
                  (appointment) =>
                    !appointment.isCancelled &&
                    _.isEmpty(appointment.appointmentStatus) &&
                    !isSameDate(new Date(), appointment.appointmentDate)
                ).length.toString()}
                message="Booked Appointments"
              />
              <MBDashboardCard
                icon="cancelled"
                title={_.filter(
                  doctorAppointments,
                  (appointment) =>
                    appointment.isCancelled === true &&
                    isSameDate(new Date(), appointment.appointmentDate)
                ).length.toString()}
                message="Cancelled Appointment Today"
              />
            </div>
          )}
          <div className="doctor-home-dashboard">
            <IonIcon
              className={
                !isExecutiveAssistant
                  ? "doctor-home-icon"
                  : "exec-assistant-home-icon"
              }
            />
            {/* //todo: check if doctor has appointments */}
            <IonLabel className="doctor-home-title mb-h1">
              {!_.isEmpty(doctorAppointments)
                ? !isExecutiveAssistant
                  ? "Have a blessed Consultation Doc"
                  : "Have a blessed Appointment"
                : !isExecutiveAssistant
                ? "You don't have any appointments"
                : "Your doctor don’t have any appointments yet"}
            </IonLabel>
            <IonLabel className="mb-body">
              {!_.isEmpty(doctorAppointments)
                ? !isExecutiveAssistant
                  ? "Make sure to monitor your upcoming and current appointments"
                  : "Make sure to monitor your doctor’s upcoming and current appointments"
                : "Just wait for a while patients will soon discover your medical expertise"}
            </IonLabel>
          </div>
          <div className="doctor-home-button-container">
            {!_.isEmpty(doctorAppointments) ? (
              <IonButton
                className="doctor-home-goto-button mb-body bold"
                color={MBCOLORS.primary}
                mode="ios"
                routerLink={routes.DOCTOR_APPOINTMENTS}
                disabled={false}
              >
                Go to My Appointments
              </IonButton>
            ) : (
              <IonButton
                className="doctor-home-add-doctor-services mb-body bold"
                color={MBCOLORS.primary}
                mode="ios"
                disabled={false}
                routerLink={routes.DOCTOR_ACCOUNT}
              >
                Go to My Account
              </IonButton>
            )}
          </div>

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
        </MBContainer>
      </>
    );
  };
}

export default DoctorHome;
