import moment from "moment";
import _ from "lodash";
import React from "react";
import { calendarOutline, timeOutline } from "ionicons/icons";
import { IonLabel, IonCol, IonGrid, IonRow, IonIcon } from "@ionic/react";

import "./MBAppointmentDetail.scss";
import {
  HospitalDoctorAppointmentsView,
  HospitalServiceAppointmentsView,
} from "../../models";
import {
  getAppointmentStatus,
  toDateTimeFromSecs,
  getDayTimeDifference,
} from "../../functions/common";
import { MBCOLORS } from "../../constants/config";

interface MBAppointmentDetailProps {
  appointmentData:
    | HospitalDoctorAppointmentsView
    | HospitalServiceAppointmentsView;
  mode: "patient" | "doctor" | "hospital";
}

export const MBAppointmentDetail = (props: MBAppointmentDetailProps) => {
  const { appointmentData, mode } = props;
  const isDoctorAppointment =
    (appointmentData as HospitalDoctorAppointmentsView).doctorId !== undefined;

  return (
    <IonGrid className="mb-appointment-list-grid ion-no-margin ion-no-padding">
      <IonRow className="mb-appointment-list-row ion-no-argin ion-no-padding">
        <IonCol
          size="2"
          className="mb-appointment-list-icon-col ion-no-margin ion-no-padding"
        >
          <div className="mb-appointment-list-icon-container">
            <IonIcon
              className={`mb-appointment-list-card-icon ${
                mode === "doctor"
                  ? "patient"
                  : isDoctorAppointment
                  ? "doctor"
                  : "out-patient"
              }`}
            />
            <IonLabel
              className={`mb-appointment-list-status ${getAppointmentStatus(
                appointmentData.appointmentDate,
                appointmentData.isCancelled,
                appointmentData.appointmentStatus
              )}`}
            >
              {getAppointmentStatus(
                appointmentData.appointmentDate,
                appointmentData.isCancelled,
                appointmentData.appointmentStatus
              ).toUpperCase()}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol
          size="7.5"
          className="mb-appointment-list-details-col ion-no-margin ion-no-padding"
        >
          <IonLabel className="mb-appointment-list-patient-name mb-h2 dark-blue normal ion-no-padding ion-no-margin">
            {mode === "doctor" ? (
              <b>{`${appointmentData.patientDetails.firstName} ${appointmentData.patientDetails.lastName}`}</b>
            ) : isDoctorAppointment ? (
              <>
                <b>{`${
                  (appointmentData as HospitalDoctorAppointmentsView)
                    .doctorDetails.firstName
                } ${
                  (appointmentData as HospitalDoctorAppointmentsView)
                    .doctorDetails.lastName
                }`}</b>{" "}
                {`(${(appointmentData as HospitalDoctorAppointmentsView).doctorHospitalDetails.specialities
                  .map((speciality) => speciality.name)
                  .toString()})`}
              </>
            ) : mode === "patient" ? (
              <b>{`${
                (appointmentData as HospitalServiceAppointmentsView)
                  .hospitalServiceDetails.treatmentName
              }`}</b>
            ) : (
              <>
                <b>{`${appointmentData.patientDetails.firstName} ${appointmentData.patientDetails.lastName}`}</b>{" "}
                {`(${
                  (appointmentData as HospitalServiceAppointmentsView)
                    .hospitalServiceDetails.treatmentName
                })`}
              </>
            )}
          </IonLabel>
          <div className="mb-appointment-list-hospital-container">
            <IonLabel className="mb-appointment-list-hospital mb-body ion-no-padding ion-no-margin">
              {appointmentData.hospitalDetails.hospitalName}
            </IonLabel>
            <IonLabel className="mb-appointment-list-appointment-type mb-body light ion-no-padding ion-no-margin">
              {" "}
              • {isDoctorAppointment ? "Consultation" : "Out-Patient Service"}
            </IonLabel>
          </div>
          <div className="mb-appointment-list-schedule-container">
            <div className="mb-appointment-list-date-container">
              <IonIcon
                className="mb-appointment-list-calendar-icon"
                color={MBCOLORS.primary}
                icon={calendarOutline}
              />
              <IonLabel className="mb-appointment-list-date-label mb-body">
                {moment(appointmentData.appointmentDate).format(
                  "MMMM DD, YYYY"
                )}
              </IonLabel>
            </div>
            <div className="mb-appointment-list-time-container">
              <IonIcon color={MBCOLORS.primary} icon={timeOutline} />
              <IonLabel className="mb-appointment-list-time-label mb-body">
                Est. Time{" "}
                {moment(appointmentData.appointmentDate).format("HH:mm A")} -
                Slot {appointmentData.slotNumber}
              </IonLabel>
            </div>
          </div>
          <IonLabel className="mb-appointment-list-fee-label mb-h4 normal">
            <b>Php {appointmentData.resourceFee}</b> Consultation Fee
          </IonLabel>
        </IonCol>
        <IonCol
          size="2.5"
          className="mb-appointment-list-button-col ion-no-margin ion-no-padding"
        >
          <IonLabel className="mb-appointment-list-days-counter mb-paragraph light ion-no-padding ion-no-margin">
            {`${getDayTimeDifference(
              toDateTimeFromSecs(appointmentData.createdAt.seconds)
            )} ago`}
          </IonLabel>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
