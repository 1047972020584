/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonModal,
  IonButton,
} from "@ionic/react";

import "./FamilyMemberFormDialog.scss";
import * as services from "../../services";
import {
  dateYesterday,
  formatString,
  getServerTimestamp,
  isMobile,
} from "../../functions/common";
import { FamilyMember } from "../../models";
import { MBDateInput } from "../MBDateInput/MBDateInput";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBDivider } from "../MBDivider/MBDivider";
import { MBInput } from "../MBInput/MBInput";
import { MBProps } from "../../interface";
import {
  MBDropdownSelectOption,
  MBDropdownSelect,
} from "../MBDropdownSelect/MBDropdownSelect";
import { MBCOLORS } from "../../constants/config";
import { MSGS_COMMON } from "../../constants/messages";
import { MBMiniCalendar } from "../MBMiniCalendar/MBMiniCalendar";

interface FamilyMemberFormDialogProps extends MBProps {
  onCancel: () => void;
  editData?: FamilyMember;
  isOpen: boolean;
}

export const FamilyMemberFormDialog = (props: FamilyMemberFormDialogProps) => {
  const { isOpen, editData, onCancel } = props;

  const isEdit = !_.isEmpty(editData);

  const genderOptions = [
    { id: "Male", name: "Male" },
    { id: "Female", name: "Female" },
  ] as MBDropdownSelectOption[];

  const [firstName, setFirstName] = useState(isEdit ? editData!.firstName : "");
  const [lastName, setLastName] = useState(isEdit ? editData!.lastName : "");
  const [selectedGender, setSelectedGender] = useState(
    isEdit
      ? ({
          id: editData!.gender,
          name: editData!.gender,
        } as MBDropdownSelectOption)
      : ({
          id: "Male",
          name: "Male",
        } as MBDropdownSelectOption)
  );
  const [birthday, setBirthday] = useState(
    isEdit ? editData!.birthday.toDate() : (dateYesterday() as Date)
  );
  const [relationship, setRelationship] = useState(
    isEdit ? editData!.relationship : ""
  );
  const [calendarAnchor, setCalendarAnchor] = useState(
    null as Event | undefined | null
  );

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgBirthday, setErrorMsgBirthday] = useState("");
  const [errorMsgRelationship, setErrorMsgRelationship] = useState("");

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const onSubmit = async () => {
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedRelationship = formatString(relationship || "");

    let errorMessage = null;

    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_COMMON.requiredField;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_COMMON.requiredField;
      setErrorMsgLastName(errorMessage);
    }

    if (_.isNull(birthday)) {
      errorMessage = MSGS_COMMON.requiredField;
      setErrorMsgBirthday(errorMessage);
    }

    if (formattedRelationship.length === 0) {
      errorMessage = MSGS_COMMON.requiredField;
      setErrorMsgRelationship(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      try {
        setSaving(true);
        const { authUser } = props;

        if (!isEdit) {
          await services.createFamilyMember(
            authUser.uid,
            formattedRelationship,
            formattedFirstName,
            formattedLastName,
            selectedGender.id,
            birthday!
          );
          setSuccess("New Family Member has been successfully added");
        } else {
          await services.updateFamilyMember(editData!.docId || "", {
            relationship: formattedRelationship,
            firstName: formattedFirstName,
            lastName: formattedLastName,
            gender: selectedGender.id,
            birthday: getServerTimestamp(birthday!),
          });
          setSuccess("Family member details updated");
        }

        setSaving(false);
      } catch (e) {
        setError(e);
        setSaving(false);
      }
    } else {
      setSaving(false);
    }
  };

  return (
    <>
      <IonModal
        cssClass="family-member-form-modal"
        isOpen={isOpen}
        onDidDismiss={onCancel}
      >
        <IonLabel className="family-member-form-header mb-h2 dark-blue ion-text-start">
          {!isEdit ? "Add " : "Update "}Family Member
        </IonLabel>
        <MBDivider />
        <div className="family-member-form-container">
          <div
            className={`family-member-form-description-container ${
              isMobile() && "mobile"
            }`}
          ></div>
          <IonGrid className="family-member-form-container-grid ion-no-padding">
            <IonRow>
              <IonCol
                className={`family-member-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  label="First name"
                  value={firstName}
                  type="text"
                  error={errorMsgFirstName}
                  onChange={(firstName) => {
                    setFirstName(firstName);
                    setErrorMsgFirstName("");
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`family-member-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  label="Last name"
                  value={lastName}
                  type="text"
                  error={errorMsgLastName}
                  onChange={(lastName) => {
                    setLastName(lastName);
                    setErrorMsgLastName("");
                  }}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                className="family-member-form-container-col  last ion-no-padding"
                size="3"
              >
                <div className="family-member-form-select-container">
                  <MBDropdownSelect
                    label="Gender"
                    value={
                      !_.isEmpty(selectedGender) ? selectedGender.name : ""
                    }
                    onSelectItem={(selectedGender) => {
                      setSelectedGender(selectedGender);
                    }}
                    selectFromOptionsRequired={true}
                    options={genderOptions}
                    disableTab={true}
                  />
                </div>
              </IonCol>
              <IonCol
                className="family-member-form-container-col  last ion-no-padding"
                size="9"
              >
                <MBInput
                  className="family-member-form-birthday-input"
                  label="Birthday"
                  readonly={true}
                  value={moment(birthday).format("MM/DD/YYYY")}
                  type="text"
                  onClick={(event) => {
                    setCalendarAnchor(event.nativeEvent);
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                className={`family-member-form-container-col ${
                  isMobile() && "mobile"
                } ion-no-padding`}
                size="12"
              >
                <MBInput
                  label="Relationship"
                  value={relationship}
                  type="text"
                  error={errorMsgRelationship}
                  onChange={(relationship) => {
                    setRelationship(relationship);
                    setErrorMsgRelationship("");
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <MBDivider />
        <div className="family-member-form-setup-button-container">
          <IonButton
            onClick={onCancel}
            className="family-member-form-setup-back-button mb-body ion-text-capitalize"
            fill="clear"
            color={MBCOLORS.primary}
          >
            Cancel
          </IonButton>
          <IonButton
            onClick={() => {
              onSubmit();
            }}
            className="family-member-form-setup-save-button mb-body bold white ion-text-capitalize"
            color={MBCOLORS.primary}
          >
            Save
          </IonButton>
        </div>
      </IonModal>

      <MBDialog
        isOpen={!_.isEmpty(success)}
        icon="family-member"
        buttonLabel="Okay"
        title={success}
        message={
          !isEdit
            ? `You can now book any service for ${firstName} ${lastName}.`
            : "Details for family member is now updated."
        }
        onDidDismiss={() => {
          setSuccess("");
          onCancel();
        }}
      />

      {!_.isNull(calendarAnchor) && !_.isEmpty(calendarAnchor) && (
        <MBMiniCalendar
          calendarAnchor={calendarAnchor}
          selectedDate={birthday}
          removeCalendarAnchor={() => {
            setCalendarAnchor(null);
          }}
          onDateChange={(birthday: Date) => {
            setBirthday(birthday);
          }}
          maxDate={dateYesterday()}
        />
      )}

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
