import React, { useState, useEffect } from "react";
import {
  IonButtons,
  IonToast,
  IonInput,
  IonSlide,
  IonSlides,
  IonItem,
  IonLabel,
  IonRippleEffect,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonCard,
} from "@ionic/react";
import * as routes from "../../constants/routes";
import { TimelineLite, Power3, Power4, Bounce, Power2 } from "gsap";

import { logoFacebook } from "ionicons/icons";

import "./Tab3.scss";

const slideOpts = {
  ease: Bounce,

  initialSlide: 0,
  speed: 500,
  loop: false,
  duration: 2500,
  pager: false,
  autoplay: true,
  zoom: {
    maxRatio: 5,
  },
};
interface PreregistrationProps {
  onGoHome: () => void;
  onPreregister: () => void;
  onGoToPatient: () => void;
  onGoToDoctor: () => void;
  onGoToHospital: () => void;
}
const Tab3: React.FC<PreregistrationProps> = (props) => {
  let t1 = new TimelineLite({ delay: 0.1 });
  const {
    onGoHome,
    onPreregister,
    onGoToPatient,
    onGoToDoctor,
    onGoToHospital,
  } = props;

  useEffect(() => {
    t1.from(
      ".banneranim",
      { y: 15, opacity: 0, ease: Power3.easeInOut, delay: 0.8 },
      "Start"
    );

    t1.from(
      ".textanim",
      { y: 20, opacity: 0, ease: Power4.easeOut, delay: 0.4 },
      "Start"
    );
    t1.from(
      ".btnanim",
      { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".box",
      { x: 20, opacity: 0, ease: Power3.easeInOut, delay: 0.7 },
      "Start"
    );
    t1.from(
      ".headanim",
      { y: -100, opacity: 0, ease: Power2.easeInOut, delay: 0 },
      "Start"
    );
  }, []);

  const [showToast1, setShowToast1] = useState(false);
  return (
    <IonPage className="tab-container">
      <IonHeader className=" headanim">
        <IonToolbar className="ion-padding-top ion-padding-left">
          <IonTitle onClick={onGoHome}>
            <img
              className="logo-padding"
              src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}
            />
          </IonTitle>
          <IonButtons slot="primary" className="ion-margin-right ml-50">
            {/* <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-margin-right mr-10"
              // fill="outline"
              color="primary"
              routerLink={routes.LOGIN_OPTIONS}
            >
              Login
            </IonButton> */}
            <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-padding-end ion-margin-right mr-50"
              fill="outline"
              color="primary"
            >
              Follow us
              <IonIcon slot="start" icon={logoFacebook} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* <IonHeader>
      <IonToolbar className="ion-padding-top ion-padding-left">
     <a href="/tab3"></a><img  className="logo-padding"src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}/>
      </IonToolbar>
    </IonHeader> */}

      <IonContent>
        <IonSlides pager={true} options={slideOpts}>
          {/* LANDING SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center ">
            <IonRow className="bkg-blue ion-justify-content-center  textanim   ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text">
                  Open Your Doors to the New Normal
                </h1>
                <p>
                  Book your next consultation, checkup, lab test, and more
                  through Medbook!
                </p>
                <IonButton
                  color="warning"
                  className="btnanim ion-text-capitalize"
                  onClick={onPreregister}
                >
                  Get Started
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0 banneranim">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/landing-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* PATIENT SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center   ion-padding-top ">
              <IonCol className="ion-padding-top pt-20    textanim " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text">
                  Are you a Patient?
                </h1>
                <p>
                  Avoid the lines, reduce exposure, and track appointments
                  easily for you and your family.
                </p>
                <IonButton
                  className="landing-text-color ion-text-capitalize btnanim"
                  color="warning"
                  onClick={onGoToPatient}
                >
                  Learn More
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0 banneranim">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/patient-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* HOSPITAL SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  Hospital or Clinic
                </h1>
                <p>
                  We redefine the way you track appointments and schedules
                  through our innovative medical booking app.
                </p>
                <IonButton
                  className="landing-text-color ion-text-capitalize"
                  color="warning"
                  onClick={onGoToHospital}
                >
                  Learn More
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/hospital-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* DOCTOR SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  Are you a Doctor?
                </h1>
                <p>
                  Take control of your daily schedules with Medbook. Organize
                  appointments and walk-in consultations efficiently.
                </p>
                <IonButton
                  className="landing-text-color ion-text-capitalize"
                  color="warning"
                  onClick={onGoToDoctor}
                >
                  Learn More
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/doctor-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* COVID SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  #StaySafe & get the full healthcare experience
                </h1>
                <p className="word">
                  Medbook helps you #StaySafe while you get quality,
                  face-to-face consultations with your doctor.
                </p>
                <IonButton
                  className="ion-text-capitalize"
                  color="warning"
                  onClick={onPreregister}
                >
                  Get Started
                </IonButton>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/vaccine-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          {/* REGISTER SLIDE */}
          <IonSlide className="ion-padding ion-justify-content-center ion-text-start ion-align-content-center">
            <IonRow className="bkg-blue ion-justify-content-center  ion-padding-top ">
              <IonCol className="ion-padding-top pt-20  " size="4">
                <h1 className="ion-padding-top landing-dark-blue-text  ">
                  Pre Register Now!
                </h1>
                <p>
                  Get early access to Medbook and keep up-to-date with the
                  latest promos, offers, and news.
                </p>
                <form>
                  <IonItem>
                    <IonLabel position="floating">Your Email</IonLabel>
                    <IonInput
                      className="ion-padding-bottom"
                      type="email"
                      required
                    ></IonInput>
                  </IonItem>{" "}
                  <IonButton
                    color="warning"
                    onClick={() => setShowToast1(true)}
                    className="ion-margin-top ion-text-capitalize"
                    type="submit"
                  >
                    Get Started
                  </IonButton>
                </form>
              </IonCol>

              <IonCol size="6" className="ion-padding-top pb-0">
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/pre-register-slide.png`}
                ></img>
              </IonCol>
            </IonRow>
          </IonSlide>

          <IonToast
            isOpen={showToast1}
            onDidDismiss={() => setShowToast1(false)}
            message="You have been successfully subscribe to Medbook."
            duration={2000}
          />
        </IonSlides>

        {/* 3 CARDS  */}

        <IonRow className="abs box">
          <IonCard
            data-aos="fade-left"
            className="card-no-shadow thumbs teal ion-padding ion-text-center ion-justify-content-centerripple-parent bord-rad-0  ion-activatable ion-no-margin "
          >
            <a className="pointer" onClick={onGoToPatient}>
              {" "}
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/heartIcon.svg`}
              />
              <h6 className="white-text">For Patient </h6>
              <IonRippleEffect></IonRippleEffect>
            </a>
          </IonCard>

          <IonCard
            data-aos="fade-up"
            className="card-no-shadow thumbs landing-dark-blue ion-padding ion-text-center ion-justify-content-center ripple-parent  bord-rad-0  ion-activatable ion-no-margin"
          >
            <a data-aos="fade-up" className="pointer" onClick={onGoToHospital}>
              {" "}
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/hospitalIcon.svg`}
              />
              <h6 className="white-text">For Hospitals</h6>
            </a>
            <IonRippleEffect></IonRippleEffect>
          </IonCard>

          <IonCard className="card-no-shadow thumbs lime ion-padding ion-text-center ion-justify-content-center ripple-parent  bord-rad-0  ion-activatable ion-no-margin">
            <a className="pointer" onClick={onGoToDoctor}>
              {" "}
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/stetoscopeIcon.svg`}
              />
              <h4 className="white-text">For Doctors</h4>
            </a>
            <IonRippleEffect></IonRippleEffect>
          </IonCard>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
