//common routes
export const LANDING = "/";
export const EXTERNAL_ACCOUNT_ACTION = "/account-action";
export const FORGOT_PASSWORD = "/forgot-password";
export const FORGOT_PASSWORD_EMAIL_SENT = "/forgot-password-email-sent";
export const RECOVER_EMAIL = "/recover-email";
export const RESET_PASSWORD = "/reset-password";
export const RESET_SUCCESS = "/reset-success";
export const VERIFY_EMAIL = "/verify-email";

export const TERMS_CONDITION = "/terms-and-condition";
export const DATA_PRIVACY = "/data-privacy";

export const PAYMENT_PROCESSING_LANDING = "/payment-processing";
export const PAYMENT_PROCESSING_FAILED_LANDING =
  "/payment-failed/:gcashPayment";

export const HOME = "/home";
export const ACCOUNT_SETUP = "/account-setup";
export const CREATE_WITH_EMAIL_ACCOUNT_SETUP =
  "/create-with-email/account-setup";
export const CREATE_WITH_EMAIL_ACCOUNT_SETUP_WITH_INVITE =
  "/create-with-email/account-setup/:invitationId";
export const LOGIN_OPTIONS = "/login";
export const LOGIN_WITH_EMAIL = "/login-with-email";
export const FAQ = "/faq";
export const CONTACT_US = "/contact-us";
export const PATIENT_HOME = "/patient-home";
export const PATIENT_EDIT_BOOKLISTITEM = "/patient-home/edit/:bookListItemId";
export const PATIENT_CHECKOUT = "/checkout";
export const PATIENT_APPOINTMENTS = "/patient-appointments";
// export const PATIENT_TRANSPORT = "/patient-transport";
export const HOSPITAL_HOME = "/hospital/home";
export const HOSPITAL_APPOINTMENTS = "/hospital/appointments";
export const HOSPITAL_APPOINTMENT_DETAIL =
  "/hospital/appointments/:resourceType/:appointmentId";
export const HOSPITAL_SCAN = "/hospital/scan-code";
export const HOSPITAL_SEARCH_PATIENTS = "/hospital/search-patients";
export const HOSPITAL_APPLICATIONS = "/hospital/applications";
export const HOSPITAL_APPROVED_APPLICATIONS = "/hospital-applications/approved";
export const HOSPITAL_DEPARTMENT = "/hospital/department";
export const HOSPITAL_REP_ACCOUNT = "/hospital/my-account";
export const HOSPITAL_DEPARTMENT_DETAIL =
  "/hospital/department/:hospitalId/:type/:docId";
export const HOSPITAL_ACCOUNT_SETUP = "/hospital/account-setup";
export const DOCTOR_HOME = "/doctor/home";
export const DOCTOR_APPOINTMENTS = "/doctor/appointments";
export const DOCTOR_APPOINTMENT_DETAIL = "/doctor/appointments/:appointmentId";
export const DOCTOR_SCAN = "/doctor/scan-code";
export const DOCTOR_SEARCH_PATIENTS = "/doctor/search-patients";
export const DOCTOR_INVITATION = "/doctor/invitation/:doctorId";
export const DOCTOR_CREATE_PASSWORD = "/doctor/create-password";
export const DOCTOR_ACCOUNT = "/doctor-account";
export const EXECUTIVE_ASSISTANT_INVITATION =
  "/executive-assistant/invitation/:executiveAssistantId";
export const EXECUTIVE_ASSISTANT_CREATE_PASSWORD =
  "/executive-assistant/create-password";
export const EXECUTIVE_ASSISTANT_HOME = "/executive-assistant/home";
export const EXECUTIVE_ASSISTANT_APPOINTMENTS =
  "/executive-assistant/appointments";
export const EXECUTIVE_ASSISTANT_SCAN = "/executive-assistant/scan-code";
export const EXECUTIVE_ASSISTANT_DOCTOR_LIST =
  "/executive-assistant/doctors-list";
export const EXECUTIVE_ASSISTANT_ACCOUNT = "/executive-assistant-account";
export const PATIENT_ACCOUNT = "/patient-account";
export const TIME_KEEPING_SCAN = "/time-keeping/scan-code";
export const TIME_KEEPING_SEARCH_PATIENTS = "/time-keeping/search-patients";
export const TIME_KEEPING_ACCOUNT = "/time-keeping/account";
// export const TRANSPORT_ACCOUNT = "/transport-account";
export const RESEND_EMAIL_VERIFICATION = "/resend-email-verification";
export const ONGOING_HOSPITAL_VERIFICATION = "/ongoing-verification";

export const SUPPORT_GROUP_REGISTRATIONS = "/support-group-registrations";
export const SUPPORT_GROUP_APPROVED_REGISTRATONS =
  "/support-group-registrations/approved";
export const SUPPORT_GROUP_REGISTRATION = "/support-group-registration";
export const SUPPORT_GROUPS = "/support-groups";
export const TECH_ADMIN = "/tech-admin";
