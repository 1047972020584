import * as _ from "lodash";
import moment from "moment";

import { firestore } from "../firebase";
import { getDoctor } from "./doctors";
import { getDoctorSpecialitiesMap } from ".";
import { daysSinceDate, download } from "../functions/common";
import { getHospital, getService } from "./hospital";
import { CancerType } from "../interface";
import {
  PatientsDetails,
  Patient,
  Doctor,
  HospitalServiceAppointmentsOLD,
  DoctorsDetails,
  HospitalDoctorAppointmentsOLD,
  ServiceDetails,
  HospitalRepDetails,
  Hospital,
  HospitalDetails,
  SupportGroupDetails,
  SupportGroup,
  TransportDetails,
  Transport,
  HospitalDoctorDetails,
  DoctorHospitals,
  HospitalServiceDetails,
  HospitalService,
  DoctorScheduleDetails,
  DoctorSchedule,
  ServiceScheduleDetails,
  ServiceSchedule,
  HospitalDoctorAppointmentViewOLD,
  HospitalServiceAppointmentView,
  AllAppointmentsDetails,
} from "../models";
import {
  CANCER_TYPES,
  CANCER_TYPE_KEYS,
  DAYS_OF_WEEK,
  DAYS_OF_WEEK_KEYS,
  RESOURCE_TYPES,
  APPOINTMENT_TYPE_KEYS,
} from "../constants/config";
import {
  PATIENTS,
  DOCTORS,
  HOSPITAL_SERVICE_APPOINTMENTS,
  HOSPITAL_DOCTOR_APPOINTMENTS,
  HOSPITALS,
  SUPPORT_GROUP,
  TRANSPORT,
  DOCTOR_HOSPITALS,
  HOSPITAL_SERVICES,
  DOCTOR_SCHEDULE,
  HOSPITAL_SERVICES_SCHEDULE,
} from "../constants/dbCollections";

export const downloadAllPatientsDetails = async () => {
  const allPatients = await firestore.collection(PATIENTS).get();

  if (!allPatients.empty) {
    const formattedPatientDetails: (PatientsDetails | null)[] = await Promise.all(
      allPatients.docs.map((patient) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as PatientsDetails;
            const patientDetails = {
              ...patient.data(),
              docId: patient.id,
            } as Patient;
            row.ID = patient.id;
            row.Name = `${patientDetails.firstName} ${patientDetails.lastName}`;
            row["Email Address"] = patientDetails.emailAddress;
            row["Phone Number"] = patientDetails.phoneNumber;
            row.Province = patientDetails.province;
            row.City = patientDetails.cityMunicipality;

            if (!_.isEmpty(patientDetails.preferredDoctor)) {
              const doctor = await firestore
                .collection(DOCTORS)
                .doc(patientDetails.preferredDoctor)
                .get();
              if (doctor.exists) {
                const doctorDetails = doctor.data() as Doctor;
                row[
                  "Preferred Doctor"
                ] = `${doctorDetails.firstName} ${doctorDetails.lastName}`;
              }
            }

            row["Is Covid Free"] =
              !!patientDetails.lastCovidFree &&
              daysSinceDate(patientDetails.lastCovidFree.toDate()) <= 14
                ? "Yes"
                : "No";
            row["Machine Data"] = !_.isEmpty(patientDetails.machineDetails)
              ? JSON.stringify(patientDetails.machineDetails)
              : "";

            let totalAppointments = 0;
            let cancelledAppointments = 0;
            const patientHospitalServiceAppointments = await firestore
              .collection(HOSPITAL_SERVICE_APPOINTMENTS)
              .where("patientId", "==", patient.id)
              .get();
            if (!patientHospitalServiceAppointments.empty) {
              totalAppointments =
                totalAppointments +
                patientHospitalServiceAppointments.docs.length;

              cancelledAppointments =
                cancelledAppointments +
                _.filter(
                  patientHospitalServiceAppointments.docs,
                  (appointment) => {
                    const appointmentDetails = appointment.data() as HospitalServiceAppointmentsOLD;
                    return appointmentDetails.isCancelled;
                  }
                ).length;
            }

            const patientDoctorAppointments = await firestore
              .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
              .where("patientId", "==", patient.id)
              .get();
            if (!patientDoctorAppointments.empty) {
              totalAppointments =
                totalAppointments + patientDoctorAppointments.docs.length;

              cancelledAppointments =
                cancelledAppointments +
                _.filter(patientDoctorAppointments.docs, (appointment) => {
                  const appointmentDetails = appointment.data() as HospitalDoctorAppointmentsOLD;
                  return appointmentDetails.isCancelled;
                }).length;
            }

            row["Total Appointments"] = totalAppointments.toString();
            row["Cancelled Appointments"] = cancelledAppointments.toString();
            row["Member Since"] = moment(
              patientDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING PATIENT DETAILS", e);
            resolve(null);
          }
        }) as Promise<PatientsDetails | null>;
      })
    );

    const finalPatientDetails = _.sortBy(
      _.compact(formattedPatientDetails),
      "Name"
    );

    download(
      finalPatientDetails,
      `ALL-PATIENTS-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllDoctorDetails = async () => {
  const allDoctors = await firestore.collection(DOCTORS).get();

  if (!allDoctors.empty) {
    const specialityMap = await getDoctorSpecialitiesMap(
      allDoctors.docs.map((doctor) => doctor.id)
    );
    const formattedDoctorDetails: (DoctorsDetails | null)[] = await Promise.all(
      allDoctors.docs.map((doctor) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as DoctorsDetails;
            const doctorDetails = {
              ...doctor.data(),
              docId: doctor.id,
            } as Doctor;
            row.ID = doctor.id;
            row["Physician Number"] = doctorDetails.physicianIdNumber;
            row.Name = `${doctorDetails.firstName} ${doctorDetails.lastName}`;
            row.Speciality = !!specialityMap[doctor.id]
              ? specialityMap[doctor.id].join(", ")
              : "";
            row["Email Address"] = doctorDetails.emailAddress;
            row["Phone Number"] = doctorDetails.phoneNumber;
            row["Machine Data"] = !_.isEmpty(doctorDetails.machineDetails)
              ? JSON.stringify(doctorDetails.machineDetails)
              : "";

            let totalAppointments = 0;
            let cancelledAppointments = 0;
            const doctorAppointments = await firestore
              .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
              .where("doctorId", "==", doctor.id)
              .get();
            if (!doctorAppointments.empty) {
              totalAppointments = doctorAppointments.docs.length;

              cancelledAppointments = _.filter(
                doctorAppointments.docs,
                (appointment) => {
                  const appointmentDetails = appointment.data() as HospitalDoctorAppointmentsOLD;
                  return appointmentDetails.isCancelled;
                }
              ).length;
            }
            row["Total Appointments"] = totalAppointments.toString();
            row["Cancelled Appointments"] = cancelledAppointments.toString();
            row["Member Since"] = moment(
              doctorDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING DOCTOR DETAILS", e);
            resolve(null);
          }
        }) as Promise<DoctorsDetails | null>;
      })
    );

    const finalDoctorDetails = _.sortBy(
      _.compact(formattedDoctorDetails),
      "Name"
    );

    download(
      finalDoctorDetails,
      `ALL-DOCTORS-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllServices = async () => {
  const allServices = await firestore.collection(HOSPITAL_SERVICES).get();
  if (!allServices.empty) {
    const formattedServicesDetails = await Promise.all(
      allServices.docs.map((service) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as ServiceDetails;
            const serviceDetails = service.data() as HospitalService;

            row.ID = service.id;
            row.Service = serviceDetails.treatmentName;
            let totalAppointments = 0;
            let cancelledAppointments = 0;
            const serviceAppointments = await firestore
              .collection(HOSPITAL_SERVICE_APPOINTMENTS)
              .where("service", "==", service.id)
              .get();

            if (!serviceAppointments.empty) {
              totalAppointments =
                totalAppointments + serviceAppointments.docs.length;

              cancelledAppointments =
                cancelledAppointments +
                _.filter(serviceAppointments.docs, (appointment) => {
                  const appointmentDetails = appointment.data() as HospitalServiceAppointmentsOLD;
                  return appointmentDetails.isCancelled;
                }).length;
            }

            row["Total Appointments"] = totalAppointments.toString();
            row["Cancelled Appointments"] = cancelledAppointments.toString();
            row["Included Since"] = moment(
              serviceDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR GETTING SERVICE DETAILS", e);
            resolve(null);
          }
        });
      })
    );

    const finalServiceDetails = _.sortBy(
      _.compact(formattedServicesDetails),
      "Service"
    );

    download(
      finalServiceDetails,
      `ALL-SERVICES-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllHospitalRepDetails = async () => {
  const allHospitalReps = await firestore.collection(HOSPITALS).get();

  if (!allHospitalReps.empty) {
    const formattedHospitalRepDetails: (HospitalRepDetails | null)[] = await Promise.all(
      allHospitalReps.docs.map((hospitalRep) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as HospitalRepDetails;
            const hospitalRepDetails = {
              ...hospitalRep.data(),
              docId: hospitalRep.id,
            } as Hospital;
            if (_.isEmpty(hospitalRepDetails.oldHospitalAccount)) {
              row.ID = hospitalRep.id;
              row.Name = `${hospitalRepDetails.firstName} ${hospitalRepDetails.lastName}`;
              row["Email Address"] = hospitalRepDetails.emailAddress;
              row["Phone Number"] = hospitalRepDetails.phoneNumber;

              const hospital = await firestore
                .collection(HOSPITALS)
                .where("hospitalRepId", "==", hospitalRep.id)
                .get();

              if (!hospital.empty) {
                const hospitalData = hospital.docs[0].data() as Hospital;
                row.Hospital = hospitalData.hospitalName;
                row.Department = hospitalData.department;
              } else {
                row.Hospital = "";
                row.Department = "";
              }

              row.Verified = hospitalRepDetails.isVerified ? "Yes" : "No";
              row.Rejected = hospitalRepDetails.isRejected ? "Yes" : "No";
              row["Government ID"] = hospitalRepDetails.governmentId;
              row["Personal ID"] = hospitalRepDetails.identificationCard;
              row["Machine Data"] = !_.isEmpty(
                hospitalRepDetails.machineDetails
              )
                ? JSON.stringify(hospitalRepDetails.machineDetails)
                : "";
              row["Date Registered"] = moment(
                hospitalRepDetails.createdAt.toDate()
              ).format("MM/DD/YYYY");

              resolve(row);
            } else {
              resolve(null);
            }
          } catch (e) {
            console.log("ERROR WHILE GETTING HOSPITAL REP DETAILS", e);
            resolve(null);
          }
        }) as Promise<HospitalRepDetails | null>;
      })
    );

    const finalHospitalRepDetails = _.orderBy(
      _.compact(formattedHospitalRepDetails),
      ["Name", "Hospital", "Department"]
    );

    download(
      finalHospitalRepDetails,
      `ALL-HOSPITAL-REP-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllHospitalDetails = async () => {
  const allHospitals = await firestore.collection(HOSPITALS).get();

  if (!allHospitals.empty) {
    const formattedHospitalDetails: (HospitalDetails | null)[] = await Promise.all(
      allHospitals.docs.map((hospital) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as HospitalDetails;
            const hospitalDetails = {
              ...hospital.data(),
              docId: hospital.id,
            } as Hospital;
            row.ID = hospital.id;
            row.Name = hospitalDetails.hospitalName;
            row.Department = hospitalDetails.department;

            if (hospitalDetails.oldHospitalAccount === undefined) {
            } else {
              row["Hospital Representative"] = "Deleted";
            }

            row.Address = `${hospitalDetails.province} ${hospitalDetails.cityMunicipality}`;
            row.Location = JSON.stringify(hospitalDetails.location);
            row.Verified = hospitalDetails.isVerified ? "Yes" : "No";
            row.Rejected = hospitalDetails.isRejected ? "Yes" : "No";

            row["Date Registered"] = moment(
              hospitalDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING HOSPITAL DETAILS", e);
            resolve(null);
          }
        }) as Promise<HospitalDetails | null>;
      })
    );

    const finalHospitalRepDetails = _.orderBy(
      _.compact(formattedHospitalDetails),
      ["Hospital", "Department"]
    );

    download(
      finalHospitalRepDetails,
      `ALL-HOSPITALS-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllSupportGroupDetails = async () => {
  const allSupportGroups = await firestore.collection(SUPPORT_GROUP).get();

  if (!allSupportGroups.empty) {
    const formattedSupportGroupDetails: (SupportGroupDetails | null)[] = await Promise.all(
      allSupportGroups.docs.map((supportGroup) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as SupportGroupDetails;
            const supportGroupDetails = {
              ...supportGroup.data(),
              docId: supportGroup.id,
            } as SupportGroup;
            row.ID = supportGroup.id;
            row.Name = supportGroupDetails.groupName;
            row["Email Address"] = supportGroupDetails.emailAddress;
            row["Phone Number"] = supportGroupDetails.phoneNumber;
            row.Description = supportGroupDetails.description;
            row.Logo = supportGroupDetails.logo;
            row.Verified = supportGroupDetails.isVerified ? "Yes" : "No";
            row.Rejected = supportGroupDetails.isRejected ? "Yes" : "No";
            row["Machine Data"] = !_.isEmpty(supportGroupDetails.machineDetails)
              ? JSON.stringify(supportGroupDetails.machineDetails)
              : "";
            row["Date Registered"] = moment(
              supportGroupDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING SUPPORT GROUP DETAILS", e);
            resolve(null);
          }
        }) as Promise<SupportGroupDetails | null>;
      })
    );

    const finalSupportGroupDetails = _.sortBy(
      _.compact(formattedSupportGroupDetails),
      "Name"
    );

    download(
      finalSupportGroupDetails,
      `ALL-SUPPORT-G-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllTransportDetails = async () => {
  const allTransport = await firestore.collection(TRANSPORT).get();

  if (!allTransport.empty) {
    const formattedTransportDetails: (TransportDetails | null)[] = await Promise.all(
      allTransport.docs.map((transport) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as TransportDetails;
            const transportDetails = {
              ...transport.data(),
              docId: transport.id,
            } as Transport;
            row.ID = transport.id;
            row.Name = `${transportDetails.firstName} ${transportDetails.lastName}`;
            row["Email Address"] = transportDetails.emailAddress;
            row["Phone Number"] = transportDetails.phoneNumber;
            row.Address = transportDetails.address;
            row["Car Model / Plate Number"] =
              transportDetails.carModelAndPlateNo;
            row[
              "Max Accommodation"
            ] = transportDetails.maxAccomodation.toString();
            row["Last Confirmed Covid-19 Negative"] = moment(
              transportDetails.lastConfirmedCovid19Negative.toDate()
            ).format("MM/DD/YYYY");
            row["Baranggay Representative"] = transportDetails.isBaranggayRep
              ? "Yes"
              : "No";
            row["Machine Data"] = !_.isEmpty(transportDetails.machineDetails)
              ? JSON.stringify(transportDetails.machineDetails)
              : "";
            row["Member Since"] = moment(
              transportDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING TRANSPORT DETAILS", e);
            resolve(null);
          }
        }) as Promise<TransportDetails | null>;
      })
    );

    const finalTransportDetails = _.sortBy(
      _.compact(formattedTransportDetails),
      "Name"
    );

    download(
      finalTransportDetails,
      `ALL-TRANSPORT-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllHospitalDoctorDetails = async () => {
  const allDoctorHospitals = await firestore.collection(DOCTOR_HOSPITALS).get();

  if (!allDoctorHospitals.empty) {
    const formattedDoctorHospitalDetails: (HospitalDoctorDetails | null)[] = await Promise.all(
      allDoctorHospitals.docs.map((doctorHospital) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as HospitalDoctorDetails;
            const doctorHospitalDetails = {
              ...doctorHospital.data(),
              docId: doctorHospital.id,
            } as DoctorHospitals;
            row.ID = doctorHospital.id;
            const doctor = await getDoctor(doctorHospitalDetails.doctorId);
            if (!_.isEmpty(doctor)) {
              row["Doctor ID"] = doctor.docId || "";
              row.Doctor = `${doctor.firstName} ${doctor.lastName}`;
            } else {
              row["Doctor ID"] = "Deleted";
              row.Doctor = "Deleted";
            }

            const hospital = await getHospital(
              doctorHospitalDetails.hospitalId
            );
            if (!_.isEmpty(hospital)) {
              row.Hospital = hospital.hospitalName;
              row.Department = hospital.department;
            } else {
              row.Hospital = "Deleted";
              row.Department = "Deleted";
            }

            let totalAppointments = 0;
            let cancelledAppointments = 0;

            const doctorHospitalAppointments = await firestore
              .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
              .where("doctorId", "==", doctorHospitalDetails.doctorId)
              .where("hospitalId", "==", doctorHospitalDetails.hospitalId)
              .get();
            if (!doctorHospitalAppointments.empty) {
              totalAppointments =
                totalAppointments + doctorHospitalAppointments.docs.length;

              cancelledAppointments =
                cancelledAppointments +
                _.filter(doctorHospitalAppointments.docs, (appointment) => {
                  const appointmentDetails = appointment.data() as HospitalDoctorAppointmentsOLD;
                  return appointmentDetails.isCancelled;
                }).length;
            }

            row["Total Appointments"] = totalAppointments.toString();
            row["Cancelled Appointments"] = cancelledAppointments.toString();

            row["Date Registered"] = moment(
              doctorHospitalDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING DOCTOR HOSPITAL DETAILS", e);
            resolve(null);
          }
        }) as Promise<HospitalDoctorDetails | null>;
      })
    );

    const finalHospitalDoctorDetails = _.orderBy(
      _.compact(formattedDoctorHospitalDetails),
      ["Doctor", "Hospital", "Department"]
    );

    download(
      finalHospitalDoctorDetails,
      `ALL-DOC-HOSPITAL-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllHospitalServiceDetails = async () => {
  const allHospitalServices = await firestore
    .collection(HOSPITAL_SERVICES)
    .get();

  if (!allHospitalServices.empty) {
    const formattedHospitalServiceDetails: (HospitalServiceDetails | null)[] = await Promise.all(
      allHospitalServices.docs.map((hospitalService) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as HospitalServiceDetails;
            const hospitalServiceDetails = {
              ...hospitalService.data(),
              docId: hospitalService.id,
            } as HospitalService;
            row.ID = hospitalService.id;
            row.Service = hospitalServiceDetails.treatmentName;
            row["Archived Since"] = !_.isEmpty(
              hospitalServiceDetails.archiveDate
            )
              ? moment(hospitalServiceDetails.archiveDate!.toDate()).format(
                  "MM/DD/YYYY"
                )
              : "";

            const hospital = await getHospital(
              hospitalServiceDetails.hospitalId
            );
            if (!_.isEmpty(hospital)) {
              row.Hospital = hospital.hospitalName;
              row.Department = hospital.department;
            } else {
              row.Hospital = "Deleted";
              row.Department = "Deleted";
            }

            let totalAppointments = 0;
            let cancelledAppointments = 0;
            const serviceAppointments = await firestore
              .collection(HOSPITAL_SERVICE_APPOINTMENTS)
              .where("service", "==", hospitalServiceDetails.docId || "")
              .where("hospitalId", "==", hospitalServiceDetails.hospitalId)
              .get();

            if (!serviceAppointments.empty) {
              totalAppointments =
                totalAppointments + serviceAppointments.docs.length;

              cancelledAppointments =
                cancelledAppointments +
                _.filter(serviceAppointments.docs, (appointment) => {
                  const appointmentDetails = appointment.data() as HospitalServiceAppointmentsOLD;
                  return appointmentDetails.isCancelled;
                }).length;
            }

            row["Total Appointments"] = totalAppointments.toString();
            row["Cancelled Appointments"] = cancelledAppointments.toString();
            row["Date Registered"] = moment(
              hospitalServiceDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING SERVICE HOSPITAL DETAILS", e);
            resolve(null);
          }
        }) as Promise<HospitalServiceDetails | null>;
      })
    );

    const finalHospitalServiceDetails = _.orderBy(
      _.compact(formattedHospitalServiceDetails),
      ["Service", "Hospital", "Department"]
    );

    download(
      finalHospitalServiceDetails,
      `ALL-SERV-HOSPITALS-${moment(new Date())
        .format("MMM D, YYYY")
        .toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllDoctorSchedules = async () => {
  const allDoctorSchedules = await firestore.collection(DOCTOR_SCHEDULE).get();

  if (!allDoctorSchedules.empty) {
    const formattedDoctorSchedule: (DoctorScheduleDetails | null)[] = await Promise.all(
      allDoctorSchedules.docs.map((doctorSchedule) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as DoctorScheduleDetails;
            const doctorScheduleDetails = {
              ...doctorSchedule.data(),
              docId: doctorSchedule.id,
            } as DoctorSchedule;
            row.ID = doctorSchedule.id;
            const doctorHospital = await firestore
              .collection(DOCTOR_HOSPITALS)
              .doc(doctorScheduleDetails.doctorHospitalId)
              .get();

            if (doctorHospital.exists) {
              const doctorHospitalDetail = doctorHospital.data() as DoctorHospitals;
              const doctor = await getDoctor(doctorHospitalDetail.doctorId);
              if (!_.isEmpty(doctor)) {
                row["Doctor ID"] = doctor.docId || "";
                row.Doctor = `${doctor.firstName} ${doctor.lastName}`;
              } else {
                row.Doctor = "Deleted";
                row["Doctor ID"] = "Deleted";
              }

              const hospital = await getHospital(
                doctorHospitalDetail.hospitalId
              );
              if (!_.isEmpty(hospital)) {
                row.Hospital = hospital.hospitalName;
                row.Department = hospital.department;
              } else {
                row.Hospital = "Deleted";
                row.Department = "Deleted";
              }
            } else {
              row.Doctor = "Deleted";
              row.Hospital = "Deleted";
              row.Department = "Deleted";
            }

            row["Day Of The Week"] =
              DAYS_OF_WEEK[
                DAYS_OF_WEEK_KEYS[doctorScheduleDetails.dayOfWeek]
              ].name;

            row.Period = "";
            // PERIOD_TYPES[PERIOD_TYPE_KEYS[doctorScheduleDetails.period]].name;

            let totalAppointments = 0;
            let cancelledAppointments = 0;
            const serviceAppointments = await firestore
              .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
              .where(
                "doctorScheduleId",
                "==",
                doctorScheduleDetails.docId || ""
              )
              .get();

            if (!serviceAppointments.empty) {
              totalAppointments =
                totalAppointments + serviceAppointments.docs.length;

              cancelledAppointments =
                cancelledAppointments +
                _.filter(serviceAppointments.docs, (appointment) => {
                  const appointmentDetails = appointment.data() as HospitalServiceAppointmentsOLD;
                  return appointmentDetails.isCancelled;
                }).length;
            }

            row["Total Appointments"] = totalAppointments.toString();
            row["Cancelled Appointments"] = cancelledAppointments.toString();
            row["Date Registered"] = moment(
              doctorScheduleDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING DOCTOR SCHEDULE DETAILS", e);
            resolve(null);
          }
        }) as Promise<DoctorScheduleDetails | null>;
      })
    );

    const finalDoctorSchedules = _.orderBy(_.compact(formattedDoctorSchedule), [
      "Doctor",
      "Hospital",
      "Department",
      "Day Of Week",
    ]);

    download(
      finalDoctorSchedules,
      `ALL-SCHED-DOCTORS-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllServiceSchedules = async () => {
  const allServiceSchedules = await firestore
    .collection(HOSPITAL_SERVICES_SCHEDULE)
    .get();

  if (!allServiceSchedules.empty) {
    const formattedServiceSchedule: (ServiceScheduleDetails | null)[] = await Promise.all(
      allServiceSchedules.docs.map((serviceSchedule) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as ServiceScheduleDetails;
            const serviceScheduleDetails = {
              ...serviceSchedule.data(),
              docId: serviceSchedule.id,
            } as ServiceSchedule;
            row.ID = serviceSchedule.id;
            const serviceHospital = await firestore
              .collection(HOSPITAL_SERVICES)
              .doc(serviceScheduleDetails.hospitalServiceId)
              .get();

            if (serviceHospital.exists) {
              const serviceHospitalDetail = serviceHospital.data() as HospitalService;
              row.Service = serviceHospitalDetail.treatmentName;

              const hospital = await getHospital(
                serviceHospitalDetail.hospitalId
              );
              if (!_.isEmpty(hospital)) {
                row.Hospital = hospital.hospitalName;
                row.Department = hospital.department;
              } else {
                row.Hospital = "Deleted";
                row.Department = "Deleted";
              }
            } else {
              row.Service = "Deleted";
              row.Hospital = "Deleted";
              row.Department = "Deleted";
            }

            row["Day Of The Week"] =
              DAYS_OF_WEEK[
                DAYS_OF_WEEK_KEYS[serviceScheduleDetails.dayOfWeek]
              ].name;

            let totalAppointments = 0;
            let cancelledAppointments = 0;
            const serviceAppointments = await firestore
              .collection(HOSPITAL_SERVICE_APPOINTMENTS)
              .where(
                "serviceScheduleId",
                "==",
                serviceScheduleDetails.docId || ""
              )
              .get();

            if (!serviceAppointments.empty) {
              totalAppointments =
                totalAppointments + serviceAppointments.docs.length;

              cancelledAppointments =
                cancelledAppointments +
                _.filter(serviceAppointments.docs, (appointment) => {
                  const appointmentDetails = appointment.data() as HospitalServiceAppointmentsOLD;
                  return appointmentDetails.isCancelled;
                }).length;
            }

            row["Total Appointments"] = totalAppointments.toString();
            row["Cancelled Appointments"] = cancelledAppointments.toString();
            row["Date Registered"] = moment(
              serviceScheduleDetails.createdAt.toDate()
            ).format("MM/DD/YYYY");

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING SERVICE SCHEDULE DETAILS", e);
            resolve(null);
          }
        }) as Promise<ServiceScheduleDetails | null>;
      })
    );

    const finalServiceSchedules = _.orderBy(
      _.compact(formattedServiceSchedule),
      ["Doctor", "Hospital", "Department", "Day Of Week"]
    );

    download(
      finalServiceSchedules,
      `ALL-SCHED-SERVICES-${moment(new Date())
        .format("MMM D, YYYY")
        .toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const downloadAllAppointments = async () => {
  const allAppointments = await getAllHospitalAppointmentsOnce();

  if (!_.isEmpty(allAppointments)) {
    const formattedAllAppointments: (AllAppointmentsDetails | null)[] = await Promise.all(
      allAppointments.map((appointment) => {
        return new Promise(async (resolve) => {
          try {
            const row = {} as AllAppointmentsDetails;
            row.ID = appointment.appointmentId;
            row["Patient ID"] = appointment.patientDetails.docId || "";
            row.Patient = `${appointment.patientDetails.firstName} ${appointment.patientDetails.lastName}`;
            row.Contact = `${appointment.patientDetails.phoneNumber} / ${appointment.patientDetails.emailAddress}`;
            row.Appointment =
              RESOURCE_TYPES[
                APPOINTMENT_TYPE_KEYS[appointment.appointmentType]
              ].name;
            const hospitalDetail = await getHospital(appointment.hospitalId);
            if (!_.isEmpty(hospitalDetail)) {
              row.Hospital = hospitalDetail.hospitalName;
              row.Department = hospitalDetail.department;
            } else {
              row.Hospital = "Deleted";
              row.Department = "Deleted";
            }
            row.Date = moment(appointment.appointmentDate)
              .format("MMM D, YYYY")
              .toString();
            row["Booked From"] = !_.isEmpty(appointment.bookedFrom)
              ? JSON.stringify(appointment.bookedFrom)
              : "";

            row["Date Booked"] = moment(appointment.bookingDate).format(
              "MM/DD/YYYY"
            );
            if (
              appointment.appointmentType === RESOURCE_TYPES.consultation.id
            ) {
              const doctorAppointment = appointment as HospitalDoctorAppointmentViewOLD;
              row.Service = "N/A";
              row["Doctor ID"] = doctorAppointment.doctorDetails.docId || "";
              row.Doctor = `${doctorAppointment.doctorDetails.firstName} ${doctorAppointment.doctorDetails.lastName}`;
            } else {
              const serviceAppointment = appointment as HospitalServiceAppointmentView;
              const service = await getService(serviceAppointment.service);

              if (!_.isEmpty(service)) {
                row.Service = service.treatmentName;
              } else {
                row.Service = "";
              }
              row["Doctor ID"] = "N/A";
              row.Doctor = "N/A";
            }
            row["Cancer Type"] =
              CANCER_TYPES[CANCER_TYPE_KEYS[appointment.cancerType]].name;
            row.Status = appointment.isCancelled ? "Cancelled" : "Active";

            resolve(row);
          } catch (e) {
            console.log("ERROR WHILE GETTING SERVICE SCHEDULE DETAILS", e);
            resolve(null);
          }
        }) as Promise<AllAppointmentsDetails | null>;
      })
    );

    const finalAllAppointments = _.sortBy(
      _.compact(formattedAllAppointments),
      "Patient"
    );

    download(
      finalAllAppointments,
      `ALL-APPOINTMENTS-${moment(new Date()).format("MMM D, YYYY").toString()}`
    );

    return true;
  } else {
    return false;
  }
};

export const getAllHospitalAppointmentsOnce = async () => {
  const appointmentList: (
    | HospitalDoctorAppointmentViewOLD
    | HospitalServiceAppointmentView
  )[] = [];

  const doctorsAppointments = await firestore
    .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
    .get();

  if (!doctorsAppointments.empty) {
    const doctorAppointmentsRawData = doctorsAppointments.docs.map(
      (appointment) => {
        return {
          ...appointment.data(),
          docId: appointment.id,
        } as HospitalDoctorAppointmentsOLD;
      }
    );
    let doctorsDataMap: {
      [doctorId: string]: Doctor;
    } = {};
    let patientsDataMap: {
      [patientId: string]: Patient;
    } = {};

    // populate doctors data map
    await Promise.all(
      _.uniqBy(doctorAppointmentsRawData, "doctorId").map(
        (doctorAppointment) => {
          return new Promise(async (resolve) => {
            const doctor = await firestore
              .collection(DOCTORS)
              .doc(doctorAppointment.doctorId)
              .get();
            if (doctor.exists) {
              doctorsDataMap[doctor.id] = doctor.data() as Doctor;
            }
            resolve();
          });
        }
      )
    );

    // populate patients data map
    await Promise.all(
      _.uniqBy(doctorAppointmentsRawData, "patientId").map(
        (doctorAppointment) => {
          return new Promise(async (resolve) => {
            const patient = await firestore
              .collection(PATIENTS)
              .doc(doctorAppointment.patientId)
              .get();
            if (patient.exists) {
              patientsDataMap[patient.id] = patient.data() as Patient;
            }
            resolve();
          });
        }
      )
    );

    const doctorsAppointmentsFormatted = doctorAppointmentsRawData.map(
      (appointment) => {
        const slotNo = _.orderBy(
          _.filter(
            doctorAppointmentsRawData,
            (nonCancelledAppointments) =>
              nonCancelledAppointments.isCancelled === false &&
              appointment.doctorScheduleId ===
                nonCancelledAppointments.doctorScheduleId
          ),
          "createdAt"
        )
          .map((appointment) => appointment.docId)
          .indexOf(appointment.docId);

        if (
          _.has(doctorsDataMap, appointment.doctorId) &&
          _.has(patientsDataMap, appointment.patientId)
        ) {
          return {
            hospitalId: appointment.hospitalId,
            bookedFrom: appointment.bookedFrom,
            bookingDate: appointment.createdAt.toDate(),
            appointmentType: RESOURCE_TYPES.consultation.id,
            appointmentId: appointment.docId,
            scheduleId: appointment.doctorScheduleId,
            doctorId: appointment.doctorId,
            doctorDetails: doctorsDataMap[appointment.doctorId],
            patientId: appointment.patientId,
            patientDetails: patientsDataMap[appointment.patientId],
            period: appointment.period,
            cancerType: appointment.cancerType,
            appointmentDate: appointment.appointmentDate.toDate(),
            slotNo: slotNo + 1,
            isCancelled: appointment.isCancelled,
          } as HospitalDoctorAppointmentViewOLD;
        } else {
          return null;
        }
      }
    );

    const compactDoctorAppointment = _.compact(doctorsAppointmentsFormatted);
    if (!_.isEmpty(compactDoctorAppointment)) {
      appointmentList.push(...compactDoctorAppointment);
    }
  }

  const serviceAppointments = await firestore
    .collection(HOSPITAL_SERVICE_APPOINTMENTS)
    .get();

  if (!serviceAppointments.empty) {
    const serviceAppointmentsRawData = serviceAppointments.docs.map(
      (appointment) => {
        return {
          ...appointment.data(),
          docId: appointment.id,
        } as HospitalServiceAppointmentsOLD;
      }
    );

    let patientsDataMap: {
      [patientId: string]: Patient;
    } = {};

    // populate patients data map
    await Promise.all(
      _.uniqBy(serviceAppointmentsRawData, "patientId").map(
        (serviceAppointment) => {
          return new Promise(async (resolve) => {
            const patient = await firestore
              .collection(PATIENTS)
              .doc(serviceAppointment.patientId)
              .get();
            if (patient.exists) {
              patientsDataMap[patient.id] = patient.data() as Patient;
            }
            resolve();
          });
        }
      )
    );

    const servicesAppointmentsFormatted = serviceAppointmentsRawData.map(
      (appointment) => {
        const slotNo = _.orderBy(
          _.filter(
            serviceAppointmentsRawData,
            (nonCancelledAppointments) =>
              nonCancelledAppointments.isCancelled === false
          ),
          "createdAt"
        )
          .map((appointment) => appointment.docId)
          .indexOf(appointment.docId);

        if (_.has(patientsDataMap, appointment.patientId)) {
          return {
            hospitalId: appointment.hospitalId,
            bookedFrom: appointment.bookedFrom,
            bookingDate: appointment.createdAt.toDate(),
            appointmentType: RESOURCE_TYPES.services.id,
            appointmentId: appointment.docId,
            scheduleId: appointment.serviceScheduleId,
            service: appointment.service,
            patientId: appointment.patientId,
            patientDetails: patientsDataMap[appointment.patientId],
            cancerType: appointment.cancerType,
            appointmentDate: appointment.appointmentDate.toDate(),
            slotNo: slotNo + 1,
            isCancelled: appointment.isCancelled,
          } as HospitalServiceAppointmentView;
        } else {
          return null;
        }
      }
    );

    const compactServiceAppointment = _.compact(servicesAppointmentsFormatted);
    if (!_.isEmpty(compactServiceAppointment)) {
      appointmentList.push(...compactServiceAppointment);
    }
  }

  return _.orderBy(appointmentList, "appointmentDate", "asc");
};

export const getPatientCount = async (callback: (count: number) => void) => {
  try {
    firestore.collection(PATIENTS).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(snapshot.docs.length);
      } else {
        callback(0);
      }
    });
  } catch (e) {
    console.log("ERROR GETTING PATIENT COUNT", e);
    callback(0);
  }
};

export const getHospitalCount = async (callback: (count: number) => void) => {
  try {
    firestore.collection(HOSPITALS).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(snapshot.docs.length);
      } else {
        callback(0);
      }
    });
  } catch (e) {
    console.log("ERROR GETTING HOSPITAL COUNT", e);
    callback(0);
  }
};

export const getDoctorCount = async (callback: (count: number) => void) => {
  try {
    firestore.collection(DOCTORS).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(snapshot.docs.length);
      } else {
        callback(0);
      }
    });
  } catch (e) {
    console.log("ERROR GETTING DOCTOR COUNT", e);
    callback(0);
  }
};

export const getServicesCount = async (callback: (count: number) => void) => {
  try {
    firestore.collection(HOSPITAL_SERVICES).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(snapshot.docs.length);
      } else {
        callback(0);
      }
    });
  } catch (e) {
    console.log("ERROR GETTING SERVICES COUNT", e);
    callback(0);
  }
};

export const getAppointmentCount = async (
  callback: (count: number) => void
) => {
  try {
    let serviceAppointmentCount = 0;
    let consultationAppointmentCount = 0;
    firestore
      .collection(HOSPITAL_DOCTOR_APPOINTMENTS)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          consultationAppointmentCount = snapshot.docs.length;
          console.log(
            "COUNT consultationAppointmentCount",
            consultationAppointmentCount
          );
          callback(consultationAppointmentCount + serviceAppointmentCount);
        } else {
          callback(0 + serviceAppointmentCount);
        }
      });

    firestore
      .collection(HOSPITAL_SERVICE_APPOINTMENTS)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          serviceAppointmentCount = snapshot.docs.length;
          console.log("COUNT serviceAppointmentCount", serviceAppointmentCount);
          callback(serviceAppointmentCount + consultationAppointmentCount);
        } else {
          callback(0 + consultationAppointmentCount);
        }
      });
  } catch (e) {
    console.log("ERROR GETTING SERVICES COUNT", e);
    callback(0);
  }
};
