/* eslint-disable jsx-a11y/anchor-is-valid */
import * as _ from "lodash";
import moment from "moment";
import { arrowForwardOutline } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import {
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonCard,
  IonCardHeader,
  IonItem,
  IonInput,
  IonButton,
  IonCheckbox,
  IonToggle,
  IonDatetime,
  IonIcon,
} from "@ionic/react";

import "./DoctorAccountForm.scss";
import * as api from "../../api";
import * as email from "../../functions/email";
import * as sms from "../../functions/sms";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { analytics } from "../../firebase";
import { validatePhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  isMobile,
  daysSinceDate,
  getServerTimestamp,
} from "../../functions/common";
import { MBCalendarRangeDialog } from "../MBCalendarRangeDialog/MBCalendarRangeDialog";
import { MBDialog } from "../MBDialog/MBDialog";
import { MBInput } from "../MBInput/MBInput";
import { MBPasswordReauthenticationDialog } from "../MBPasswordReauthenticationDialog/MBPasswordReauthenticationDialog";
import {
  MBDropdownSelectOption,
  MBDropdownSelect,
} from "../MBDropdownSelect/MBDropdownSelect";
import { MBProps, DaysOfWeek } from "../../interface";
import {
  Doctor,
  DoctorSchedule,
  DoctorHospitals,
  Speciality,
  ExecutiveAssistantDetails,
  Hospital,
} from "../../models";
import {
  LIMITS,
  MBCOLORS,
  DAYS_OF_WEEK,
  ANALYTICS_CONTENT_TYPES,
} from "../../constants/config";
import {
  MSGS_COMMON,
  MSGS_SIGNUP,
  MSGS_COMMON_FORM_ERROR,
  MSGS_DOCTOR,
} from "../../constants/messages";

export interface DoctorScheduleOptions extends Partial<DoctorSchedule> {
  isDayChecked: boolean;
  name: string;
}
interface DoctorAccountFormProps extends MBProps {
  isOpenEditAccount: boolean;
  inititalSelectedDoctorHospital: MBDropdownSelectOption;
  initialDoctorHospitalOptions: MBDropdownSelectOption[];
  onBack: () => void;
}
export const DoctorAccountForm = (props: DoctorAccountFormProps) => {
  const {
    authUser,
    isOpenEditAccount,
    inititalSelectedDoctorHospital,
    initialDoctorHospitalOptions,
    onBack,
  } = props;

  const doctor = authUser.userDetails as Doctor;
  const isOutOfOffice =
    doctor.outOfOfficeStartDate !== undefined &&
    !_.isNull(doctor.outOfOfficeStartDate) &&
    ((doctor.outOfOfficeEndDate !== undefined &&
      !_.isNull(doctor.outOfOfficeEndDate) &&
      daysSinceDate(doctor.outOfOfficeEndDate!.toDate()) <= 0) ||
      _.isEmpty(doctor.outOfOfficeEndDate));

  const [outOfOffice, setOutOfOffice] = useState(
    !!doctor ? isOutOfOffice : false
  );

  const [physicianIdNumber, setPhysicianIdNumber] = useState(
    !!doctor && !!doctor.physicianIdNumber ? doctor.physicianIdNumber : ""
  );

  const [firstName, setFirstName] = useState(
    !!doctor && doctor.firstName ? doctor.firstName : ""
  );
  const [lastName, setLastName] = useState(
    !!doctor && doctor.lastName ? doctor.lastName : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    !!doctor && doctor.phoneNumber ? doctor.phoneNumber : ""
  );
  const [emailAddress, setEmailAddress] = useState(
    !!doctor && doctor.emailAddress ? doctor.emailAddress : ""
  );

  const [selectedSpecialities, setSelectedSpecialites] = useState(
    [] as Speciality[]
  );

  const [
    selectedOutOfOfficeStartDate,
    setSelectedOutOfOfficeStartDate,
  ] = useState(null as Date | null);
  const [selectedOutOfOfficeEndDate, setSelectedOutOfOfficeEndDate] = useState(
    null as Date | null
  );

  const [selectedDoctorHospital, setSelectedDoctorHospital] = useState(
    inititalSelectedDoctorHospital as MBDropdownSelectOption
  );

  const [doctorHospitalOptions, setDoctorHospitalOptions] = useState(
    initialDoctorHospitalOptions as MBDropdownSelectOption[]
  );

  const [executiveAssistants, setExecutiveAssistants] = useState(
    null as ExecutiveAssistantDetails[] | null
  );

  const [doctorHospitalSchedule, setDoctorHospitalSchedule] = useState(
    [] as DoctorScheduleOptions[]
  );

  const [hasUpdatedSchedule, setHasUpdatedSchedule] = useState(false);
  const [withLunchBreak, setWithLunchBreak] = useState(false);
  const [startBreakTime, setStartBreakTime] = useState(
    new Date(2020, 1, 1, 12, 0, 0, 0)
  );
  const [endBreakTime, setEndBreakTime] = useState(
    new Date(2020, 1, 1, 13, 0, 0, 0)
  );

  const [estimatedAppointmentMinuteLengthOptions] = useState([
    { id: "15", name: "15 Minutes" },
    { id: "30", name: "30 Minutes" },
    { id: "45", name: "45 Minutes" },
    { id: "60", name: "60 Minutes" },
  ] as MBDropdownSelectOption[]);

  const [
    selectedEstimatedAppointmentMinuteLength,
    setSelectedEstimatedAppointmentMinuteLength,
  ] = useState({ id: "15", name: "15 Minutes" } as MBDropdownSelectOption);

  const [isEdit, setIsEdit] = useState(isOpenEditAccount);

  const [isScheduleMode, setScheduleMode] = useState(false);

  const [errorMsgPhysicianIdNumber, setErrorMsgPhysicianIdNumber] = useState(
    ""
  );
  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
  const [errorMsgEmaillAddress, setErrorMsgEmaillAddress] = useState("");

  const [isOpenSuccessProfileUpdate, setIsOpenSuccessProfileUpdate] = useState(
    false
  );
  const [isOpenSendChangePassword, setIsOpenSendChangePassword] = useState(
    false
  );

  const [
    isDeleteProfileConfirmationDialogOpen,
    setIsDeleteProfileConfirmationDialogOpen,
  ] = useState(false);

  const [
    isConfirmOutOfOfficeDialogOpen,
    setIsConfirmOutOfOfficeDialogOpen,
  ] = useState(false);

  const [
    isConfirmCancelOutOfOfficeDialogOpen,
    setIsConfirmCancelOutOfOfficeDialogOpen,
  ] = useState(false);

  const [isReauthenticateDialogOpen, setIsReauthenticateDialogOpen] = useState(
    false
  );

  const [isCalendarRangeDialogOpen, setIsCalendarRangeDialogOpen] = useState(
    false
  );

  const [
    isSuccessOutOfOfficeDialogOpen,
    setIsSuccessOutOfOfficeDialogOpen,
  ] = useState(false);

  const [isAddExecutiveDialogOpen, setIsAddExecutiveDialogOpen] = useState(
    false
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!_.isEmpty(authUser)) {
      setEmailAddress(authUser.authEmail);
      getDoctorHospitals();

      const isOutOfOffice =
        doctor.outOfOfficeStartDate !== undefined &&
        !_.isNull(doctor.outOfOfficeStartDate) &&
        ((doctor.outOfOfficeEndDate !== undefined &&
          !_.isNull(doctor.outOfOfficeEndDate) &&
          daysSinceDate(doctor.outOfOfficeEndDate!.toDate()) <= 0) ||
          _.isEmpty(doctor.outOfOfficeEndDate));
      setOutOfOffice(isOutOfOffice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteProfile = async () => {
    try {
      setLoading(true);
      await api.deleteUser(authUser.authEmail);
      await services.signOut();
      analytics.logEvent("delete_doctor_account", {
        content_type: ANALYTICS_CONTENT_TYPES.doctorAccount.type,
        hospital: selectedDoctorHospital.name,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const getDoctorHospitals = async () => {
    try {
      selectDoctorHospital(inititalSelectedDoctorHospital!);
      getDoctorHospitalExecutiveAssistant(
        initialDoctorHospitalOptions.map((doctorHospital) => doctorHospital.id)
      );
      if (!inititalSelectedDoctorHospital.metadata.initialSetupDone) {
        setIsEdit(true);
      }
    } catch (e) {
      setError(e);
      setDoctorHospitalOptions([]);
    }
  };

  const getDoctorHospitalExecutiveAssistant = (doctorHospitalIds: string[]) => {
    services.getDoctorHospitalExecutiveAssistant(
      doctorHospitalIds,
      (executiveAssistants, error) => {
        if (_.isEmpty(error)) {
          setExecutiveAssistants(executiveAssistants);
        } else {
          setExecutiveAssistants([]);
          setError(error!);
        }
      }
    );
  };

  const selectDoctorHospital = (option: MBDropdownSelectOption) => {
    const doctorHospital = option.metadata.data as DoctorHospitals;
    console.log("SELECTING DOCTOR HOSPITAL", option);
    if (!_.isEmpty(doctorHospital.startBreakTime)) {
      setStartBreakTime(doctorHospital.startBreakTime!.toDate());
      setEndBreakTime(doctorHospital.endBreakTime!.toDate());
    }
    if (!_.isEmpty(doctorHospital.startBreakTime)) {
      setWithLunchBreak(true);
    }

    setSelectedEstimatedAppointmentMinuteLength(
      estimatedAppointmentMinuteLengthOptions.find((estimateOption) => {
        return (
          estimateOption.id ===
          (option.metadata
            .schedules[0] as DoctorSchedule).estimatedAppointmentMinuteLength.toString()
        );
      })!
    );

    setSelectedSpecialites(doctorHospital.specialities);
    setDoctorHospitalSchedule([]);
    const doctorSchedule = Object.values(DAYS_OF_WEEK).map((day) => {
      const existingSchedule = _.find(
        option.metadata.schedules as DoctorSchedule[],
        (doctorSchedule) => doctorSchedule.dayOfWeek === day.day
      );

      if (!_.isEmpty(existingSchedule)) {
        return {
          ...existingSchedule,
          name: day.name,
          isDayChecked: true,
        } as DoctorScheduleOptions;
      } else {
        const dateToday = new Date();
        const startTime = _.cloneDeep(dateToday);
        startTime.setHours(6);
        startTime.setMinutes(0);
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);

        const endTime = _.clone(dateToday);
        endTime.setHours(18);
        endTime.setMinutes(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);
        return {
          dayOfWeek: day.day,
          name: day.name,
          isDayChecked: false,
          slots: 0,
          startTime: getServerTimestamp(startTime),
          endTime: getServerTimestamp(endTime),
        } as DoctorScheduleOptions;
      }
    });
    console.log("WILL NOW EDIT THIS", doctorSchedule);
    setTimeout(() => {
      setDoctorHospitalSchedule(doctorSchedule);
    }, 100);
  };

  const onSubmit = async () => {
    setLoading(true);
    const formattedPhysicianIdNumber = formatString(physicianIdNumber || "");
    const formattedFirstName = formatString(firstName || "");
    const formattedLastName = formatString(lastName || "");
    const formattedPhoneNumber = formatString((phoneNumber || "") as string);
    const emptySchedule = _.isEmpty(
      _.compact(
        doctorHospitalSchedule.map(
          (doctorHospitalScheduleItem: DoctorScheduleOptions) =>
            doctorHospitalScheduleItem.isDayChecked &&
            doctorHospitalScheduleItem.slots !== 0
        )
      )
    );

    const noSlotChecked = !_.isEmpty(
      _.filter(
        doctorHospitalSchedule,
        (item) => item.isDayChecked && item.slots === 0
      )
    );

    let errorMessage = null;
    if (!isMobile() || (isMobile() && !isScheduleMode)) {
      if (formattedPhysicianIdNumber.length === 0) {
        errorMessage = MSGS_DOCTOR.physicianId;
        setErrorMsgPhysicianIdNumber(errorMessage);
      }
      if (formattedFirstName.length === 0) {
        errorMessage = MSGS_SIGNUP.firstName.required;
        setErrorMsgFirstName(errorMessage);
      }
      if (formattedFirstName.length > LIMITS.name.single) {
        errorMessage = MSGS_SIGNUP.firstName.max;
        setErrorMsgFirstName(errorMessage);
      }
      if (formattedLastName.length === 0) {
        errorMessage = MSGS_SIGNUP.lastName.required;
        setErrorMsgLastName(errorMessage);
      }
      if (formattedLastName.length > LIMITS.name.single) {
        errorMessage = MSGS_SIGNUP.lastName.max;
        setErrorMsgLastName(errorMessage);
      }
      if (formattedPhoneNumber.length === 0) {
        errorMessage = MSGS_SIGNUP.mobileNumber;
        setErrorMsgPhoneNumber(errorMessage);
      }
      if (!validatePhoneNumber(formattedPhoneNumber)) {
        errorMessage = MSGS_SIGNUP.invalidMobileNumber;
        setErrorMsgPhoneNumber(errorMessage);
      }
    }

    if (!isMobile() || (isMobile() && isScheduleMode)) {
      if (emptySchedule || noSlotChecked) {
        errorMessage = MSGS_DOCTOR.allotedSlots;
        setError(errorMessage);
      }
    }

    if (_.isEmpty(errorMessage)) {
      const doctorDetail = _.cloneDeep(doctor);
      const updatedDoctorDetail = {
        ...doctorDetail,
        firstName: formattedFirstName,
        lastName: formattedLastName,
        phoneNumber: formattedPhoneNumber,
        physicianIdNumber: formattedPhysicianIdNumber,
      } as Partial<Doctor>;
      try {
        const doctorHospitalId = selectedDoctorHospital.id;
        if (!isMobile() || (isMobile() && !isScheduleMode)) {
          await services.updateDoctorDetail(
            doctor.docId || "",
            updatedDoctorDetail
          );
          const updatedDoctorHospital = {
            specialities: selectedSpecialities,
            initialSetupDone: true,
            ...(withLunchBreak
              ? {
                  startBreakTime,
                  endBreakTime,
                }
              : {
                  startBreakTime: null,
                  endBreakTime: null,
                }),
          } as Partial<DoctorHospitals>;

          await services.updateDoctorHospitalDetail(
            doctorHospitalId,
            updatedDoctorHospital
          );
        }

        //UPDATE SCHEDULE
        await Promise.all(
          doctorHospitalSchedule.map((schedule) => {
            //create
            if (schedule.docId === undefined && schedule.isDayChecked) {
              return services.createDoctorSchedule(
                selectedDoctorHospital.id,
                schedule.dayOfWeek as DaysOfWeek,
                schedule.slots!,
                +selectedEstimatedAppointmentMinuteLength.id,
                schedule.startTime!.toDate(),
                schedule.endTime!.toDate()
              );
            }
            //update
            else if (schedule.docId !== undefined && schedule.isDayChecked) {
              return services.updateDoctorSchedule(
                schedule.docId || "",
                schedule.slots!,
                +selectedEstimatedAppointmentMinuteLength.id,
                schedule.startTime!.toDate(),
                schedule.endTime!.toDate()
              );
            }
            //delete
            else if (schedule.docId !== undefined && !schedule.isDayChecked) {
              return services.removeDoctorSchedule(schedule.docId || "");
            }
          })
        );

        const executiveAssistant = _.find(
          executiveAssistants,
          (assistant) =>
            assistant.executiveAssistantDoctorHospitalDetails
              .doctorHospitalId === doctorHospitalId
        );

        if (hasUpdatedSchedule && !!executiveAssistant) {
          try {
            const emailAssistant = email.updateDoctorAvailabilitySecretary(
              `${executiveAssistant.executiveAssistantDetails.firstName} ${executiveAssistant.executiveAssistantDetails.lastName}`,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`
            );
            await services.sendEmail(
              executiveAssistant.executiveAssistantDetails.emailAddress,
              emailAssistant.subject,
              emailAssistant.message
            );
            const smsAssistant = sms.updateDoctorAvailabilitySecretary(
              `${executiveAssistant.executiveAssistantDetails.firstName} ${executiveAssistant.executiveAssistantDetails.lastName}`,
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`
            );

            await api.sendSMS(
              executiveAssistant.executiveAssistantDetails.phoneNumber,
              smsAssistant
            );
          } catch (error) {
            console.log("error -- sendingNotif to Secretary -- ", error);
          }
        }

        if (
          doctor.physicianIdNumber !== formattedPhysicianIdNumber ||
          doctor.firstName !== formattedFirstName ||
          doctor.lastName !== formattedLastName ||
          doctor.phoneNumber !== formattedPhoneNumber ||
          hasUpdatedSchedule
        ) {
          try {
            const hospitalRep = await services.getHospitalRep(
              selectedDoctorHospital.metadata.hospitalRepId
            );
            const { subject, message } = email.updateDoctorAvailabilityByDoctor(
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              `${hospitalRep.firstName} ${hospitalRep.lastName}`
            );

            await services.sendEmail(
              hospitalRep.emailAddress,
              subject,
              message
            );

            const smsMessage = sms.updateDoctorAvailabilityByDoctor(
              `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
              `${hospitalRep.firstName} ${hospitalRep.lastName}`
            );

            await api.sendSMS(hospitalRep.phoneNumber, smsMessage);
          } catch (e) {
            console.log("ERROR SENDING NOTIFS", e);
            setError(e);
          }
        }

        setIsEdit(false);
        setLoading(false);
        setIsOpenSuccessProfileUpdate(true);
        analytics.logEvent("update_doctor_account", {
          content_type: ANALYTICS_CONTENT_TYPES.doctorAccount.type,
          hospital: selectedDoctorHospital.name,
        });
        analytics.logEvent("update_doctor_speciality", {
          content_type: ANALYTICS_CONTENT_TYPES.doctorAccount.type,
          hospital: selectedDoctorHospital.name,
        });
        analytics.logEvent("update_doctor_schedule", {
          content_type: ANALYTICS_CONTENT_TYPES.doctorAccount.type,
          hospital: selectedDoctorHospital.name,
        });
      } catch (error) {
        console.log("error - updateDoctorAccount -- ", error);
        setError(error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const onChangePassword = async () => {
    let error = "";
    if (_.isEmpty(emailAddress)) {
      error = MSGS_COMMON_FORM_ERROR.emailAddress;
      setErrorMsgEmaillAddress(error);
    }

    if (!error) {
      setIsOpenSendChangePassword(true);
      try {
        await services.doSendResetPasswordEmail(emailAddress);
        analytics.logEvent("edit_doctor_password", {
          content_type: ANALYTICS_CONTENT_TYPES.doctorAccount.type,
          event_value: outOfOffice,
        });
      } catch (error) {
        console.log("Error -- doSendResetPasswordEmail -- ", error);
      }
    }
  };

  const updateOutOfOfficeState = async (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    try {
      setLoading(true);
      const outOfOffice =
        !_.isNull(startDate) &&
        ((!_.isNull(endDate) && daysSinceDate(endDate!) <= 0) ||
          _.isNull(endDate));

      const doctorDetails = _.cloneDeep(authUser.userDetails as Doctor);

      await services.updateDoctorVacationMode(authUser.uid, startDate, endDate);
      setOutOfOffice(outOfOffice);
      if (outOfOffice) {
        if (!_.isNull(doctorHospitalOptions)) {
          try {
            await Promise.all(
              doctorHospitalOptions.map((doctorHospital) => {
                return new Promise(async (resolve) => {
                  const hospitalRep = doctorHospital.metadata
                    .hospital as Hospital;

                  const executiveAssistant = _.find(
                    executiveAssistants,
                    (assistant) =>
                      assistant.executiveAssistantDoctorHospitalDetails
                        .doctorHospitalId === doctorHospital.id
                  );

                  if (!!executiveAssistant) {
                    const emailAssistant = email.doctorOutOfOfficeSecretary(
                      `${executiveAssistant.executiveAssistantDetails.firstName} ${executiveAssistant.executiveAssistantDetails.lastName}`,
                      `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                      moment(startDate).format("MM/DD/YYYY").toString(),
                      !_.isNull(endDate)
                        ? moment(endDate).format("MM/DD/YYYY").toString()
                        : ""
                    );
                    //SEND SMS to Executive Assistant
                    const smsAssistant = sms.doctorOutOfOfficeSecretary(
                      `${executiveAssistant.executiveAssistantDetails.firstName} ${executiveAssistant.executiveAssistantDetails.lastName}`,
                      `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                      moment(startDate).format("MM/DD/YYYY").toString(),
                      !_.isNull(endDate)
                        ? moment(endDate).format("MM/DD/YYYY").toString()
                        : ""
                    );

                    await api.sendSMS(
                      executiveAssistant.executiveAssistantDetails.phoneNumber,
                      smsAssistant
                    );
                    await services.sendEmail(
                      executiveAssistant.executiveAssistantDetails.emailAddress,
                      emailAssistant.subject,
                      emailAssistant.message
                    );
                  }

                  const {
                    subject,
                    message,
                  } = email.doctorOutOfOfficeHospitalRep(
                    `${hospitalRep.firstName} ${hospitalRep.lastName} - ${doctorHospital.name}`,
                    `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`,
                    moment(startDate).format("MM/DD/YYYY").toString(),
                    !_.isNull(endDate)
                      ? moment(endDate).format("MM/DD/YYYY").toString()
                      : ""
                  );

                  await services.sendEmail(
                    hospitalRep.emailAddress,
                    subject,
                    message
                  );

                  resolve();
                });
              })
            );

            await api.doctorOutOfOfficeNotifyPatientsAndHospitals(
              authUser.uid,
              moment(startDate).toISOString(),
              !_.isNull(endDate) ? moment(endDate).toISOString() : ""
            );
          } catch (e) {
            console.log("ERROR SENDING NOTIF: ", e);
          }

          setIsSuccessOutOfOfficeDialogOpen(true);
          setLoading(false);
        } else {
          setLoading(false);
          setError("NO HOSPITAL AVAILABLE");
        }
      } else {
        //NOTIFY PATIENTS DOCTORS BACK
        if (!_.isNull(doctorHospitalOptions)) {
          try {
            await Promise.all(
              doctorHospitalOptions.map((doctorHospital) => {
                return new Promise(async (resolve) => {
                  const hospitalRep = await services.getHospitalRep(
                    doctorHospital.metadata.hospitalRepId
                  );

                  const executiveAssistant = _.find(
                    executiveAssistants,
                    (assistant) =>
                      assistant.executiveAssistantDoctorHospitalDetails
                        .doctorHospitalId === doctorHospital.id
                  );

                  if (!!executiveAssistant) {
                    const emailAssistant = email.doctorInOfOfficeSecretary(
                      `${executiveAssistant.executiveAssistantDetails.firstName} ${executiveAssistant.executiveAssistantDetails.lastName}`,
                      `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`
                    );
                    await services.sendEmail(
                      executiveAssistant.executiveAssistantDetails.emailAddress,
                      emailAssistant.subject,
                      emailAssistant.message
                    );
                  }

                  const {
                    subject,
                    message,
                  } = email.doctorInOfOfficeHospitalRep(
                    `${hospitalRep.firstName} ${hospitalRep.lastName} - ${doctorHospital.name}`,
                    `${authUser.userDetails.firstName} ${authUser.userDetails.lastName}`
                  );

                  await services.sendEmail(
                    hospitalRep.emailAddress,
                    subject,
                    message
                  );

                  resolve();
                });
              })
            );

            await api.doctorInOfOfficeNotifyPatientsAndHospitals(
              authUser.uid,
              doctorDetails.outOfOfficeStartDate!.toDate().valueOf(),
              !_.isNull(doctorDetails.outOfOfficeEndDate)
                ? doctorDetails.outOfOfficeEndDate!.toDate().valueOf()
                : ""
            );
          } catch (e) {
            console.log("ERROR SENDING NOTIF: ", e);
          }
          setLoading(false);
          props.history.push(routes.DOCTOR_APPOINTMENTS);
        } else {
          setLoading(false);
          setError("NO HOSPITAL AVAILABLE");
        }
      }
      analytics.logEvent("out_of_office", {
        content_type: ANALYTICS_CONTENT_TYPES.doctorAccount.type,
        event_value: outOfOffice,
      });
    } catch (e) {
      setOutOfOffice(!outOfOffice);
      setLoading(false);
      setError(e);
    }
  };

  return (
    <>
      <IonCard
        className={`doctor-account-card ${
          isMobile() ? "mobile" : ""
        } ion-no-margin`}
        color={MBCOLORS.tertiary}
      >
        <IonCardHeader className="doctor-account-card-header">
          <div
            className={`doctor-account-card-header-container ${
              isMobile() ? "mobile" : ""
            }`}
          >
            <IonLabel className="mb-h2 ion-text-start">
              {!_.isEmpty(selectedDoctorHospital) &&
              !selectedDoctorHospital.metadata.initialSetupDone
                ? "Setup Doctor’s Account"
                : isEdit
                ? "Edit Information"
                : "User Information"}
            </IonLabel>
            {/* {isEdit && (
              <IonLabel className="mb-body ion-text-start">
                Please provide information needed.{" "}
                <b>This will only affect future schedules.</b>
              </IonLabel>
            )} */}

            {!_.isNull(doctorHospitalOptions) && (
              <IonLabel className="doctor-account-vacation-mode-label mb-h4 ion-text-start">
                Out-Of-Office
                <IonToggle
                  checked={outOfOffice}
                  mode="ios"
                  onIonChange={(event) => {
                    const outOfOfficeChecked = event.detail.checked;
                    setOutOfOffice(outOfOfficeChecked);

                    if (outOfOfficeChecked && !isOutOfOffice && !loading) {
                      setIsConfirmOutOfOfficeDialogOpen(true);
                    } else if (
                      !outOfOfficeChecked &&
                      isOutOfOffice &&
                      !loading
                    ) {
                      setIsConfirmCancelOutOfOfficeDialogOpen(true);
                    }
                    // updateVacationMode(vacationMode);
                  }}
                />
              </IonLabel>
            )}
          </div>
        </IonCardHeader>
        <div className="doctor-account-form-card-container">
          <form
            autoComplete="nope..."
            className={`doctor-account-form-user-details ${
              isMobile()
                ? `mobile ${isScheduleMode ? "schedule-mode" : ""}`
                : ""
            }`}
          >
            <div className="doctor-account-form-container">
              <div className="doctor-account-form-description-container"></div>
              <IonGrid className="doctor-account-form-container-grid ion-no-padding">
                <IonRow>
                  <IonCol
                    className="doctor-account-form-container-col last ion-no-padding"
                    size="12"
                  >
                    <MBInput
                      label="Physician ID number"
                      value={physicianIdNumber.replace(/^0+/, "")}
                      type="text"
                      error={errorMsgPhysicianIdNumber}
                      onChange={(physicianIdNumber) => {
                        setPhysicianIdNumber(
                          physicianIdNumber.replace(/^0+/, "")
                        );
                        setErrorMsgPhysicianIdNumber("");
                      }}
                      readonly={!isEdit}
                    />
                  </IonCol>
                </IonRow>
                {_.chunk(
                  [
                    <MBInput
                      label="First name"
                      value={firstName}
                      type="text"
                      error={errorMsgFirstName}
                      onChange={(firstName) => {
                        setFirstName(firstName);
                        setErrorMsgFirstName("");
                      }}
                      readonly={!isEdit}
                    />,
                    <MBInput
                      label="Last name"
                      value={lastName}
                      type="text"
                      error={errorMsgLastName}
                      onChange={(lastName) => {
                        setLastName(lastName);
                        setErrorMsgLastName("");
                      }}
                      readonly={!isEdit}
                    />,
                    <MBInput
                      label="Mobile Number"
                      value={phoneNumber}
                      type="tel"
                      error={errorMsgPhoneNumber}
                      onChange={(phoneNumber) => {
                        setPhoneNumber(phoneNumber);
                        setErrorMsgPhoneNumber("");
                      }}
                      readonly={!isEdit}
                    />,
                    <MBInput
                      label="Email Address"
                      value={emailAddress}
                      type="email"
                      error={errorMsgEmaillAddress}
                      onChange={(emailAddress) => {
                        setEmailAddress(emailAddress);
                        setErrorMsgEmaillAddress("");
                      }}
                      readonly={true} //last priority - implement the email change and verification
                    />,
                    <IonItem
                      lines="none"
                      className="doctor-account-form-password-item ion-no-padding ion-no-margin"
                    >
                      <IonLabel
                        position="stacked"
                        className="mb-paragraph bold ion-no-padding ion-no-margin"
                      >
                        Password
                      </IonLabel>
                      <IonInput
                        type="password"
                        className="doctor-account-form-password-input ion-no-padding ion-no-margin"
                        autocomplete="off"
                        value="password" //fake password changing password will send an Email
                        readonly={true}
                      >
                        <IonButton
                          color={MBCOLORS.primary}
                          className="doctor-account-form-change-password-button sv-paragraph ion-no-margin ion-text-capitalize"
                          onClick={onChangePassword}
                          fill="outline"
                        >
                          Edit Password
                        </IonButton>
                      </IonInput>
                    </IonItem>,
                  ],
                  1
                ).map((row, index) => {
                  return (
                    <IonRow key={`row_${index}`}>
                      {row.map((col, index) => {
                        return (
                          <IonCol
                            key={`col_${index}`}
                            className="doctor-account-form-container-col ion-no-padding"
                            size={(12 / row.length).toString()}
                          >
                            {col}
                          </IonCol>
                        );
                      })}
                    </IonRow>
                  );
                })}

                <IonRow>
                  <IonCol
                    className="doctor-account-form-container-col last ion-no-padding"
                    size="12"
                  >
                    <div className="doctor-account-form-select-container">
                      <MBDropdownSelect
                        label="Hospital"
                        value={
                          !_.isEmpty(selectedDoctorHospital)
                            ? selectedDoctorHospital.name
                            : ""
                        }
                        onSelectItem={(selectedDoctorHospitalNew) => {
                          setSelectedDoctorHospital(selectedDoctorHospitalNew);
                          if (
                            !_.isEmpty(selectedDoctorHospital) &&
                            selectedDoctorHospital.id !==
                              selectedDoctorHospitalNew.id
                          ) {
                            selectDoctorHospital(selectedDoctorHospitalNew);
                          }
                        }}
                        selectFromOptionsRequired={true}
                        options={doctorHospitalOptions}
                        emptyOptionLabel={"No assigned hospital..."}
                        disabled={_.isNull(doctorHospitalOptions)}
                        disableTab={true}
                      />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol
                    className="doctor-account-form-container-col ion-no-padding"
                    size="4"
                  >
                    <IonLabel className="mb-h4 bold">Speciality</IonLabel>
                  </IonCol>
                  <IonCol
                    className="doctor-account-form-container-col ion-no-padding"
                    size="3"
                  >
                    <IonLabel className="mb-h4">Price (PHP)</IonLabel>
                  </IonCol>
                </IonRow>
                {selectedSpecialities.map((speciality, index) => {
                  return (
                    <IonRow key={`row_speciality_${index}`}>
                      <IonCol
                        className="doctor-account-form-container-col ion-align-self-center ion-no-padding"
                        size="4"
                      >
                        <IonLabel className="mb-body">
                          {speciality.name}
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        className="doctor-account-form-container-col ion-no-padding"
                        size="3"
                      >
                        <IonInput
                          className="doctor-account-form-container-fee-input mb-body"
                          type="number"
                          min={"0"}
                          readonly={!isEdit}
                          value={speciality.fee}
                          onIonChange={(fee) => {
                            if (!!fee.detail.value) {
                              const newSelectedSpecialities = _.cloneDeep(
                                selectedSpecialities
                              );
                              newSelectedSpecialities[index].fee = parseInt(
                                fee.detail.value!
                              );

                              setSelectedSpecialites(newSelectedSpecialities);
                            }
                          }}
                        ></IonInput>
                      </IonCol>
                    </IonRow>
                  );
                })}
              </IonGrid>
            </div>
          </form>

          {!_.isEmpty(selectedDoctorHospital) && (
            <IonGrid className="doctor-account-form-break-time-schedule ion-no-padding ion-no-margin">
              <IonRow>
                <IonCol size="4.5">
                  <IonLabel className="mb-paragraph bold">
                    Regular Break Time
                  </IonLabel>
                </IonCol>
                <IonCol className="doctor-account-form-schedule-col" size="2.5">
                  <IonLabel className="mb-paragraph bold">Start Time</IonLabel>
                </IonCol>
                <IonCol
                  className="doctor-account-form-schedule-col"
                  size="1"
                ></IonCol>
                <IonCol className="doctor-account-form-schedule-col" size="2.5">
                  <IonLabel className="mb-paragraph bold">End Time</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="4.5">
                  <IonItem
                    className={`doctor-account-form-schedule-item ${
                      isMobile() && "mobile"
                    }`}
                    lines="none"
                    color={MBCOLORS.tertiary}
                  >
                    <IonCheckbox
                      mode="md"
                      checked={withLunchBreak}
                      onIonChange={(event) => {
                        setWithLunchBreak(event.detail.checked);
                        setHasUpdatedSchedule(true);
                      }}
                      disabled={!isEdit}
                    />
                    <IonLabel className="doctor-account-form-schedule-label mb-body">
                      with Lunch
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol className="doctor-account-form-schedule-col" size="2.5">
                  <IonDatetime
                    disabled={!withLunchBreak}
                    className="doctor-account-form-time-slot-input mb-body ion-no-margin ion-no-padding"
                    display-format="h:mm A"
                    picker-format="h:mm A"
                    minuteValues="0,15,30,45"
                    value={startBreakTime.toISOString()}
                    readonly={!isEdit}
                    onIonChange={(selectedTime) => {
                      setStartBreakTime(
                        new Date(selectedTime.detail.value as string)
                      );
                      setHasUpdatedSchedule(true);
                    }}
                  ></IonDatetime>
                </IonCol>
                <IonCol className="doctor-account-form-schedule-col" size="1">
                  <IonIcon color={MBCOLORS.medium} icon={arrowForwardOutline} />
                </IonCol>
                <IonCol className="doctor-account-form-schedule-col" size="2.5">
                  <IonDatetime
                    disabled={!withLunchBreak}
                    className="doctor-account-form-time-slot-input mb-body ion-no-margin ion-no-padding"
                    display-format="h:mm A"
                    picker-format="h:mm A"
                    minuteValues="0,15,30,45"
                    value={endBreakTime.toISOString()}
                    onIonChange={(selectedTime) => {
                      setEndBreakTime(
                        new Date(selectedTime.detail.value as string)
                      );
                      setHasUpdatedSchedule(true);
                    }}
                  ></IonDatetime>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}

          {!_.isEmpty(doctorHospitalSchedule) && (
            <>
              <IonGrid className="doctor-account-form-schedule-estimated-appointment-length ion-no-padding ion-no-margin">
                <IonRow>
                  <IonCol className="ion-align-self-center" size="3">
                    <IonLabel className="mb-paragraph bold">
                      Estimated Time per Appointment
                    </IonLabel>
                  </IonCol>
                  <IonCol
                    className="doctor-account-form-schedule-col ion-align-items-center"
                    size="9"
                  >
                    <div className="doctor-account-form-schedule-col-select-container">
                      <MBDropdownSelect
                        label=""
                        className="appointment-length-options"
                        options={estimatedAppointmentMinuteLengthOptions}
                        value={selectedEstimatedAppointmentMinuteLength.name}
                        onSelectItem={(
                          selectedEstimatedAppointmentMinuteLength
                        ) => {
                          setSelectedEstimatedAppointmentMinuteLength(
                            selectedEstimatedAppointmentMinuteLength
                          );
                          setHasUpdatedSchedule(true);
                        }}
                        readonly={!isEdit}
                        selectFromOptionsRequired={true}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <div
                className={`doctor-account-form-schedule-container ${
                  isMobile() && "mobile"
                }`}
              >
                <IonGrid className="ion-no-padding ion-no-margin">
                  <IonRow>
                    <IonCol size="4.5">
                      <IonLabel className="mb-paragraph bold">
                        Schedule
                      </IonLabel>
                    </IonCol>
                    <IonCol
                      className="doctor-account-form-schedule-col"
                      size="2.5"
                    >
                      <IonLabel className="mb-paragraph bold">
                        Start Time
                      </IonLabel>
                    </IonCol>
                    <IonCol
                      className="doctor-account-form-schedule-col"
                      size="2.5"
                    >
                      <IonLabel className="mb-paragraph bold">
                        End Time
                      </IonLabel>
                    </IonCol>
                    <IonCol
                      className="doctor-account-form-schedule-col"
                      size="2.5"
                    >
                      <IonLabel className="mb-paragraph bold">Slots</IonLabel>
                    </IonCol>
                  </IonRow>
                  {doctorHospitalSchedule.map((item, index) => {
                    return (
                      <IonRow
                        className={`doctor-account-form-row-schedule ${
                          isMobile() && "mobile"
                        }`}
                        key={index}
                      >
                        <IonCol size="4.5">
                          <IonItem
                            className={`doctor-account-form-schedule-item ${
                              isMobile() && "mobile"
                            }`}
                            lines="none"
                            color={MBCOLORS.tertiary}
                          >
                            <IonCheckbox
                              mode="md"
                              checked={item.isDayChecked}
                              disabled={!isEdit}
                              onIonChange={() => {
                                const isCheckScheduleDaysOfWeek = _.clone(
                                  doctorHospitalSchedule
                                ) as DoctorScheduleOptions[];

                                const updateIsCheckScheduleDaysOfWeek =
                                  isCheckScheduleDaysOfWeek[index];

                                const updateIsCheckValueIndex = {
                                  ...isCheckScheduleDaysOfWeek[index],
                                  isDayChecked: !updateIsCheckScheduleDaysOfWeek.isDayChecked,
                                } as DoctorScheduleOptions;

                                isCheckScheduleDaysOfWeek[
                                  index
                                ] = updateIsCheckValueIndex;
                                setDoctorHospitalSchedule(
                                  isCheckScheduleDaysOfWeek
                                );
                                setHasUpdatedSchedule(true);
                              }}
                            />
                            <IonLabel className="doctor-account-form-schedule-label mb-body">
                              {isMobile()
                                ? item.name.slice(0, index === 4 ? 3 : 3)
                                : item.name}
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          className="doctor-account-form-schedule-col"
                          size="2.5"
                        >
                          <IonDatetime
                            disabled={!item.isDayChecked}
                            className="doctor-account-form-time-slot-input mb-body ion-no-margin ion-no-padding"
                            display-format="h:mm A"
                            picker-format="h:mm A"
                            minuteValues="0,15,30,45"
                            readonly={!isEdit}
                            value={item.startTime!.toDate().toISOString()}
                            onIonChange={(selectedTime) => {
                              const updatedScheduleDays = _.clone(
                                doctorHospitalSchedule
                              );
                              const updateValueIndex = {
                                ...updatedScheduleDays[index],
                                startTime: getServerTimestamp(
                                  new Date(selectedTime.detail.value as string)
                                ),
                              } as DoctorScheduleOptions;

                              updatedScheduleDays[index] = updateValueIndex;
                              setDoctorHospitalSchedule(updatedScheduleDays);
                              setHasUpdatedSchedule(true);
                            }}
                          ></IonDatetime>
                        </IonCol>
                        <IonCol
                          className="doctor-account-form-schedule-col"
                          size="2.5"
                        >
                          <IonDatetime
                            disabled={!item.isDayChecked}
                            className="doctor-account-form-time-slot-input mb-body ion-no-margin ion-no-padding"
                            display-format="h:mm A"
                            picker-format="h:mm A"
                            minuteValues="0,15,30,45"
                            readonly={!isEdit}
                            value={item.endTime!.toDate().toISOString()}
                            onIonChange={(selectedTime) => {
                              const updatedScheduleDays = _.clone(
                                doctorHospitalSchedule
                              );
                              const updateValueIndex = {
                                ...updatedScheduleDays[index],
                                endTime: getServerTimestamp(
                                  new Date(selectedTime.detail.value as string)
                                ),
                              } as DoctorScheduleOptions;

                              updatedScheduleDays[index] = updateValueIndex;
                              setDoctorHospitalSchedule(updatedScheduleDays);
                              setHasUpdatedSchedule(true);
                            }}
                          ></IonDatetime>
                        </IonCol>
                        <IonCol
                          className="doctor-account-form-schedule-col"
                          size="2.5"
                        >
                          <IonInput
                            disabled={!item.isDayChecked}
                            className="doctor-account-form-time-slot-input mb-body"
                            type="number"
                            min={"0"}
                            max={"99"}
                            readonly={!isEdit}
                            value={doctorHospitalSchedule[index].slots}
                            onIonChange={(slots) => {
                              const updatedScheduleDays = _.clone(
                                doctorHospitalSchedule
                              );
                              const updateValueIndex = {
                                ...updatedScheduleDays[index],
                                slots:
                                  parseInt(slots.detail.value as string) ||
                                  (0 as number),
                              } as DoctorScheduleOptions;
                              updatedScheduleDays[index] = updateValueIndex;
                              setDoctorHospitalSchedule(updatedScheduleDays);
                              setHasUpdatedSchedule(true);
                            }}
                          ></IonInput>
                        </IonCol>
                      </IonRow>
                    );
                  })}
                </IonGrid>
              </div>
            </>
          )}
        </div>
        <div
          className={`doctor-account-card-footer ${
            isMobile() ? "mobile" : ""
          } ${
            !_.isEmpty(selectedDoctorHospital) &&
            !selectedDoctorHospital.metadata.initialSetupDone
              ? "initial-setup"
              : ""
          }`}
        >
          {isMobile() ? (
            <>
              <IonButton
                onClick={() => {
                  if (
                    isEdit ||
                    !selectedDoctorHospital.metadata.initialSetupDone
                  ) {
                    onSubmit();
                  } else {
                    setIsEdit(!isEdit);
                  }
                }}
                className="doctor-account-submit-button mb-body bold white ion-text-capitalize"
                color={MBCOLORS.primary}
              >
                {!_.isEmpty(selectedDoctorHospital) &&
                !selectedDoctorHospital.metadata.initialSetupDone
                  ? "Confirm Details"
                  : isEdit
                  ? "Save"
                  : `${isScheduleMode ? "Edit Schedule" : "Edit Profile"}`}
              </IonButton>
              <IonButton
                onClick={() => {
                  setScheduleMode(!isScheduleMode);
                  setIsEdit(false);
                }}
                className="doctor-account-mode-button mb-body bold white ion-text-capitalize"
                color={MBCOLORS.tertiary}
              >
                {isScheduleMode
                  ? "View / Edit Profile"
                  : "View / Edit Schedule"}
              </IonButton>
            </>
          ) : (
            <>
              <IonButton
                onClick={() => {
                  onBack();
                }}
                className="doctor-account-cancel-button mb-body ion-text-capitalize"
                fill="clear"
              >
                Back
              </IonButton>
              <div>
                <IonButton
                  onClick={() => {
                    if (
                      isEdit ||
                      !selectedDoctorHospital.metadata.initialSetupDone
                    ) {
                      onSubmit();
                    } else {
                      setIsEdit(!isEdit);
                    }
                  }}
                  className="doctor-account-submit-button mb-body bold white ion-text-capitalize"
                  color={MBCOLORS.primary}
                >
                  {!_.isEmpty(selectedDoctorHospital) &&
                  !selectedDoctorHospital.metadata.initialSetupDone
                    ? "Confirm Details"
                    : isEdit
                    ? "Save"
                    : "Edit"}
                </IonButton>
              </div>
            </>
          )}
        </div>
      </IonCard>

      <MBDialog
        isOpen={isOpenSendChangePassword}
        title="Email Sent"
        message={`We have sent you an email to ${emailAddress} Click the link to change you account password.`}
        icon="success"
        onDidDismiss={() => {
          setIsOpenSendChangePassword(false);
        }}
      />

      <MBDialog
        isOpen={isOpenSuccessProfileUpdate}
        title={
          !_.isEmpty(selectedDoctorHospital) &&
          !selectedDoctorHospital.metadata.initialSetupDone
            ? "All right we are all set"
            : "Personal Profile Updated"
        }
        message={
          !_.isEmpty(selectedDoctorHospital) &&
          !selectedDoctorHospital.metadata.initialSetupDone
            ? "Your expertise will now be seen in our web app by the patients seeking for a medical attention"
            : "You have successfully updated your personal profile. This will now reflect on your appointment details."
        }
        icon="success"
        buttonLabel={
          !_.isEmpty(selectedDoctorHospital) &&
          !selectedDoctorHospital.metadata.initialSetupDone
            ? "Let's Get Started"
            : "Okay"
        }
        onDidDismiss={() => {
          setIsOpenSuccessProfileUpdate(false);
          onBack();
          if (
            !_.isEmpty(selectedDoctorHospital) &&
            !selectedDoctorHospital.metadata.initialSetupDone
          ) {
            props.history.push(routes.DOCTOR_APPOINTMENTS);
          }
        }}
      />

      <MBDialog
        isOpen={isConfirmCancelOutOfOfficeDialogOpen}
        icon="warning"
        title="You are about to set your status as active"
        message="Your schedule will now be available to patients.  Are you sure you want to set your status to active?"
        onDidDismiss={() => {
          setIsConfirmCancelOutOfOfficeDialogOpen(false);
          if (!isReauthenticateDialogOpen) setOutOfOffice(isOutOfOffice);
        }}
        onApprove={() => {
          setSelectedOutOfOfficeStartDate(null);
          setSelectedOutOfOfficeEndDate(null);
          setIsReauthenticateDialogOpen(true);
          setIsConfirmCancelOutOfOfficeDialogOpen(false);
        }}
        onDecline={() => {
          setIsConfirmCancelOutOfOfficeDialogOpen(false);
          setOutOfOffice(isOutOfOffice);
        }}
      />

      <MBDialog
        isOpen={isConfirmOutOfOfficeDialogOpen}
        icon="warning"
        title="You are about to set your Out-of-Office status."
        message="Your schedule will not be available to future bookings by patients until you come back. If there are appointments affected, an email message were sent to the patients, however you would need to cancel the appointments manually.  Are you sure you want to set your Out-of-Office?"
        onDidDismiss={() => {
          setIsConfirmOutOfOfficeDialogOpen(false);
          if (!isCalendarRangeDialogOpen && !isReauthenticateDialogOpen)
            setOutOfOffice(isOutOfOffice);
        }}
        onApprove={() => {
          setIsCalendarRangeDialogOpen(true);
          setIsConfirmOutOfOfficeDialogOpen(false);
        }}
        onDecline={() => {
          setIsConfirmOutOfOfficeDialogOpen(false);
          setOutOfOffice(isOutOfOffice);
        }}
      />

      {isCalendarRangeDialogOpen && (
        <MBCalendarRangeDialog
          icon="download"
          isOpen={isCalendarRangeDialogOpen}
          title="Please set the duration of your Out-of-Office status"
          errorMessage="You must indicate a start and end date for your Out-of-Office status. You can also set end date to indefinite if you don’t want to set an end date."
          indefiniteEndDateLabel="Keep out of office status indefinite"
          onDidDismiss={() => {
            setIsCalendarRangeDialogOpen(false);
            if (!isReauthenticateDialogOpen) setOutOfOffice(isOutOfOffice);
          }}
          onSubmit={(startDate, endDate) => {
            setSelectedOutOfOfficeStartDate(startDate);
            setSelectedOutOfOfficeEndDate(endDate);
            setIsReauthenticateDialogOpen(true);
            setIsCalendarRangeDialogOpen(false);
          }}
          onCancel={() => {
            setIsCalendarRangeDialogOpen(false);
            setOutOfOffice(isOutOfOffice);
          }}
        />
      )}

      {isReauthenticateDialogOpen && (
        <MBPasswordReauthenticationDialog
          {...props}
          isOpen={isReauthenticateDialogOpen}
          onDidDismiss={() => {
            setIsReauthenticateDialogOpen(false);
            if (!loading) setOutOfOffice(isOutOfOffice);
          }}
          onSubmit={(isReauthenticated) => {
            if (isReauthenticated) {
              updateOutOfOfficeState(
                selectedOutOfOfficeStartDate,
                selectedOutOfOfficeEndDate
              );
            } else {
              setSelectedOutOfOfficeStartDate(null);
              setSelectedOutOfOfficeEndDate(null);
            }
            setIsReauthenticateDialogOpen(false);
          }}
        />
      )}

      <MBDialog
        isOpen={isSuccessOutOfOfficeDialogOpen}
        icon="success"
        title="Out-of-Office status set"
        message="You have successfully set your Out-of-Office status. Your schedule will not be available for booking to patients during this period.  You can change this status if you choose to go back to active duty at an earlier date."
        onDidDismiss={() => {
          setIsSuccessOutOfOfficeDialogOpen(false);
        }}
        onClick={() => {
          setIsSuccessOutOfOfficeDialogOpen(false);
          onBack();
        }}
      />

      <MBDialog
        isOpen={isDeleteProfileConfirmationDialogOpen}
        icon="warning"
        title="You are about to delete your profile"
        message="Are you sure you want to delete your profile?"
        onDidDismiss={() => {
          setIsDeleteProfileConfirmationDialogOpen(false);
        }}
        onApprove={() => {
          deleteProfile();
          onBack();
          setIsDeleteProfileConfirmationDialogOpen(false);
        }}
        onDecline={() => {
          setIsDeleteProfileConfirmationDialogOpen(false);
        }}
      />

      <IonLoading
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={MBCOLORS.danger}
      />
    </>
  );
};
