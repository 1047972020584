import * as _ from "lodash";
import moment from "moment";
import React from "react";
import { location } from "ionicons/icons";
import { Helmet } from "react-helmet";
import {
  IonIcon,
  IonLabel,
  IonCard,
  IonCardContent,
  IonSearchbar,
  IonButton,
  IonToast,
  IonLoading,
} from "@ionic/react";

import "./PatientHome.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps } from "../../interface";
import { PatientViewResource } from "../../components/PatientViewResource/PatientViewResource";
import {
  MBDropdownSelect,
  MBDropdownSelectOption,
} from "../../components/MBDropdownSelect/MBDropdownSelect";
import {
  DoctorResource,
  ServiceResource,
  DoctorSchedule,
  ServiceSchedule,
  BookListItem,
} from "../../models";
import { MBCOLORS } from "../../constants/config";

class PatientHome extends React.Component<MBProps> {
  state = {
    searchQuery: "",
    hostpitalOptions: [
      {
        id: "nearby",
        name: "All Hospitals nearby",
      },
    ] as MBDropdownSelectOption[],
    selectedHospitalOption: {
      id: "nearby",
      name: "All Hospitals nearby",
    } as MBDropdownSelectOption,

    resourceTypeOptions: [
      {
        id: "allTypes",
        name: "All Types",
      },
      {
        id: "consultation",
        name: "Consultation",
      },
      {
        id: "service",
        name: "Ambulatory Care",
      },
    ] as MBDropdownSelectOption[],

    selectedResourceType: {
      id: "allTypes",
      name: "All Types",
    } as MBDropdownSelectOption,

    resourceCategoryOption: [
      {
        id: "allResourceCategory",
        name: "All Specialty/Treatment",
        metadata: null,
      },
    ] as MBDropdownSelectOption[],

    serviceCategoryOption: [] as MBDropdownSelectOption[],

    doctorCategoryOption: [] as MBDropdownSelectOption[],

    selectedResourceCategory: {
      id: "allResourceCategory",
      name: "All Specialty/Treatment",
      metadata: null,
    } as MBDropdownSelectOption,

    doctorResources: null as DoctorResource[] | null,
    serviceResources: null as ServiceResource[] | null,
    consolidatedResourceList: [] as (DoctorResource | ServiceResource)[],

    selectedResourceToView: {} as DoctorResource | ServiceResource,
    bookListItem: null as BookListItem | null,

    loading: false,
    error: "",
  };

  componentDidMount = () => {
    const { bookListItemId } = this.props.match.params as {
      bookListItemId: string;
    };
    if (!_.isEmpty(bookListItemId)) {
      this.getBooklistItem(bookListItemId);
    } else {
      this.getHospitals();
      this.getDoctorResources();
      this.getServiceResources();
    }
  };

  getBooklistItem = async (bookListItemId: string) => {
    try {
      this.setState({
        loading: true,
      });

      const result = await services.getBookListItem(bookListItemId);

      if (!_.isNull(result)) {
        this.setState({
          doctorResources: [],
          serviceResources: [],
          bookListItem: result,
          selectedResourceToView: result.resource,
          loading: false,
        });
      } else {
        this.getHospitals();
        this.getDoctorResources();
        this.getServiceResources();
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      this.getHospitals();
      this.getDoctorResources();
      this.getServiceResources();
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  getHospitals = async () => {
    const { hostpitalOptions } = this.state;
    services.getHospitalsRealTime((hospitals, error) => {
      if (_.isEmpty(error)) {
        let newHospitalOptions = _.cloneDeep(hostpitalOptions);
        newHospitalOptions = newHospitalOptions.concat(
          hospitals.map((hospital) => {
            return {
              id: hospital.docId || "",
              name: hospital.hospitalName,
              metadata: hospital,
            };
          })
        );
        this.setState({
          hostpitalOptions: newHospitalOptions,
        });
      } else {
        this.setState({
          error,
        });
      }
    });
  };

  getDoctorResources = async () => {
    try {
      services.getDoctorResources(
        null,
        null,
        true,
        (doctorResources, error) => {
          if (_.isEmpty(error)) {
            const doctorCategoryOption = _.unionBy(
              doctorResources.map((resource) => {
                return {
                  id: resource.specialities[0].name.trim().toLowerCase(),
                  name: resource.specialities[0].name,
                  metadata: "doctor",
                };
              }),
              "name"
            ) as MBDropdownSelectOption[];
            this.setState({
              doctorResources,
              doctorCategoryOption,
            });
          } else {
            this.setState({
              doctorResources: [],
              error,
            });
          }
          setTimeout(() => {
            this.applyFilters();
          }, 100);
        }
      );
    } catch (e) {
      this.setState({
        doctorResources: [],
        error: e,
      });
    }
  };

  getServiceResources = async () => {
    try {
      //! update to properly query filters
      services.getServiceResources(
        null,
        null,
        true,
        (serviceResources, error) => {
          if (_.isEmpty(error)) {
            const serviceCategoryOption = _.uniqBy(
              serviceResources.map((resource) => {
                return {
                  id: resource.treatmentName.trim().toLowerCase(),
                  name: resource.treatmentName,
                  metadata: "service",
                };
              }),
              "name"
            ) as MBDropdownSelectOption[];

            this.setState({
              serviceResources: _.filter(serviceResources, (serviceResource) =>
                _.isEmpty(serviceResource.archiveDate)
              ),
              serviceCategoryOption,
            });
          } else {
            this.setState({
              serviceResources: [],
              error,
            });
          }
          setTimeout(() => {
            this.applyFilters();
          }, 100);
        }
      );
    } catch (e) {
      this.setState({
        serviceResources: [],
        error: e,
      });
    }
  };

  applyFilters = () => {
    const {
      searchQuery,
      selectedHospitalOption,
      selectedResourceType,
      selectedResourceCategory,
      doctorResources,
      serviceResources,
    } = this.state;

    const consolidatedResourceList: (
      | DoctorResource
      | ServiceResource
    )[] = _.filter(
      [...(doctorResources || []), ...(serviceResources || [])],
      (resource) => {
        let isValid = true;
        const serviceResource = resource as ServiceResource;
        const doctorResources = resource as DoctorResource;

        const isDoctorResource = doctorResources.doctorHospitalId !== undefined;
        const formattedSearchQuery = searchQuery.trim().toLowerCase();

        if (
          selectedHospitalOption.id !== "nearby" &&
          resource.hospital.docId !== selectedHospitalOption.id
        ) {
          isValid = false;
        }

        if (
          selectedResourceType.id !== "allTypes" &&
          ((selectedResourceType.id === "consultation" && !isDoctorResource) ||
            (selectedResourceType.id === "service" && isDoctorResource))
        ) {
          isValid = false;
        }

        if (
          selectedResourceCategory.id !== "allResourceCategory" &&
          ((selectedResourceCategory.metadata === "service" &&
            !isDoctorResource &&
            selectedResourceCategory.id !==
              serviceResource.treatmentName.trim().toLowerCase()) ||
            (selectedResourceCategory.metadata === "doctor" &&
              isDoctorResource &&
              selectedResourceCategory.id !==
                doctorResources.specialities[0].name.trim().toLowerCase()) ||
            (selectedResourceCategory.metadata === "service" &&
              isDoctorResource) ||
            (selectedResourceCategory.metadata === "doctor" &&
              !isDoctorResource))
        ) {
          isValid = false;
        }

        if (
          !_.isEmpty(searchQuery) &&
          ((isDoctorResource &&
            !doctorResources.fullName
              .trim()
              .toLowerCase()
              .includes(formattedSearchQuery) &&
            !doctorResources.hospital.hospitalName
              .trim()
              .toLowerCase()
              .includes(formattedSearchQuery) &&
            !doctorResources.specialities[0].name
              .trim()
              .toLowerCase()
              .includes(formattedSearchQuery)) ||
            (!isDoctorResource &&
              !serviceResource.treatmentName
                .trim()
                .toLowerCase()
                .includes(formattedSearchQuery) &&
              !serviceResource.hospital.hospitalName
                .trim()
                .toLowerCase()
                .includes(formattedSearchQuery)))
        ) {
          isValid = false;
        }
        return isValid;
      }
    );

    this.setState({
      consolidatedResourceList: _.sortBy(consolidatedResourceList, "distance"),
    });
  };

  render = () => {
    const {
      searchQuery,
      selectedHospitalOption,
      hostpitalOptions,
      resourceTypeOptions,
      selectedResourceType,
      resourceCategoryOption,
      selectedResourceCategory,
      serviceCategoryOption,
      doctorCategoryOption,
      doctorResources,
      serviceResources,
      consolidatedResourceList,
      selectedResourceToView,
      bookListItem,
      loading,
      error,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>MedBook - Patient - Home</title>
        </Helmet>

        <MBContainer {...this.props} activePage="patient-home">
          {_.isEmpty(selectedResourceToView) ? (
            <>
              <div className="patient-home-search-card-container">
                <IonSearchbar
                  mode="ios"
                  placeholder="Search keyword here..."
                  value={searchQuery}
                  onIonChange={(e) =>
                    this.setState({ searchQuery: e.detail.value })
                  }
                  className="patient-home-search-input mb-body ion-no-margin ion-no-padding ion-text-start"
                />

                <div className="patient-home-search-card-col-dropdown-container">
                  <MBDropdownSelect
                    className="patient-home-hospital-drop-down-options"
                    options={hostpitalOptions}
                    value={selectedHospitalOption.name}
                    onSelectItem={(selectedHospitalOption) => {
                      this.setState({
                        selectedHospitalOption,
                      });
                    }}
                    selectFromOptionsRequired={true}
                    error={""}
                  />
                </div>
                <div className="patient-home-search-card-col-dropdown-container">
                  <MBDropdownSelect
                    className="patient-home-hospital-drop-down-options"
                    options={resourceTypeOptions}
                    value={selectedResourceType.name}
                    onSelectItem={(selectedResourceType) => {
                      this.setState({
                        selectedResourceType,
                      });
                    }}
                    selectFromOptionsRequired={true}
                    error={""}
                  />
                </div>
                <div className="patient-home-search-card-col-dropdown-container">
                  <MBDropdownSelect
                    className="patient-home-hospital-drop-down-options"
                    options={[
                      ...resourceCategoryOption,
                      ...serviceCategoryOption,
                      ...doctorCategoryOption,
                    ]}
                    value={selectedResourceCategory.name}
                    onSelectItem={(selectedResourceCategory) => {
                      this.setState({
                        selectedResourceCategory,
                      });
                    }}
                    selectFromOptionsRequired={true}
                    error={""}
                  />
                </div>
                <IonButton
                  className="patient-home-search-submit mb-body"
                  color={MBCOLORS.primary}
                  mode="ios"
                  disabled={loading}
                  onClick={this.applyFilters}
                >
                  Search
                </IonButton>
              </div>

              {!_.isEmpty(consolidatedResourceList) ? (
                <div className="patient-home-resource-results">
                  <IonLabel className="patient-home-results-label mb-body bold">
                    {`${consolidatedResourceList.length} Results Found`}
                  </IonLabel>

                  {consolidatedResourceList.map((resource) => {
                    const serviceResource = resource as ServiceResource;
                    const doctorResources = resource as DoctorResource;

                    const isDoctorResource =
                      doctorResources.doctorHospitalId !== undefined;

                    return (
                      <IonCard className="patient-home-resource-card ion-no-margin">
                        <IonCardContent className="patient-home-resource-card-content ion-no-padding">
                          {isDoctorResource ? (
                            <>
                              <IonIcon className="patient-home-resource-card-doctor-icon ion-no-margin" />
                              <div className="patient-home-resource-details">
                                <IonLabel className="mb-h2 dark-blue normal">
                                  <b>{doctorResources.fullName}</b> (
                                  {doctorResources.specialities[0].name})
                                </IonLabel>
                                <IonLabel className="patient-home-resource-hospital mb-body">
                                  {doctorResources.hospital.hospitalName}{" "}
                                  <span className="patient-home-detail-resource-type">
                                    • Consulation
                                  </span>
                                </IonLabel>
                                <div className="patient-home-detail-schedule-days-container">
                                  {_.sortBy(
                                    doctorResources.schedule,
                                    "dayOfWeek"
                                  ).map((schedule: DoctorSchedule) => {
                                    return (
                                      <IonLabel className="mb-data-table-schedule-label mb-body blue bold">
                                        {moment.weekdaysMin(schedule.dayOfWeek)}
                                      </IonLabel>
                                    );
                                  })}
                                </div>
                                <IonLabel className="patient-home-resource-fee mb-h3">
                                  <b>{`Php ${doctorResources.specialities[0].fee}`}</b>{" "}
                                  Consultation Fee
                                </IonLabel>
                              </div>
                              <div className="patient-home-resource-view-button-container">
                                <IonLabel className="patient-home-resource-distance-label mb-body bold">
                                  {resource.distance !== undefined && (
                                    <>
                                      {" "}
                                      <IonIcon icon={location} />{" "}
                                      {`${~~resource.distance} KMS away`}{" "}
                                    </>
                                  )}
                                </IonLabel>
                                <IonButton
                                  onClick={() => {
                                    this.setState({
                                      selectedResourceToView: doctorResources,
                                    });
                                  }}
                                  className="patient-home-resource-view-button mb-body blue ion-text-capitalize"
                                  fill="outline"
                                >
                                  View
                                </IonButton>
                              </div>
                            </>
                          ) : (
                            <>
                              <IonIcon className="patient-home-resource-card-service-icon ion-no-margin" />
                              <div className="patient-home-resource-details">
                                <IonLabel className="mb-h2 dark-blue bold">
                                  {serviceResource.treatmentName}
                                </IonLabel>
                                <IonLabel className="patient-home-resource-hospital mb-body">
                                  {serviceResource.hospital.hospitalName}{" "}
                                  <span className="patient-home-detail-resource-type">
                                    • Ambulatory Care
                                  </span>
                                </IonLabel>
                                <div className="patient-home-detail-schedule-days-container">
                                  {_.sortBy(
                                    serviceResource.schedule,
                                    "dayOfWeek"
                                  ).map((schedule: ServiceSchedule) => {
                                    return (
                                      <IonLabel className="mb-data-table-schedule-label mb-body blue bold">
                                        {moment.weekdaysMin(schedule.dayOfWeek)}
                                      </IonLabel>
                                    );
                                  })}
                                </div>
                                <IonLabel className="patient-home-resource-fee mb-h3">
                                  <b>{`Php ${serviceResource.fee}`}</b> Service
                                  Fee
                                </IonLabel>
                              </div>
                              <div className="patient-home-resource-view-button-container">
                                <IonLabel className="patient-home-resource-distance-label mb-body bold">
                                  {resource.distance !== undefined && (
                                    <>
                                      {" "}
                                      <IonIcon icon={location} />{" "}
                                      {`${~~resource.distance} KMS away`}{" "}
                                    </>
                                  )}
                                </IonLabel>
                                <IonButton
                                  onClick={() => {
                                    this.setState({
                                      selectedResourceToView: serviceResource,
                                    });
                                  }}
                                  className="patient-home-resource-view-button mb-body blue ion-text-capitalize"
                                  fill="outline"
                                >
                                  View
                                </IonButton>
                              </div>
                            </>
                          )}
                        </IonCardContent>
                      </IonCard>
                    );
                  })}
                </div>
              ) : (
                <div className="patient-home-dashboard">
                  <IonIcon className="patient-home-icon" />
                  <IonLabel className="patient-home-title mb-h1">
                    No Results Found
                  </IonLabel>
                  <IonLabel className="mb-body">
                    Please try another keyword or try another hospital, type,
                    specialty or treatment
                  </IonLabel>
                </div>
              )}
            </>
          ) : (
            <PatientViewResource
              resourceToView={selectedResourceToView}
              {...(!_.isEmpty(bookListItem)
                ? {
                    bookListItem: bookListItem!,
                  }
                : {})}
              onClose={() => {
                this.setState({
                  selectedResourceToView: {},
                });
                this.props.history.push(routes.PATIENT_HOME);
              }}
              {...this.props}
            />
          )}
        </MBContainer>

        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => this.setState({ error: "" })}
          color={MBCOLORS.danger}
        />

        <IonLoading
          translucent={true}
          mode="ios"
          isOpen={
            _.isNull(doctorResources) || _.isNull(serviceResources) || loading
          }
          message={""}
        />
      </>
    );
  };
}

export default PatientHome;
