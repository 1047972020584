import React, { useEffect } from "react";
import {
  IonButtons,
  IonTextarea,
  IonInput,
  IonImg,
  IonRippleEffect,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonRow,
} from "@ionic/react";
import * as routes from "../../constants/routes";
import { logoFacebook } from "ionicons/icons";
import { TimelineLite, Power3, Power4 } from "gsap";
import "./Tab2.scss";
import { LandingContact } from "./LandingContact";

interface PreregistrationProps {
  onGoHome: () => void;
}
const Tab2: React.FC<PreregistrationProps> = (props) => {
  let t1 = new TimelineLite({ delay: 0.1 });
  const { onGoHome } = props;

  useEffect(() => {
    t1.from(
      ".banneranim",
      { y: 15, opacity: 0, ease: Power3.easeInOut, delay: 0.8 },
      "Start"
    );

    t1.from(
      ".textanim",
      { y: 20, opacity: 0, ease: Power4.easeOut, delay: 0.4 },
      "Start"
    );
    t1.from(
      ".btnanim",
      { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.7 },
      "Start"
    );

    setTimeout(() => {}, 1000);
  }, []);

  return (
    <IonPage className="tab-container">
      <IonHeader>
        <IonToolbar className="ion-padding-top ion-padding-left">
          <IonTitle onClick={onGoHome}>
            <img
              className="logo-padding"
              src={`${process.env.PUBLIC_URL}/assets/images/Medbooklogo.svg`}
            />
          </IonTitle>
          <IonButtons slot="primary" className="ion-margin-right ml-50">
            {/* <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-margin-right mr-10"
              // fill="outline"
              color="primary"
              routerLink={routes.LOGIN_OPTIONS}
            >
              Login
            </IonButton> */}
            <IonButton
              href="https://www.facebook.com/medbookph/"
              target="blank"
              className="landing-dark-blue-text ion-padding-end ion-margin-right mr-50"
              fill="outline"
              color="primary"
            >
              Follow us
              <IonIcon slot="start" icon={logoFacebook} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* CONTENT */}
      <IonContent className="pb-0">
        <IonRow className="h100 lime"></IonRow>
        <IonRow className="bkg-blue ion-justify-content-center lime ion-padding-top  ">
          <IonCol
            className="white-text ion-padding-top pb-0  textanim "
            size="4"
          >
            <h1 className="lgH1">
              Keep track of your appointments & walk-in consultations
            </h1>
            <p>
              Medbook is more than just a booking app - it's a scheduling system
              that lets doctors view, organize, cancel, or reschedule
              consultations and appointments.
            </p>
            <IonButton
              className="main tnanim ion-text-capitalize"
              color="warning"
              href=""
            >
              Get In Touch
            </IonButton>

            {/* <p > No need to create an account. A hospital affiliate will register your profile.  Coordinate with your hospital representatives now!</p> */}
          </IonCol>

          <IonCol size="6" className="ion-padding-top pb-0 banneranim">
            {" "}
            <img src={`${process.env.PUBLIC_URL}/assets/images/doctor.png`} />
          </IonCol>
        </IonRow>

        {/* WHY MEDBOOK? */}
        <IonRow className="bkg-blue ion-justify-content-center dark-teal ion-padding-bottom">
          <IonCard
            color="primary"
            className="card-no-shadow bkg-trans ion-justify-content-center"
          >
            <IonCardHeader>
              <IonCardTitle className="ion-text-center ion-padding-bottom">
                <h1>Why is Medbook a great fit for you?</h1>
              </IonCardTitle>
            </IonCardHeader>
            <IonRow className="ion-padding-bottom ion-justify-content-center">
              <IonCol size="3">
                <IonCard className="card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bord-20  ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/ControlSchedule.svg`}
                  />
                  <h4 className="landing-dark-blue-text">Control Schedule</h4>
                  <p>
                    We allow all doctors to create and update their schedules
                    with a touch.
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
              <IonCol size="3">
                <IonCard className="card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bord-20 ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/PatientConsult.svg`}
                  />
                  <h4 className="landing-dark-blue-text">
                    Improve Patient Consults
                  </h4>
                  <p>
                    Be able find the nearest Hospital wherever you book an
                    appointment{" "}
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
              <IonCol size="3">
                <IonCard className="card-no-shadow ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent  bord-20 ">
                  <img
                    className="w150"
                    src={`${process.env.PUBLIC_URL}/assets/images/WaitingTime.svg`}
                  />
                  <h4 className="landing-dark-blue-text">
                    Waiting Time Analysis
                  </h4>
                  <p>
                    Medbook prioritizes time from beginning to end for a better
                    patient experience.
                  </p>
                  <IonRippleEffect></IonRippleEffect>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonCard>
        </IonRow>

        {/* HOMO TO HOME IN 6 STEPS */}
        <IonRow className="ion-justify-content-center lime">
          <IonCard color="primary" className="card-no-shadow bkg-trans">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                <h1>Manage consultations in 4 steps</h1>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonRow>

        <IonRow className="ion-justify-content-center lime">
          <IonCol size="3">
            <IonCard className="ion-padding card-no-shadow bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step.svg`}
                  slot="start"
                />
                <h6 className="white-text">Login to Medbook</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Login.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>

          <IonCol size="3">
            <IonCard className="ion-padding card-no-shadow bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step2.svg`}
                  slot="start"
                />
                <h6 className="white-text">Check your Schedule</h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/CheckSchedule.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center lime">
          <IonCol size="3">
            <IonCard className="ion-padding card-no-shadow bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step3.svg`}
                  slot="start"
                />
                <h6 className="white-text">
                  Scan the QR code from the patient
                </h6>
              </IonItem>

              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/ReceivePatient.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="3">
            <IonCard className="ion-padding card-no-shadow bkg-trans">
              <IonItem color="transparent" lines="none">
                <IonIcon
                  src={`${process.env.PUBLIC_URL}/assets/images/step4.svg`}
                  slot="start"
                />
                <h6 className="white-text">Conduct the consultation</h6>
              </IonItem>
              <IonCardContent>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Appointment.svg`}
                ></img>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>

        {/* HOW EASY MEDBOOK */}
        <IonRow className="bkg-blue marg">
          <IonCard
            color="light"
            className="landing-center card-no-shadow ion-text-center bkg-trans"
          >
            <IonCardHeader>
              <IonCardTitle>
                <h1 className="landing-dark-blue-text">Giving you Control</h1>
              </IonCardTitle>
              <IonCardContent>
                Edit your schedule, adjust your consultation time, manage
                appointments across different hospitals and clinics, and more -
                all in one convenient app.
              </IonCardContent>

              <IonRow className="ion-justify-content-center  ">
                <IonCol size="3">
                  <IonCard className="card-no-shadow  bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/LoginTablet.png`}
                    />
                    <h4 className="landing-dark-blue-text">Select Hospital</h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
                <IonCol size="3">
                  <IonCard className="card-no-shadow   bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/SetSchedule.png`}
                    />
                    <h4 className="landing-dark-blue-text">Update Schedule </h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
                <IonCol size="3">
                  <IonCard className="card-no-shadow  bord-20 ion-padding ion-text-center ion-justify-content-center ion-activatable ripple-parent bkg-trans ">
                    <IonImg
                      src={`${process.env.PUBLIC_URL}/assets/images/SaveSchedule.png`}
                    />
                    <h4 className="landing-dark-blue-text">Save Schedule</h4>
                    <p>
                      We provide innovative realtime booking of medical services
                      and consultations.
                    </p>
                    <IonRippleEffect></IonRippleEffect>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonCardHeader>
          </IonCard>
        </IonRow>

        {/* GET NOTIFIED */}

        {/* GET NOTIFIED */}
        <form>
          <IonCard className="card-no-shadow landing-light ion-padding">
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="6" className="padding">
                {" "}
                <h3 className="ion-text-end teal-text">
                  Get notified for the latest updates
                </h3>{" "}
              </IonCol>
              <IonCol size="1">
                {" "}
                <IonItem>
                  <IonLabel position="floating">Email Address</IonLabel>
                  <IonInput type="email" required></IonInput>
                </IonItem>
              </IonCol>
              <IonCol size="1">
                {" "}
                <IonButton
                  className="ion-text-capitalize"
                  color="primary"
                  size="large"
                  type="submit"
                >
                  Subscribe
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCard>
        </form>

        {/* YOUMAY ASLO LIKE */}
        {/* 
<IonRow className="ion-justify-content-center six-steps ">
      <IonCard color="light"className="bkg-trans">
        <IonCardHeader>
          <IonCardTitle className="ion-text-center" ><h1 className="landing-dark-blue-text">You may also like</h1></IonCardTitle>
          <IonCardContent className="ion-text-center" >
          Medbook also have some partner brands that you can offer to your patients
        </IonCardContent>
        </IonCardHeader>
        <IonRow className="ion-justify-content-center">
      <IonCol size="2.5">
              <IonCard className="ion-padding shadow bord-10">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/Ad1.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>
        <IonCol size="2.5">
                <IonCard className="ion-padding shadow bord-10">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/Ad2.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>


        <IonCol size="2.5">
                <IonCard className="ion-padding shadow bord-10">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/Ad3.png`}></img>
                     <h5>Add Placement 1</h5>
                           
                     <p>Lorem Ipsum dolor este por pabor delamente colorado pordito mecando</p>
                      <IonButton  fill="outline">Learn More</IonButton>
                 
               </IonCard>
        </IonCol>
      </IonRow>

      </IonCard>
  


      </IonRow> */}

        {/* CONTACT US NEW */}

        <LandingContact />
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
